import { Select } from "antd"
import getActivityCodes from "app/activity-codes/queries/getActivityCodes"
import { TableStore } from "app/core/stores/TableStore"
import { matterStore } from "app/core/stores/store"
import { useQuery } from "blitz"
import { Entry } from "db"
import { isNil, some } from "lodash"
import { reaction, runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useReviewLock } from "../../billing/hooks/locked"
import { updateWithVersionBump } from "../TDocketTable"
import { TSelectableCell } from "../base/TSelectableCell"
import { ClickToEdit } from "./ClickToEdit"
const { Option } = Select

export const TActivityCodeCell = observer((props: { record: Entry; store: TableStore<Entry> }) => {
  const { record, store } = props
  const entryLocked = useReviewLock(record.billingReviewId)
  const [editing, setEditing] = useState(false)

  const [enabled, setEnabled] = useState(false)

  let row: Entry = props.store.rows.find((r) => r.id === props.record.id)!

  const [data, { isLoading, isFetching }] = useQuery(
    getActivityCodes,
    {},
    {
      cacheTime: 300_000,
      staleTime: 300_000,
    }
  )

  useEffect(() => {
    if (matterStore.finishLoad && row.matterId) {
      if (
        some(data?.activityCodes, (el) => {
          return (
            typeof el.clientId === "number" &&
            el.clientId === matterStore.findMatterFromId(row.matterId!)?.clientId
          )
        })
      ) {
        setEnabled(true)
      } else {
        setEnabled(false)
      }
    }
  }, [data?.activityCodes, row.matterId])

  // TODO: For some reason this reaction is called A LOT...
  // Listen to matter changes.
  reaction(
    () => props.store.matterUpdateForEntry,
    () => {
      // Make sure we are reacting to the correct entry...
      if (matterStore.finishLoad && props.store.matterUpdateForEntry[0] === row.id) {
        const matter = matterStore.findMatterFromId(props.store.matterUpdateForEntry[1])

        if (
          some(data?.activityCodes, (el) => {
            return typeof el.clientId === "number" && el.clientId === matter?.clientId
          })
        ) {
          setEnabled(true)
        } else {
          setEnabled(false)
        }
      }
    }
  )

  const save = (value: number) => {
    runInAction(() => {
      row.activityCodeId = value
    })
    updateWithVersionBump(row, 0, { id: row.id, activityCodeId: value }, store)
  }

  // Make sure the matterstore has finished loading...
  if (!matterStore.finishLoad || isLoading || isFetching) {
    return null
  }

  const options =
    data?.activityCodes?.map((t) => {
      return {
        name: `(${t.code}) ${t.description}`,
        value: t.id,
      }
    }) ?? []

  const optionsValues = (
    <>
      {options.map((o, i) => {
        return (
          <Option key={i} value={o.value}>
            {o.name}
          </Option>
        )
      })}
    </>
  )

  if (!enabled) {
    return null
  }

  if (editing) {
    return (
      <TSelectableCell
        disabled={entryLocked}
        options={optionsValues}
        value={props.record.activityCodeId}
        onSave={save}
      />
    )
  }

  let warning = false

  if (isNil(props.record.activityCodeId)) {
    warning = true
  }

  const selected = data?.activityCodes?.find((el) => el.id === props.record.activityCodeId)

  return (
    <ClickToEdit
      warning={warning}
      setEditing={() => setEditing(true)}
      value={`(${selected?.code}) ${selected?.description}`}
    />
  )
})
