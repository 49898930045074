import { Form, Select } from "antd"
import getClient from "app/clients/queries/getClient"
import getClients from "app/clients/queries/getClients"
import { invoke } from "blitz"
import { Client } from "db"
import React, { useEffect, useState } from "react"

const { Option } = Select

export const TClientSearch = (props: { initialClientId?: number; formProps: any; onSelect? }) => {
  const [data, setData] = useState<Client[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { onSelect } = props

  useEffect(() => {
    const load = async () => {
      if (props.initialClientId) {
        const clientQueryResult = await invoke(getClient, { id: props.initialClientId })
        setData([clientQueryResult])
      }
    }
    load()
  }, [props.initialClientId])

  const search = async () => {
    // LTE 1 because we populate it with the initial client (if there is one)
    if (data.length <= 1) {
      setLoading(true)

      // TODO: This should be made to infinite scroll. Can only handle up to 10000 take
      // And use onSearch handler
      // TODO: it should also include client grouping
      // TODO: should also include most recently used / most popular for the firm

      // Alphabetical ordering
      const clients = await invoke(getClients, {
        where: {},
        take: 10000,
        orderBy: { createdAt: "desc" },
      })
      setData(clients.clients)
      setLoading(false)
    }
  }

  return (
    <Form.Item {...props.formProps}>
      <Select
        onSelect={onSelect}
        showSearch
        dropdownStyle={{ minWidth: 500 }}
        loading={loading}
        optionFilterProp="search"
        placeholder="Please select the client"
        style={{ minWidth: 100 }}
        onClick={search}
      >
        {data.map((c, index) => {
          return (
            <Option
              key={index}
              label={`${c.name}`}
              search={`${c.name} ${c.contactName}`}
              value={c.id}
            >
              {c.name}
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}
