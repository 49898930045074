import { CheckIcon, DotsHorizontalIcon } from "@heroicons/react/solid"
import { Button, Dropdown, Menu, Popconfirm, Tag, Tooltip, notification } from "antd"
import { reviewPageStore } from "app/core/stores/ReviewStore"
import { AugmentedEntry } from "app/core/stores/StatisticStore"
import { TableStore, postedStore } from "app/core/stores/TableStore"
import { lawyerStore, matterStore, templateStore, whoAmIStore } from "app/core/stores/store"
import { syncStore } from "app/core/sync/store"
import { getRateFromEntry } from "app/core/utils/billing"
import { sortByMomentDate } from "app/core/utils/csv"
import getAllEntriesComments from "app/entries/queries/getAllEntriesComments"
import { truncateText } from "app/pages/organization/workspace"
import createTemplate from "app/templates/mutations/createTemplate"
import { invoke, useMutation } from "blitz"
import { Entry, MembershipRole, UserInOrganization } from "db"
import { assign, cloneDeep, isNil, omit } from "lodash"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import moment, { Moment } from "moment"
import router from "next/router"
import { ReactNode, memo, useEffect, useState } from "react"
import { useReviewLock } from "../billing/hooks/locked"
import { CreateButton } from "../button/TNewEntryButton"
import { ReactiveMatterCell } from "../disbursements/DisbursementsTable"
import { LoadingSpinner } from "../spinner/LoadingSpinner"
import { TEmbeddableTable } from "./TEmbeddableTable"
import { TExpandableTableProps } from "./TExpandableTable"
import { TTable } from "./TTable"
import { TActivityCodeCell } from "./cells/TActivityCodeCell"
import { TBillableTimeCell, maxHours } from "./cells/TBillableTimeCell"
import { TDateCell } from "./cells/TDateCell"
import { TDescriptionCell } from "./cells/TDescriptionCell"
import { TLawyerCell } from "./cells/TLawyerCell"
import { TMatterCell } from "./cells/TMatterCell"
import { TStopWatch, stopTimer } from "./cells/TStopWatch"
import { TTaskCell } from "./cells/TTaskCell"
import { TTaskCodeCell } from "./cells/TTaskCodeCell"
import { TimeCell } from "./cells/TimeCell"
import { LawyerFilterEntry, TaskFilterEntry } from "./cells/filters/Filters"
import { THeaderPadded, THeaderPaddedLess } from "./cells/headers/THeaders"
import { StaticLawyerCell } from "./cells/static/StaticLawyerCell"
import { formatArray, formatHours, hours } from "./cells/utility/time"
import { BillingStatusCell } from "./dockets/cells/BillingStatusCell"
import { CommentStatusCell } from "./dockets/cells/CommentStatusCell"

export enum TDocketTableType {
  WORKSPACE,
  POSTED,
  REPORT,
  PREBILL,
  BILL,
  HISTORY,
}

export const newEntry = (
  lawyerId: number,
  creatorId: number,
  orgId: number,
  store: TableStore<Entry>,
  settingTodaysDate: boolean
) => {
  return async () => {
    const lastDate = localStorage.getItem("lastDate") ?? moment().toDate() // Get the last saved date
    const matterIdString = localStorage.getItem("matterPreferenceSetting")
    const matterId = matterIdString ? parseInt(matterIdString) : undefined // Last saved matter
    const matterTask = localStorage.getItem("matterPreferenceSettingDefaultTask") ?? undefined // Last saved matter
    const newDate = settingTodaysDate ? moment().toDate() : moment(lastDate).toDate()

    const entry: Entry = {
      description: "",
      task: matterTask ?? "",
      date: newDate,
      times: "[]",
      history: [],
      lawyerId: lawyerId,
      matterId: matterId,
      flag: false,
      organizationId: orgId,
      userInOrganizationId: creatorId,
      createdAt: new Date(),
      updatedAt: new Date(),
    } as any

    const _new = await store.updateFn!(entry)

    runInAction(() => {
      store.setRows([...store.rows, _new])
    })
  }
}

export const sortEntriesByRateAndDate = (lawyers: UserInOrganization[]) => (a, b) => {
  if (moment(a.date).isSame(moment(b.date), "day")) {
    // Add an additional sort by hourly rate...
    // This should be tested...
    const rateA = getRateFromEntry(
      a,
      lawyers,
      matterStore.matterArray,
      matterStore.clientArray,
      new Date()
    )
    const rateB = getRateFromEntry(
      b,
      lawyers,
      matterStore.matterArray,
      matterStore.clientArray,
      new Date()
    )
    if (rateA && rateB) {
      return rateB - rateA
    } else {
      return 1
    }
  } else {
    return sortByMomentDate(a, b)
  }
}

export const EmbeddableColumns = [
  {
    title: "Date",
    dataIndex: "date",
    key: "date",
    render: (value, record: AugmentedEntry) => (
      <div>
        {moment(record.date).format("MMM-D-YYYY")} {record.flag ? <Tag>PCLaw sync</Tag> : null}
      </div>
    ),
  },
  {
    title: "Matter",
    dataIndex: "matterId",
    key: "matter",
    render: (value, record: AugmentedEntry) => <ReactiveMatterCell record={record as any} />,
  },
  {
    title: "Time",
    dataIndex: "timeInSeconds",
    render: (value: number) => {
      return hours(value)
    },
  },
  {
    title: "Lawyer",
    dataIndex: "lawyerId",
    key: "lawyer",
    render: (value, record: AugmentedEntry) => <StaticLawyerCell id={record.lawyerId} />,
  },
  {
    title: "Billable Amount",
    dataIndex: "billableAmount",
    key: "billableAmount",
    render: (value, record: AugmentedEntry) => <div>{record.statistics.amount.toFixed(2)}</div>,
    sorter: (a, b) => a.statistics.amount - b.statistics.amount,
  },
  {
    title: "Billable Hours",
    dataIndex: "billableHours",
    key: "billableHours",
    render: (value, record: AugmentedEntry) => (
      <div>{record.statistics.billableHours.toFixed(1)}</div>
    ),
    sorter: (a, b) => a.statistics.billableHours - b.statistics.billableHours,
  },
  {
    title: "Rate",
    dataIndex: "rate",
    key: "rate",
    render: (value, record: AugmentedEntry) => <div>{record.statistics.rate.toFixed(2)}</div>,
    sorter: (a, b) => a.statistics.rate - b.statistics.rate,
  },
  {
    title: "Task",
    dataIndex: "task",
    key: "task",
    render: (value, record: AugmentedEntry) => (
      <div>
        <Tooltip title={record.task}>{record.task ? record.task.toUpperCase() : ""}</Tooltip>
      </div>
    ),
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
    render: (value, record: AugmentedEntry) => (
      <div>
        <Tooltip title={record.description}>{truncateText(record.description, 35)}</Tooltip>
      </div>
    ),
  },
  {
    title: "Creator",
    dataIndex: "creator",
    key: "creator",
    render: (value, record: AugmentedEntry) => (
      <StaticLawyerCell id={record.userInOrganizationId} />
    ),
  },
  {
    title: "Created",
    dataIndex: "created",
    key: "created",
    render: (value, record: AugmentedEntry) => (
      <div>{moment(record.createdAt).format("MMM-D-YYYY")}</div>
    ),
  },
  {
    title: "Updated",
    dataIndex: "updated",
    key: "updated",
    render: (value, record: AugmentedEntry) => (
      <div>{moment(record.updatedAt).format("MMM-D-YYYY")}</div>
    ),
  },
]

export const TDocketEmbeddableTable = (
  props: TExpandableTableProps & { dataSource: AugmentedEntry[] }
) => {
  const sorted = props.dataSource.sort((a, b) => sortByMomentDate(a.date, b.date))
  return (
    <TEmbeddableTable
      columns={EmbeddableColumns}
      dataSource={sorted}
      expands={props.expands}
      style={{}}
    />
  )
}

export const postCheck = (record: Entry) => {
  const errors: String[] = []

  if (!record.matterId) {
    errors.push("Missing matter")
  }

  if (!record.task) {
    errors.push("Missing task")
  }

  if (!record.date) {
    errors.push("Invalid date")
  }

  if (record.times) {
    let entry = stopTimer(record)

    const max = maxHours(entry)

    let hours = formatArray(JSON.parse(entry.times))

    if (hours > max) {
      errors.push(`Too many hours. This matter is limited to ${max} by organization.`)
    }
  }

  if (
    whoAmIStore.me.invitedEmail !== "jmcmillan@sprigings.com" &&
    whoAmIStore.me.invitedEmail !== "cyu@sprigings.com" &&
    whoAmIStore.me.role !== MembershipRole.ADMIN
  ) {
    // Check to see if there is already a review for this matter and date.
    const reviewsWithMatter = reviewPageStore.reviews.filter((f) => f.matterId === record.matterId)
    if (reviewsWithMatter.length > 0) {
      const finalizedReviewExists = reviewsWithMatter.some((r) => {
        return r.finalized && moment(r.endDate).isAfter(moment(record.date))
      })
      if (finalizedReviewExists) {
        errors.push(
          "This date and matter combination is locked because a finalized bill exists. A user with the accountant role may add this entry"
        )
      }
    }
  }

  const valid = errors.length === 0

  return { errors, valid }
}

export const postEntry = async (value: Entry, store) => {
  const record = cloneDeep(value)

  // Set task to default if not exist...
  record.task = !record.task || record.task === "" ? "bw" : record.task

  const check = postCheck(record)

  if (check.valid) {
    const newVersion = 1 // TODO: Remove versions, they are stupid.
    // TODO: For some reason this posted isn't making it to createEntry?
    const newValues = { posted: moment().toDate(), times: stopTimer(record).times }

    store.setRows([...store.rows!.filter((e) => e.id !== value.id)])

    const postgresValue = omit(record, ["id", "_id", "_rev"]) // Remove the placeholder id and let the server generate one. Also remove `_id` and `_rev` as they are only for couchdb.
    updateWithVersionBump(
      // Clone so that the following two local state actions can run asynchronously.
      postgresValue as any,
      newVersion,
      newValues,
      postedStore // Use the posted store because we are trying to move the entry!
    )
      .then((insertedRecord) => {
        const couchValue = cloneDeep(record)
        // Force an update in the posted tab once it completes...
        postedStore.addRowAtBeginning(insertedRecord)
        // Ensure adding to postgres is successfull. Then change the values in couchdb.
        updateWithVersionBump(
          // Clone so that the following two local state actions can run asynchronously.
          couchValue,
          newVersion,
          newValues, // The `posted` value effectively filters it out of the couchdb workspace.
          store // Use the couchdb store because we are trying to remove the entry.
        ).then(() => {
          // We use the initial id to filter out the couchdb entry... This is different than the id generated upon insertion into postgres.
        })
      })
      .catch((error) => {
        // If there is an error... Undo the following two actions...
      })
  } else {
    notification.open({
      message: check.errors.reduce(
        (prev: string, cur: string) => prev + cur + ". ",
        "Cannot post the docket. "
      ),
    })
  }
}

export const updateWithVersionBump = async (
  row: Entry & { _id?: string; _rev?: string },
  version: number, // Delete this.
  newValues: Partial<Entry>,
  store: TableStore<Entry> // We need to pass the store so that we can update the `_rev` field.
) => {
  const merge = { ...row, ...newValues, version: version }
  const result = await store.updateFn!(merge, newValues)

  if (result._rev) {
    // If it is a couchdb update with a `rev`, then we need to bump the `_rev`.
    runInAction(() => {
      row._rev = result._rev
    })
  }

  return result
}

export const TDocketTable = observer(
  (
    props: TExpandableTableProps & {
      type: TDocketTableType
      store: TableStore<Entry>
      reviewId?: number
      range?: Moment[]
      billId?: number
      matterId?: number
      tasks?: string[]
    }
  ) => {
    const { store, range, matterId, reviewId } = props

    // TODO: We should isolate components based on which actually need to be in the parent here.
    // Probably should move entries lower, and lawyers higher.

    const lawyer = whoAmIStore.me
    const lawyers = lawyerStore.lawyersArray

    const [loading, setLoading] = useState(true)

    const isWorkspaceView = props.type === TDocketTableType.WORKSPACE
    const isPostedView = props.type === TDocketTableType.POSTED
    const isReportView = props.type === TDocketTableType.REPORT
    const isPreBillView = props.type === TDocketTableType.PREBILL
    const isBillView = props.type === TDocketTableType.BILL

    let _where: any = { userInOrganizationId: whoAmIStore.me.id }
    let params = {}
    let orderBy = {}

    if (isPostedView) {
      _where = { ..._where, posted: { not: null } }
      params = { take: 200, maxTake: 200 }
      orderBy = { date: "desc" }
    } else if (isWorkspaceView) {
      _where = { ..._where, posted: null }
      orderBy = { createdAt: "asc" }
    } else if (isReportView && props.range) {
      _where = {
        date: { gte: props.range[0].toDate(), lte: props.range[1].toDate() },
        posted: { not: null },
      }
      // TODO: This should be infinite scrolling...
      params = { take: 2000, maxTake: 2000 }
      orderBy = { date: "desc" }
    } else if (isPreBillView && props.reviewId) {
      _where = {
        prebillingReviewId: props.reviewId,
        posted: { not: null },
        // billingReviewId: null, // Should this be here? Prevents seeing entries that have already been billed...
        matterId: { equals: props.matterId },
        task: { in: props.tasks },
      }
      // TODO: This should be infinite scrolling...
      params = { take: 2000, maxTake: 2000 }
      // Prebill and billing views want ascending order...
      orderBy = { date: "asc" }
    } else if (isBillView && props.billId) {
      _where = {
        posted: { not: null },
        billingReviewId: props.billId,
      }
      // TODO: This should be infinite scrolling...
      params = { take: 10000, maxTake: 10000 }
      // Prebill and billing views want ascending order...
      orderBy = { date: "asc" }
    }

    useEffect(() => {
      if (!reviewPageStore.finishLoad && !reviewPageStore.initialLoad) {
        reviewPageStore.load()
      }
    }, [])

    useEffect(() => {
      if (isWorkspaceView && syncStore.database) {
        syncStore.database
          ?.fetchInitialDocs()
          .then((docs) => {
            store.setRows(
              cloneDeep(
                docs.rows
                  .filter((f) => !(f.doc as any).posted && !(f.doc as any).deleted)
                  .map((r) => r.doc as any)
              ) // Only pull in dockets that are not posted or deleted.
            )
          })

          .then(() => {
            setLoading(false)
            syncStore.database?.onChanges((change) => {
              // TODO: Should we cancel this listener on unmount?

              if (isNil(change) || isNil(change?.doc)) {
                return
              }

              // If the docket is deleted or posted, filter it out.
              if (change.deleted || change.doc.posted || change.doc.deleted) {
                store.setRows(
                  store.rows.filter((r: Entry & { _id: string }) => r._id !== change.id)
                )
              } else if (change.doc) {
                const row = store.rows.find((r: Entry & { _id: string }) => r._id === change.id)

                if (row) {
                  runInAction(() => {
                    assign(row, change.doc) // This will not cause a full rerender, like `setRows` does.
                  })
                } else {
                  store.appendRow(change.doc)
                }
              }
            })
          })
      } else if (!isWorkspaceView) {
        invoke(getAllEntriesComments, {
          where: { ..._where },
          orderBy: orderBy,
          ...params,
        }).then((entries) => {
          runInAction(() => {
            store.setRows(entries.sort(sortEntriesByRateAndDate(lawyers)))
          })
          setLoading(false)
        })
      }
    }, [syncStore.database, range, matterId]) // Change when the syncstore is changes, the range changes (report), or the matterId changes (prebill).

    let prefixColumns: any[] = []

    if (isWorkspaceView) {
      prefixColumns = [
        {
          key: "stopwatch",
          width: "5%",
          render: (value, record) => {
            return <TStopWatch lawyer={lawyer} record={record} store={store} />
          },
        },
      ]
    } else {
      prefixColumns = [
        {
          key: "status",
          width: "5%",
          render: (value, record) => {
            return (
              <div style={{ display: "flex", flexDirection: "row" }}>
                {/* TODO: We don't need to show this on the prebill review page. */}
                <ReviewLink record={record}>
                  <BillingStatusCell record={record} />
                </ReviewLink>

                <CommentStatusCell record={record} />
              </div>
            )
          },
        },
      ]
    }

    const timeColumns = [
      {
        title: <THeaderPaddedLess>Duration</THeaderPaddedLess>,
        dataIndex: "times",
        key: "duration",
        width: "5%",
        responsive: ["xl"],
        render: (value, record) => {
          return <TimeCell record={record} store={props.store} />
        },
      },
      {
        title: <THeaderPadded>Billable</THeaderPadded>,
        dataIndex: "times",
        key: "billable",
        width: "2%",
        render: (value, record) => {
          return <TBillableTimeCell record={record} store={props.store} />
        },
        sorter: (a, b) => {
          const aHours = formatHours([a])
          const bHours = formatHours([b])
          return aHours - bHours
        },
      },
    ]

    const tooltipFunction = (param: string) => {
      let tooltip: string | undefined = undefined

      if (isWorkspaceView) {
        // Check if date is more than 40 days ago.
        const date = moment(param)
        const today = moment()
        const diff = Math.abs(today.diff(date, "days"))

        if (diff > 40) {
          tooltip = "This date is more than 40 days from today."
        }
      }
      return tooltip
    }

    const columns = [
      ...prefixColumns,
      ...(isPreBillView ? [] : timeColumns), // Time columns are not showed at the front for the prebill.
      {
        title: <THeaderPaddedLess>Date</THeaderPaddedLess>,
        dataIndex: "date",
        key: "date",
        width: "10%",
        render: (value, record) => {
          return <TDateCell tooltipFunction={tooltipFunction} record={record} store={props.store} />
        },
        sorter: (a, b) => {
          return sortEntriesByRateAndDate(lawyers)(b.date, a.date)
        },
      },
      {
        title: <THeaderPadded>Matter</THeaderPadded>,
        dataIndex: "matter",
        key: "matter",
        width: "10%",
        render: (value, record) => {
          return <TMatterCell record={record} store={props.store} />
        },
        sorter: (a, b) => {
          return a.matterId - b.matterId
        },
      },
      {
        title: <THeaderPadded>Type</THeaderPadded>,
        dataIndex: "task",
        key: "task",
        width: "5%",
        responsive: ["xl"],
        render: (value, record) => {
          return <TTaskCell record={record} store={props.store} />
        },
        sorter: (a, b) => {
          return (a.task as string).localeCompare(b.task)
        },
        ...TaskFilterEntry(),
      },
      {
        title: <THeaderPadded>Task</THeaderPadded>,
        dataIndex: "taskCodeId",
        key: "taskCodeId",
        width: "2%",
        responsive: ["xl"],
        render: (value, record) => {
          return <TTaskCodeCell record={record} store={props.store} />
        },
        sorter: (a, b) => {
          return a.taskCodeId - b.taskCodeId
        },
      },
      {
        title: <THeaderPadded>Activity</THeaderPadded>,
        dataIndex: "activityCodeId",
        key: "activityCodeId",
        width: "2%",
        responsive: ["xl"],
        render: (value, record) => {
          return <TActivityCodeCell record={record} store={props.store} />
        },
        sorter: (a, b) => {
          return a.activityCodeId - b.activityCodeId
        },
      },
      {
        title: <THeaderPadded>Lawyer</THeaderPadded>,
        dataIndex: "userInOrganizationId",
        key: "userInOrganizationId", // dataIndex and key should be kept the same for clarity.
        width: "2%",
        responsive: ["xl"],
        render: (value, record) => {
          return (
            <TLawyerCell
              lawyers={lawyers}
              record={record}
              store={props.store}
              userInOrgId={value}
            />
          )
        },
        ...LawyerFilterEntry(lawyers),
      },
      ...(!isPreBillView ? [] : timeColumns), // Time columns are showed at the end for the prebill.
      {
        title: <THeaderPadded>Description</THeaderPadded>,
        dataIndex: "description",
        key: "description",
        width: "40%",
        render: (value, record) => {
          return <TDescriptionCell reviewId={reviewId} record={record} store={props.store} />
        },
      },

      {
        title: "",
        key: "actions",
        width: "3%",
        render: (value, record) => {
          return (
            <div style={{ display: "flex" }}>
              <GroupButton record={record} />
            </div>
          )
        },
      },
    ]

    const ReviewLink = (props: { record: Entry; children: ReactNode | ReactNode[] }) => {
      const { record, children } = props

      if (record.billingReviewId) {
        return (
          <div role="button" style={{ cursor: "pointer" }} tabIndex={0} onKeyDown={() => {}}>
            {children}
          </div>
        )
      }

      return <>{children}</>
    }

    const GroupButton = memo((props: { record: Entry }) => {
      const { record } = props

      const ActionMenu = () => {
        const entryLocked = useReviewLock(record.billingReviewId)
        const [_createTemplate] = useMutation(createTemplate)

        const deleteEntry = () => {
          try {
            store?.deleteFn!(record as any)
            runInAction(() => {
              store.setRows([...store.rows!.filter((e) => e.id !== record.id)])
            })
          } catch {
            notification.open({
              message:
                "Unable to delete docket. This is usually because it is linked to a Invoice.",
            })
          }
        }

        const template = async () => {
          const result = await _createTemplate({
            description: props.record.description,
            task: props.record.task,
            matterId: props.record.matterId ?? undefined,
          })
          templateStore.setTemplates([...templateStore.templates, result])
        }

        return (
          <Menu triggerSubMenuAction="click">
            {!isWorkspaceView && (
              <Menu.Item
                key="1"
                style={{ borderRadius: 0 }}
                onClick={() => {
                  router.push(`/organization/entries/${record.id}`)
                }}
              >
                Activity log
              </Menu.Item>
            )}

            <Menu.Item key="1" style={{ borderRadius: 0 }} onClick={template}>
              Save as template
            </Menu.Item>

            <Menu.Item
              key="1"
              style={{ borderRadius: 0 }}
              onClick={() => {
                navigator.clipboard.writeText(record?.id?.toString() ?? "")
              }}
            >
              Copy debug info
            </Menu.Item>

            <Menu.Item disabled={entryLocked} key="2" style={{ borderRadius: 0, width: "100%" }}>
              <Popconfirm
                title="Delete docket？"
                onConfirm={() => {
                  deleteEntry()
                }}
              >
                <div style={{ display: "inline-block", width: "100%" }}>Delete</div>
              </Popconfirm>
            </Menu.Item>
          </Menu>
        )
      }

      return (
        <>
          {isWorkspaceView ? (
            <Popconfirm
              title="Post docket?"
              onConfirm={() => {
                postEntry(props.record, store)
              }}
            >
              <div style={{ marginRight: 10 }}>
                <Button
                  style={{
                    width: "75px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <CheckIcon height={16} />
                </Button>
              </div>
            </Popconfirm>
          ) : null}
          <Dropdown overlay={ActionMenu()} trigger={["click"]}>
            <Button
              style={{
                width: "65px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <DotsHorizontalIcon height={16} />
            </Button>
          </Dropdown>
        </>
      )
    })

    if (loading || !lawyer) {
      return <LoadingSpinner center />
    }

    return (
      <div>
        <TTable
          {...props}
          columns={columns.filter((c) => !(c.key === "lawyer" && isWorkspaceView))} // Do not show the lawyer column in workspace view.
          empty={
            isWorkspaceView
              ? {
                  title: "No dockets",
                  subtitle: "Create a docket to bill your time.",
                  action: (
                    <CreateButton
                      text={"Add docket"}
                      onAdd={newEntry(
                        whoAmIStore.me.id!,
                        whoAmIStore.me.id!,
                        whoAmIStore.me.organizationId!,
                        store,
                        lawyer!.settingTodaysDate
                      )}
                    />
                  ),
                }
              : {
                  title: "Nothing to report",
                  subtitle: "0 dockets found based on this criteria.",
                  action: null,
                }
          }
          expands={props.expands}
          rowClassName={() => "editable-row"}
          store={props.store}
          style={{ paddingBottom: 200 }}
        />
      </div>
    )
  }
)
