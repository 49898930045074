import { ClockIcon } from "@heroicons/react/solid"
import { Badge, BadgeDimensions, colours } from "./Badge"

export const BlueBadge = (props: { title: string; onClick?: VoidFunction; icon?: JSX.Element }) => {
  return (
    <Badge {...props} colour={colours.blue} title={props.title}>
      {props.icon ? props.icon : <ClockIcon {...BadgeDimensions} />}
    </Badge>
  )
}

export const InfoBadge = (props: { title: string; onClick?: VoidFunction }) => {
  return (
    <Badge {...props} colour={colours.info} title={props.title}>
      <ClockIcon {...BadgeDimensions} />
    </Badge>
  )
}

export const WarningBadge = (props: { title: string; onClick?: VoidFunction }) => {
  return (
    <Badge {...props} colour={colours.warning} title={props.title}>
      <ClockIcon {...BadgeDimensions} />
    </Badge>
  )
}
