import { getRateFromEntry } from "app/core/utils/billing"
import { Client, Entry, Matter, UserInOrganization } from "db"
import { round } from "lodash"
import { formatHours } from "../../cells/utility/time"

interface Total {
  totalHours: number
  billableHours: number
  billableAmount: number
  billableRate: number
}

interface Totals extends Total {
  byLawyer: Record<string, Total>
}

const calculateTotal: (
  entry: Entry,
  lawyers: UserInOrganization[],
  matters: Matter[],
  clients: Client[]
) => Total = (
  entry: Entry,
  lawyers: UserInOrganization[],
  matters: Matter[],
  clients: Client[]
) => {
  const billableRate: number | undefined =
    getRateFromEntry(entry, lawyers, matters, clients, entry.date) ?? 0
  const totalHours = round(formatHours([entry]), 1)
  let billableHours = 0
  if (entry.task === "bw") {
    billableHours = totalHours
  }
  const billableAmount = round(billableRate * billableHours, 2)
  return { totalHours, billableHours, billableAmount, billableRate }
}

// This should replace `getBillingDetails` which is deprecated...
export const calculateTotals: (
  entries: Entry[],
  lawyers: UserInOrganization[],
  matters: Matter[],
  clients: Client[]
) => Omit<Totals, "billableRate"> = (
  entries: Entry[],
  lawyers: UserInOrganization[],
  matters: Matter[],
  clients: Client[]
) => {
  const reduction = (entries as any).reduce(
    (acc: Totals, e: Entry) => {
      const entryTotal = calculateTotal(e, lawyers, matters, clients)
      const byLawyer = {
        ...acc.byLawyer,
        [e.lawyerId.toString()]: {
          ...acc.byLawyer[e.lawyerId],
          totalHours: round((acc.byLawyer[e.lawyerId]?.totalHours ?? 0) + entryTotal.totalHours, 1),
          billableHours: round(
            (acc.byLawyer[e.lawyerId]?.billableHours ?? 0) + entryTotal.billableHours,
            2
          ),
          billableAmount: round(
            (acc.byLawyer[e.lawyerId]?.billableAmount ?? 0) + entryTotal.billableAmount,
            2
          ),
        },
      }
      return {
        byLawyer,
        totalHours: acc.totalHours + entryTotal.totalHours,
        billableHours: acc.billableHours + entryTotal.billableHours,
        billableAmount: acc.billableAmount + entryTotal.billableAmount,
      }
    },
    { totalHours: 0, billableHours: 0, billableAmount: 0, byLawyer: {} }
  )

  return {
    byLawyer: reduction.byLawyer,
    totalHours: round(reduction.totalHours, 1),
    billableHours: round(reduction.billableHours, 1),
    billableAmount: round(reduction.billableAmount, 2),
  }
}
