import { Authorizable } from "app/core/sync"
import axios from "axios"
import { Entry } from "db"
import { makeObservable, observable } from "mobx"

class SyncStore {
  database: Authorizable<Entry> | null = null
  databaseName: string | null = null

  constructor() {
    makeObservable(this, {
      database: observable,
    })
  }

  start = (userId) => {
    this.databaseName = `private-${userId!.toString()}`
    this.database = new Authorizable()
    this.database!.init(this.databaseName!)
    axios
      .get("/api/sync/new_user")
      .then((res) => {
        setTimeout(() => {
          this.sync() // Start sync with a delay to give database some time to be created.
        }, 7500)
      })
      .catch((error) => {
        if (error.response.status === 409) {
          this.sync() // Start syncing immediately if the server returns with 409.
        }
      })
  }

  sync = () => {
    axios.get("/api/sync/new_access_token").then((res) => {
      this.database!.sync(res.data.token)
      this.database!.fetchInitialDocs().then((r) => {
        console.log(r)
      })
    })
  }
}

export const syncStore = new SyncStore()
