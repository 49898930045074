import { makeObservable, observable } from "mobx"
import PouchDB from "pouchdb"

const endpoint = "https://sync.evatir.com/"

export enum SYNC_STATUS {
  OFFLINE = "Offline",
  PENDING = "Pending",
  ERROR = "Error",
  SYNCED = "Synced",
}

export class Authorizable<T> {
  database: PouchDB.Database | null
  localDatabase: PouchDB.Database | null
  remoteDatabase: PouchDB.Database | null
  databaseName: string | null
  status: SYNC_STATUS = SYNC_STATUS.PENDING
  syncActiveEvent: boolean
  syncChangeEvent: boolean

  authorized = false

  init = (databaseName: string) => {
    this.databaseName = databaseName
    this.localDatabase = new PouchDB(databaseName)
    this.database = this.localDatabase
  }

  constructor() {
    this.databaseName = null
    this.database = null
    this.localDatabase = null
    this.remoteDatabase = null
    this.syncActiveEvent = false
    this.syncChangeEvent = false

    makeObservable(this, {
      status: observable,
    })
  }

  fetchInitialDocs() {
    return this.database!.allDocs({ include_docs: true })
  }

  sync(token: string) {
    if (!this.remoteDatabase) {
      this.remoteDatabase = new PouchDB(endpoint + this.databaseName, AuthorizeOptions(token))
    }

    if (this.remoteDatabase != null) {
      this.localDatabase!.sync(this.remoteDatabase as PouchDB.Database, {
        live: true,
        retry: true,
      })
        .on("change", (info) => {
          // handle change
          this.syncChangeEvent = true
          console.log(info)
        })
        .on("paused", (err) => {
          // replication paused (e.g. replication up to date, user went offline)
          console.log(err)
          if (this.syncActiveEvent && !this.syncChangeEvent) {
            this.status = SYNC_STATUS.OFFLINE
          } else {
            this.status = SYNC_STATUS.PENDING
          }
          this.syncChangeEvent = false
          this.syncActiveEvent = false
        })
        .on("active", () => {
          // replicate resumed (e.g. new changes replicating, user went back online)
          this.syncActiveEvent = true
          this.status = SYNC_STATUS.SYNCED
        })
        .on("denied", (err) => {
          // a document failed to replicate (e.g. due to permissions)
          console.log(err)
          this.status = SYNC_STATUS.ERROR
        })
        .on("complete", (info) => {
          // handle complete - doesn't trigger if live == `true`.
          console.log(info)
          this.status = SYNC_STATUS.SYNCED
        })
        .on("error", (err) => {
          // handle error
          console.log(err)
          this.status = SYNC_STATUS.ERROR
        })
    }
  }

  onChanges(
    callback: (change?: PouchDB.Core.ChangesResponseChange<Record<string, unknown>>) => void
  ) {
    const listener = this.database!.changes({ live: true, since: "now", include_docs: true })
      .on("change", (change) => {
        if (change.doc?._id.indexOf("_design") !== -1) {
          return
        }
        callback(change)
      })
      .on("error", console.log.bind(console))
    return listener
  }

  delete(value: T & { _id: string }) {
    value = { deleted: true, ...value } // This will not actually delete the document. It will just flag it as deleted.
    return this.database!.put(value)
  }

  update(value: T & { _id: string }) {
    // Must include id if it is a new document.
    return this.database!.put(value)
  }

  create(value: T) {
    return this.database!.post(value as any)
  }
}

export const AuthorizeOptions = (token: string) => {
  return {
    skip_setup: false,
    fetch: function (url: any, opts: any) {
      const headers = {
        ...opts.headers,
        "content-type": "application/json",
        Authorization: `Bearer ${token}`,
      }
      const o = { ...opts, headers: headers }
      return PouchDB.fetch(url, o)
    },
  }
}
