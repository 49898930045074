import { AutoComplete } from "db"
import { when } from "mobx"
import { useEffect, useState } from "react"
import { autoCompleteStore } from "../AutoCompleteStore"

export const useAutoCompleteStore = (): [boolean, AutoComplete[]] => {
  const [ready, setReady] = useState(false)

  useEffect(() => {
    autoCompleteStore.load()
    when(
      () => autoCompleteStore.finishLoad,
      () => setReady(true)
    )
  })

  return [ready, autoCompleteStore.autocompletesArray]
}
