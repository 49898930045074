import { AugmentedStatistic } from "app/core/stores/StatisticStore"

export const BillableAmountColumn = {
  title: "Billable Amount",
  dataIndex: "amount",
  key: "billableAmount",
  render: (value, record: AugmentedStatistic) => {
    return <div>{record.statistics.amount.toFixed(2)}</div>
  },
  defaultSortOrder: "descend" as any,
  sorter: (a, b): number => {
    return a.statistics.amount - b.statistics.amount
  },
}

export const BillableHoursColumn = {
  title: "Billable Hours",
  dataIndex: "hours",
  key: "billableHours",
  render: (value, record: AugmentedStatistic) => {
    return <div>{record.statistics.billableHours.toFixed(1)}</div>
  },
  sorter: (a, b): number => {
    return a.statistics.billableHours - b.statistics.billableHours
  },
}

export const TotalHoursColumn = {
  title: "Total Hours",
  dataIndex: "totalHours",
  key: "totalHours",
  render: (value, record: AugmentedStatistic) => {
    return <div>{record.statistics.totalHours.toFixed(1)}</div>
  },
  sorter: (a, b): number => {
    return a.statistics.totalHours - b.statistics.totalHours
  },
}
