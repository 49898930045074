import { useClickAway } from "ahooks"
import { DatePicker } from "antd"
import { TableStore } from "app/core/stores/TableStore"
import { Entry } from "db"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { memo, useEffect, useRef, useState } from "react"
import { useReviewLock } from "../../billing/hooks/locked"
import { updateWithVersionBump } from "../TDocketTable"
import { ClickToEdit } from "./ClickToEdit"

export const TDateCell = memo((props: any) => {
  return <DateCell {...props} />
})

const DateCell = observer(
  (props: {
    record: Entry
    store: TableStore<Entry>
    tooltipFunction?: (date: string) => string
  }) => {
    const { record, store, tooltipFunction } = props
    const row: Entry = props.store.rows.find((r) => r.id === props.record.id)!

    const entryLocked = useReviewLock(record.billingReviewId)

    const [editing, setEditing] = useState(false)
    const [value, setValue] = useState(moment(row!.date))

    const ref = useRef<any>()
    const pickerRef = useRef<any>()

    useClickAway(() => {
      setEditing(false)
    }, pickerRef)

    useEffect(() => {
      if (editing) {
        ref.current!.focus()
      }
    }, [editing])

    useEffect(() => {
      setValue(moment(row!.date))
    }, [row.date]) // If couchdb is updated (maybe from another browser), update the value.

    const NotEditable = () => {
      const localTooltip = tooltipFunction?.(value.toISOString())
      return (
        <ClickToEdit
          warning={!!localTooltip}
          tooltip={localTooltip}
          setEditing={() => setEditing(true)}
          value={value.format("MMM-D-YYYY")}
        />
      )
    }

    const Editable = (props: {}) => {
      return (
        <DatePicker
          allowClear={false}
          disabled={entryLocked}
          format={"MMM-D-YYYY"}
          pickerRef={pickerRef}
          ref={ref}
          size={"middle"}
          style={{ minWidth: 130, marginTop: 2, marginBottom: 2 }}
          suffixIcon={null}
          value={value}
          // Margin is important for centering in the table row.
          onBlur={() => {
            setEditing(false)
          }}
          onChange={(_date) => {
            const newVersion = row.version + 1

            // Make this the middle of the day.
            const date = moment(_date).set("hour", 12).set("minute", 0).set("second", 0)

            runInAction(() => {
              if (date) {
                row!.date = date!.toDate()
                row.version = newVersion
                localStorage.setItem("lastDate", date!.toISOString()) // Set the last saved date - if the user wants to use it for the next new entry
              }
            })
            if (date) {
              updateWithVersionBump(row, newVersion, { id: row!.id!, date: row!.date! }, store)
            }
            setEditing(false) // Cancel the editing session after a successful save.
          }}
        />
      )
    }

    return <>{editing ? <Editable /> : <NotEditable />}</>
  }
)
