import { MultiSelect } from "@mantine/core"
import { Button, DatePicker, Form, Select } from "antd"
import { TButton } from "app/core/components/button/TButton"
import { FormMatterSelect } from "app/core/components/matters/MatterSearch"
import { lawyerStore } from "app/core/stores/store"
import getDisbursements from "app/disbursements/queries/getDisbursements"
import getEntries from "app/entries/queries/getEntries"
import { TRangePicker } from "app/pages/organization/reports"
import createReview from "app/reviews/mutations/createReview"
import updateReview from "app/reviews/mutations/updateReview"
import { invoke, useMutation, useQuery } from "blitz"
import { Disbursement, Entry, UserInOrganization } from "db"
import { isNil, uniqBy } from "lodash"
import moment, { Moment } from "moment"
import { useState } from "react"
import { LoadingSpinner } from "../../spinner/LoadingSpinner"
import { TForm } from "../settings/forms/TForm"

const { Option } = Select

export const autopopulateReviewers = async (endDate: Date, matterId: number) => {
  const peopleSet = new Set<number>()
  const dockets = await invoke(getEntries, {
    where: {
      deleted: false,
      task: "bw",
      flag: false,
      matterId,
      date: {
        lte: endDate,
      },
    },
  })
  dockets.entries.forEach((curr: Entry) => peopleSet.add(curr.lawyerId))
  return Array.from(peopleSet.values())
}

export const PreBillForm = (props: { initialValues?: any; setQueryData: any }) => {
  const [createReviewMutation] = useMutation(createReview)
  const [updateReviewMutation] = useMutation(updateReview)

  const [form] = Form.useForm()
  const lawyers = lawyerStore.lawyersArray

  let _converted: any = undefined

  if (props.initialValues) {
    _converted = {
      ...props.initialValues,
      entryRange: props.initialValues.entryRange.map((d) => moment(d)),
      due: props.initialValues.due ? moment(props.initialValues.due) : undefined,
    }
  }

  const defaultRange = _converted ? _converted.entryRange : undefined

  const [range, setRange] = useState<Moment[]>(defaultRange ?? undefined)
  const [matterId, setMatterId] = useState<number>(props.initialValues?.matterId ?? undefined)

  const [loading, setLoading] = useState(false)

  return (
    <TForm
      includeUIOID
      newSetQueryData
      create={createReviewMutation}
      form={form}
      index={"userInOrganizations"}
      initialValues={_converted}
      setQueryData={props.setQueryData}
      update={updateReviewMutation}
    >
      <FormMatterSelect
        initialValues={props.initialValues}
        onSelect={(value) => setMatterId(value)}
      />
      <Form.Item name="endDate" hidden></Form.Item>
      <Form.Item
        required
        label="Entry Range"
        name="entryRange"
        rules={[
          {
            required: true,
            message: "Please input!",
          },
        ]}
        tooltip="This is a required field"
      >
        <TRangePicker
          default={defaultRange}
          onChange={(value: Moment[]) => {
            setRange(value)
            // We do this to keep the current "true" date, but not the adjusted time.
            form.setFieldsValue({
              // This value is timezone sensitive, since it should be universal,
              // regardless of where the accounant is.
              // TODO: Make this consistent with the timezone setting in the workspace's profile.
              // NOTE: do not use `endOf` or `startOf` here, as it will adjust the time past the UTC-offset date.
              // This is a problem, because the date is in UTC format already, 01-01-2022 00:00 UTC, which is 12-31-2021 19:00 EST.
              endDate: value[1].toDate(),
            })
          }}
        />
      </Form.Item>

      {(form.getFieldValue("matterId") ?? props.initialValues?.matterId) && (
        <DisbursementSelector
          form={form}
          reviewId={props.initialValues?.id}
          matterId={form.getFieldValue("matterId") ?? props.initialValues?.matterId}
        ></DisbursementSelector>
      )}
      <ReviewerSelects initialValues={props.initialValues} userInOrganizations={lawyers} />

      {range && matterId ? (
        <>
          <TButton
            onClick={async () => {
              const reviewers = await autopopulateReviewers(
                moment(form.getFieldValue("endDate")).toDate(),
                matterId
              )
              // TODO: review1 should by more dynamic...
              form.setFieldsValue({ review2: reviewers })
            }}
          >
            Add contributing reviewers
          </TButton>
        </>
      ) : null}

      <TButton
        onClick={async () => {
          const reviewers = lawyerStore.activeLawyers.map((lawyer) => lawyer.id)
          // TODO: review1 should by more dynamic...
          form.setFieldsValue({ review1: reviewers })
        }}
      >
        Add all workspace members
      </TButton>

      <Form.Item label="Review Due" name="due">
        <DatePicker format={"MMM-D-YYYY"} />
      </Form.Item>
      <Form.Item>
        {loading ? (
          <LoadingSpinner center />
        ) : (
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        )}
      </Form.Item>
    </TForm>
  )
}

const DisbursementSelector = ({ reviewId, matterId, form }) => {
  const [data, { isError, isLoading }] = useQuery(getDisbursements, {
    where: { billingReviewId: null, prebillingReviewId: null, estimate: false, matterId },
  })

  const [existingDisbursements, { isError: isError2, isLoading: isLoading2 }] = useQuery(
    getDisbursements,
    {
      where: { prebillingReviewId: reviewId, matterId },
    },
    { enabled: !!reviewId }
  )

  if (!data) {
    return <LoadingSpinner center />
  }

  if (reviewId && !existingDisbursements) {
    return <LoadingSpinner center />
  }

  let initialValues: Disbursement[] = []

  if (reviewId && existingDisbursements) {
    initialValues = existingDisbursements?.disbursements
  } else if (data) {
    initialValues = data?.disbursements

    if (isNil(form.getFieldValue("disbursementPrebill"))) {
      form.setFieldsValue({ disbursementPrebill: initialValues.map((d) => d.id?.toString()) })
    }
  }

  return (
    <Form.Item name={"disbursementPrebill"} label="Disbursements to include">
      <MultiSelect
        comboboxProps={{ withinPortal: false }}
        defaultValue={initialValues?.map((d) => d.id?.toString())}
        data={uniqBy(data?.disbursements?.concat(initialValues ?? []), "id")?.map((d) => ({
          label: d.reason,
          value: d.id?.toString(),
        }))}
      ></MultiSelect>
    </Form.Item>
  )
}

const ReviewerSelects = (props: {
  initialValues: any
  userInOrganizations: UserInOrganization[]
}) => {
  const { userInOrganizations } = props

  const initial = (tier: number) => {
    if (props.initialValues && props.initialValues.reviewers) {
      return props.initialValues.reviewers.filter((i) => i.tier === tier).map((i) => i.value)
    } else {
      return []
    }
  }

  return (
    <>
      <ReviewerSelect
        initialValues={initial(1)}
        tier={1}
        userInOrganizations={userInOrganizations}
      />
      <ReviewerSelect
        initialValues={initial(2)}
        tier={2}
        userInOrganizations={userInOrganizations}
      />
      <ReviewerSelect
        initialValues={initial(3)}
        tier={3}
        userInOrganizations={userInOrganizations}
      />
    </>
  )
}

export const ReviewerSelect = (props: {
  initialValues: any
  tier: number
  userInOrganizations
}) => {
  return (
    <Form.Item
      initialValue={props.initialValues}
      label={`Reviewing Tier ${props.tier}`}
      name={`review${props.tier}`}
      tooltip="This is a required field"
    >
      <Select
        showSearch
        mode={"multiple"}
        optionFilterProp="search"
        placeholder="Please select the reviewers"
      >
        {props.userInOrganizations.map((c, index) => {
          return (
            <Option key={index} search={`${c.invitedName} ${c.shortName}`} value={c.id}>
              {c.invitedName} ({c.shortName})
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}

export const NewReviewerSelect = ({ onChange, initialValues, users }) => {
  return (
    <Form.Item label={`Reviewers`} tooltip="This is a required field">
      <Select
        defaultValue={initialValues}
        onChange={onChange}
        showSearch
        mode={"multiple"}
        optionFilterProp="search"
        placeholder="Please select the reviewers"
      >
        {users.map((c, index) => {
          return (
            <Option key={index} search={`${c.invitedName} ${c.shortName}`} value={c.id}>
              {c.invitedName} ({c.shortName})
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}
