import { Tooltip } from "antd"
import { BillingStatus } from "app/core/components/billing/hooks/helpers/types"
import { useReviewStatus } from "app/core/components/billing/hooks/status"
import getAllReviews from "app/reviews/queries/getAllReviews"
import { useQuery } from "blitz"
import { Entry } from "db"
import { observer } from "mobx-react-lite"
import { BlueBadge, InfoBadge, WarningBadge } from "../badges/InfoBadge"
import { SuccessBadge } from "../badges/SuccessBadge"

const tooltip = (state: BillingStatus) => {
  switch (state) {
    case BillingStatus.UNBILLED:
      return "Docket is unbilled"
    case BillingStatus.PREBILLED:
      return "Docket is pre-billed (under review)"
    case BillingStatus.FINALIZED:
      return "Docket is billed"
    case BillingStatus.ESTIMATE:
      return "Estimate"
    case BillingStatus.CAN_ADD:
      return "Can add to existing pre-bill"
  }
}

const icon = (state: BillingStatus, invoiceNo?: number | null) => {
  const dimensions = { height: 22, width: 22 }

  switch (state) {
    case BillingStatus.UNBILLED:
      return null
    case BillingStatus.PREBILLED:
      return <InfoBadge title={"Pre-Billed"} />
    case BillingStatus.FINALIZED:
      return <SuccessBadge title={"Billed" + ` ${invoiceNo}`} />
    case BillingStatus.ESTIMATE:
      return <WarningBadge title={"Estimate"} />
    case BillingStatus.CAN_ADD:
      return <BlueBadge title={"Unattached Pre-Bill"} />
    case BillingStatus.COLLECTED:
      return <SuccessBadge title={"Collected" + ` ${invoiceNo}`} />
  }
}

export const BillingStatusCell = observer((props: { record: Entry }) => {
  const [reviews] = useQuery(getAllReviews, { where: { archived: false } })
  const { status, invoiceNo } = useReviewStatus(props.record, reviews)

  return (
    <div style={{ minWidth: 100, paddingTop: 5 }}>
      <Tooltip title={tooltip(status)}>{icon(status, invoiceNo)}</Tooltip>
    </div>
  )
})
