import { ActionIcon, Button as MantineButton, Modal, Tooltip } from "@mantine/core"
import { IconCheck, IconX } from "@tabler/icons"
import { useToggle } from "ahooks"
import { Button, Input } from "antd"
import createComment from "app/comments/mutations/createComment"
import resolveComment from "app/comments/mutations/resolveComment"
import updateComment from "app/comments/mutations/updateComment"
import getComments from "app/comments/queries/getComments"
import { threadStore } from "app/core/stores/store"
import { invoke, useMutation } from "blitz"
import { Comment, UserInOrganization } from "db"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { Suspense, useEffect, useState } from "react"

export const Thread = observer(() => {
  const entryId = threadStore.entryId

  useEffect(() => {
    invoke(getComments, {
      where: { entryId, deleted: false },
      orderBy: { createdAt: "asc" },
    }).then((res) => {
      threadStore.setComments(res.comments)
    })
  }, [entryId])

  if (!threadStore.visible) {
    return null
  }

  return (
    <Suspense fallback={null}>
      <ThreadCore entryId={threadStore.entryId} visible={threadStore.visible} />
    </Suspense>
  )
})

const ThreadCore = observer((props: { entryId; visible }) => {
  const { entryId } = props

  return (
    <div
      style={{
        width: 350,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          padding: 20,
          gap: 15,
          overflowY: "scroll",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 15,
            flexDirection: "column",
            width: 350,
            backgroundColor: "#fff",
            borderWidth: 1,
            borderRadius: 5,
          }}
        >
          {threadStore.comments
            .filter((f) => f.entryId === entryId) // Make sure stale comments are not shown.
            .map((comment: Comment) => {
              return (
                <CommentElement
                  record={comment}
                  content={comment.content}
                  date={comment.createdAt}
                  key={comment.id}
                  user={(comment as any).userInOrganization}
                />
              )
            })}
          <ReplyBox entryId={entryId} onAddComment={(comment) => threadStore.addComment(comment)} />
        </div>
      </div>
    </div>
  )
})

const ReplyBox = (props: { entryId: number; onAddComment: (comment: Comment) => void }) => {
  const { entryId, onAddComment } = props

  const [createCommentMutation] = useMutation(createComment, {})

  const [content, setContent] = useState("")

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 10,
        borderBottom: "20px solid #fff",
      }}
    >
      <Input
        style={{}}
        value={content}
        onChange={(event) => {
          setContent(event.target.value)
        }}
      />
      <Button
        onClick={async () => {
          if (content !== "") {
            try {
              let comment = await createCommentMutation({
                content: content,
                entryId,
                commentUrl: window.location.href,
              })
              onAddComment(comment)
              setContent("")
            } catch (error) {
              console.error(error)
            }
          }
        }}
      >
        Reply
      </Button>
    </div>
  )
}

const CommentElement = (props: {
  record: Comment
  user: UserInOrganization
  date: Date
  content: string
}) => {
  const { record, user, date, content } = props
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gap: 15,
        border: "1px solid #e3e8ee",
        // backgroundColor: theme.background[125],
        borderRadius: 5,
        padding: 15,
      }}
    >
      {/* <Avatar /> */}
      <Body record={record} content={content} date={date} name={user ? user.invitedName : "YOU"} />
    </div>
  )
}

const Body = (props: { record: Comment; name: string; date: Date; content: string }) => {
  const { record, name, date, content } = props
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 7 }}>
      <Details record={record} date={date} name={name} />
      <Content content={content} />
    </div>
  )
}

const Details = (props: { record: Comment; name: string; date: Date }) => {
  const { record, name, date } = props

  const [deleteModalOpened, { toggle: toggleDeleteModal }] = useToggle()
  const [resolved, { toggle: toggleResolved }] = useToggle(record.resolved)

  const handleResolve = () => {
    invoke(resolveComment, { id: record.id, resolved: !resolved })
    toggleResolved()
  }

  const handleDelete = () => {
    invoke(updateComment, { id: record.id, deleted: true })
    toggleDeleteModal()
  }

  return (
    <div style={{ lineHeight: 1, display: "flex", gap: 5, justifyItems: "space-between" }}>
      <div>
        <span style={{ fontWeight: 400, color: "#000", fontSize: 10, opacity: 0.85 }}>
          {name.toUpperCase()}
        </span>
        <span style={{ marginLeft: 5, color: "#C1C8D1", fontSize: 10 }}>
          {moment(date).format("MMM DD HH:mm A").toUpperCase()}
        </span>
      </div>
      <div style={{ display: "flex", gap: 5 }}>
        <Modal
          zIndex={2000}
          opened={deleteModalOpened}
          onClose={toggleDeleteModal}
          title="Delete comment"
        >
          <MantineButton onClick={handleDelete}>Confirm delete</MantineButton>
        </Modal>
        <Tooltip label="Resolution Status">
          <ActionIcon
            onClick={handleResolve}
            variant="light"
            color={resolved ? "green" : undefined}
          >
            <IconCheck size={16} />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete">
          <ActionIcon onClick={toggleDeleteModal} variant="light">
            <IconX size={16} />
          </ActionIcon>
        </Tooltip>
      </div>
    </div>
  )
}

const Content = (props: { content: string }) => {
  const { content } = props

  return <div style={{ fontSize: 13, fontWeight: 400, color: "#3E485B" }}>{content}</div>
}
