import { matterStore } from "app/core/stores/store"
import { TableStore } from "app/core/stores/TableStore"
import { Entry, Matter } from "db"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useCallback, useState } from "react"
import { useReviewLock } from "../../billing/hooks/locked"
import { MatterSearch } from "../../matters/MatterSearch"
import { updateWithVersionBump } from "../TDocketTable"
import { ClickToEdit } from "./ClickToEdit"

export const TMatterCell = observer((props: { record: Entry; store: TableStore<Entry> }) => {
  const { record, store } = props
  const entryLocked = useReviewLock(record.billingReviewId)
  const [editing, setEditing] = useState(false)

  let row: Entry = props.store.rows.find((r) => r.id === props.record.id)!

  const save = useCallback(
    (matter: Matter) => {
      runInAction(() => {
        row!.matterId = matter.id
      })

      localStorage.setItem("matterPreferenceSetting", matter.id.toString())
      localStorage.setItem("matterPreferenceSettingDefaultTask", matter.defaultTask ?? "bw")

      setEditing(false)

      updateWithVersionBump(row, 0, { id: row!.id!, matterId: matter.id }, store).then(() => {
        // Trigger when a matter is updated for an entry. TaskCell listens to this.
        // This must be after `updateWithVersionBump` because couchdb requires the `_rev` to be up-to-date.
        store.setMatterUpdateForEntry(row.id, matter.id)
      })
    },
    [props.store, row, store.updateFn]
  )

  const NotEditable = (props: {}) => {
    {
      const matter = row.matterId ? matterStore.findMatterFromId(row.matterId) : undefined
      return (
        <ClickToEdit
          setEditing={() => {
            setEditing(true)
          }}
          tooltip={matter?.description ?? ""}
          value={`${matter?.file ?? ""} ${matter?.description ?? ""}`}
        />
      )
    }
  }

  const Editable = (props: {}) => {
    return (
      <MatterSearch
        defaultOpen
        defaultValue={row.matterId}
        disabled={entryLocked}
        style={{ width: 120, minWidth: 120 }}
        onClose={() => {}} //setEditing(false)} // This is buggy after ugprading ant d.
        onSelect={save}
      />
    )
  }

  return <>{editing ? <Editable /> : <NotEditable />}</>
})
