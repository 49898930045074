import { CheckIcon } from "@heroicons/react/solid"
import React from "react"
import { Badge, BadgeDimensions, colours } from "./Badge"

export const SuccessBadge = (props: { title: string }) => {
  return (
    <Badge colour={colours.success} title={props.title}>
      <CheckIcon {...BadgeDimensions} />
    </Badge>
  )
}
