import { theme } from "app/core/styles/styles"
import moment, { Moment } from "moment"
import tinygradient from "tinygradient"
import { Badge, ColourType } from "../dockets/badges/Badge"

const colours: ColourType[] = [
  ["#FEF3C7", "#92400E"],
  ["#DBEAFE", "#1E40AF"],
  ["#E0E7FF", "#3730A3"],
  ["#EDE9FE", "#5B21B6"],
  ["#FCE7F3", "#9D174D"],
  [theme.green[200], theme.green[550]],
  [theme.gray[450], theme.gray[700]],
]

export const encompassMonth = (range: [Moment, Moment]): boolean => {
  const sameMonth = range[0].month() === range[1].month()
  const firstDay = range[0].date() === 1
  const lastDay = range[1].date() === range[1].daysInMonth()
  // console.log(sameMonth, firstDay, lastDay, range[0])
  if (sameMonth && firstDay && lastDay) {
    return true
  } else {
    return false
  }
}

export const colourForMonth = (month: number): ColourType => {
  return colours[month % colours.length]
}

export const DocketRangeCell = (props: { range: [Date, Date] }) => {
  const range: [Moment, Moment] = props.range.map((r) => moment(r)) as [Moment, Moment]
  if (encompassMonth(range)) {
    return (
      <Badge
        colour={colourForMonth(range[0].month())}
        title={range[0].format("MMMM").toUpperCase()}
      >
        <></>
      </Badge>
    )
  } else {
    const startColour = colourForMonth(range[0].month())
    const endColour = colourForMonth(range[1].month())

    const gradient = tinygradient([startColour[0], endColour[0]])

    return (
      <div style={{ display: "flex", gap: 5 }}>
        <Badge
          colour={startColour}
          style={{
            background: gradient.css(),
          }}
          title={range[0].format("MMM").toUpperCase()}
        >
          <>{`${range[0].date()} - ${range[1].format("MMM").toUpperCase()} ${range[1].date()}`}</>
        </Badge>
      </div>
    )
  }
}
