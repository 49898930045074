import { CheckIcon } from "@heroicons/react/solid"
import { Box } from "@mantine/core"
import { Avatar, Button, Popconfirm } from "antd"
import { prebillStore } from "app/core/stores/TableStore"
import { lawyerStore, matterStore, modalStore, whoAmIStore } from "app/core/stores/store"
import { theme } from "app/core/styles/styles"
import { disbursementsWhere } from "app/core/utils/where"
import unbillManyDisbursements from "app/disbursements/mutations/unbillManyDisbursements"
import getAllDisbursements from "app/disbursements/queries/getAllDisbursements"
import getAllEntries from "app/entries/queries/getAllEntries"
import { ExportTemplateSection } from "app/pages/organization/matters"
import { EditingPermissions } from "app/permissions/Simple"
import updateReview from "app/reviews/mutations/updateReview"
import getReviews from "app/reviews/queries/getReviews"
import { Link, invoke, useMutation, useRouter, useSession } from "blitz"
import { Disbursement, MembershipRole, Review, UserInOrganization } from "db"
import { isNil, merge } from "lodash"
import { runInAction, toJS } from "mobx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { memo, useEffect } from "react"
import { TButton } from "../../button/TButton"
import { Reviewer } from "../../disbursements/DisbursementForm"
import { TTable } from "../TTable"
import { LawyerFilterReview } from "../cells/filters/Filters"
import { THeaderPadded } from "../cells/headers/THeaders"
import { ActionType, RowActions } from "../cells/settings/TSettingsActionCell"
import { TTag } from "../settings/TClientSettingsTable"
import { DocketRangeCell } from "./DocketRangeCell"
import { PreBillForm } from "./PreBillForm"
import { ReviewButton } from "./ReviewButton"
import { finalize } from "./bill.helpers"

export const MatterLink = (props: { value: number }) => {
  const session = useSession()
  const matter = matterStore.findMatterFromId(props.value)
  const client = matterStore.findClientFromMatter(props.value)

  if (session.orgRole === MembershipRole.USER) {
    return (
      <div>
        {matter?.file} ({matter?.description})
      </div>
    )
  }

  return (
    <div>
      <Link href={`/organization/clients/${client?.id}`}>
        <div style={{ cursor: "pointer", color: theme.blue[600], fontWeight: 500 }}>
          {matter?.file} ({matter?.description})
        </div>
      </Link>
    </div>
  )
}

export const OverlapAvatars = observer(
  (props: { reviewId: number; lawyers: UserInOrganization[]; lawyerIds: number[] }) => {
    const session = useSession()
    const review = prebillStore.rows.find((r) => r.id === props.reviewId)

    const myself = props.lawyerIds.includes(whoAmIStore.me.id)
    const myselfComplete = review?.reviewerIdsComplete.includes(whoAmIStore.me.id)

    const background = (lawyerId: number) => {
      if (review?.reviewerIdsComplete?.includes(lawyerId)) {
        return { backgroundColor: theme.green[400] }
      } else {
        return { backgroundColor: theme.gray[500] }
      }
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {myself && (
          <div
            onClick={() => {
              let ids: any[] = []
              if (review?.reviewerIdsComplete?.includes(session.userInOrgId!)) {
                ids = review?.reviewerIdsComplete?.filter((f) => f !== session.userInOrgId!)
              } else {
                ids = [session.userInOrgId!, ...(review?.reviewerIdsComplete ?? [])]
              }
              // Update the local rows...
              runInAction(() => {
                review!.reviewerIdsComplete = ids
              })
              invoke(updateReview, {
                id: review!.id,
                reviewerIdsComplete: ids,
              })
            }}
          >
            <Avatar
              style={{
                cursor: "pointer",
                // fontSize: "9px",
                border: "2px solid white",
                lineHeight: "28px",
                height: "32px",
                width: "32px",
                fontSize: "10px",
                fontWeight: "600",
                color: "white",
                backgroundColor: myselfComplete ? theme.blue[600] : theme.gray[500],
              }}
            >
              <div
                style={{
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CheckIcon height="16px" />
              </div>
            </Avatar>
          </div>
        )}
        <Avatar.Group>
          {props.lawyerIds.map((i, index) => {
            return (
              <Avatar
                key={index}
                size={"large"}
                style={{
                  border: "2px solid white",
                  lineHeight: "28px",
                  height: "32px",
                  width: "32px",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "white",
                  ...background(i),
                }}
              >
                {/* Use the passed in `lawyers` because they are sorted by tier */}
                {props.lawyers.find((l) => l.id === i)?.shortName.toUpperCase()}
              </Avatar>
            )
          })}
        </Avatar.Group>
      </div>
    )
  }
)

type UserReviewer = Partial<UserInOrganization> & Reviewer

export const NewOverlapAvatars = observer(
  ({
    record,
    users,
    handleClick,
  }: {
    record: Disbursement
    users: UserInOrganization[]
    handleClick
  }) => {
    const userReviewers =
      (record.reviewers as unknown as Reviewer[] | undefined)?.map((reviewer) => {
        const userReviewer: UserReviewer = {
          ...reviewer,
          ...(users.find((u) => u.id === reviewer.id) ?? {}),
        }
        return userReviewer
      }) ?? []

    const myself = userReviewers.find((r) => r.id === whoAmIStore.me.id)
    const myselfComplete = myself?.approved ?? false

    const background = (lawyerId: number) => {
      if (userReviewers.find((r) => r.id === lawyerId)?.approved) {
        return { backgroundColor: theme.green[400] }
      } else {
        return { backgroundColor: theme.gray[500] }
      }
    }

    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {myself && (
          <div onClick={handleClick}>
            <Avatar
              style={{
                cursor: "pointer",
                border: "2px solid white",
                lineHeight: "28px",
                height: "32px",
                width: "32px",
                fontSize: "10px",
                fontWeight: "600",
                color: "white",
                backgroundColor: myselfComplete ? theme.blue[600] : theme.gray[500],
              }}
            >
              <div
                style={{
                  height: "28px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <CheckIcon height="16px" />
              </div>
            </Avatar>
          </div>
        )}
        <Avatar.Group>
          {userReviewers.map((user, index) => {
            return (
              <Avatar
                key={index}
                size={"large"}
                style={{
                  border: "2px solid white",
                  lineHeight: "28px",
                  height: "32px",
                  width: "32px",
                  fontSize: "10px",
                  fontWeight: "600",
                  color: "white",
                  ...background(user.id),
                }}
              >
                {user?.shortName?.toUpperCase()}
              </Avatar>
            )
          })}
        </Avatar.Group>
      </div>
    )
  }
)

export const findAllDisbursements = async (matterId: number, start: Date, end: Date) => {
  const disbursements = await invoke(getAllDisbursements, disbursementsWhere(matterId, start, end))
  return disbursements
}

export const PreBillWrapper = (props: {}) => {}

export const PreBillTable = memo(() => {
  const router = useRouter()
  const session = useSession()

  const [updateReviewMutation] = useMutation(updateReview)
  const [unbillManyDisbursementsMutation] = useMutation(unbillManyDisbursements)

  const lawyers = lawyerStore.lawyersArray

  useEffect(() => {
    invoke(getReviews, {
      where: { archived: false, finalized: false },
      take: 1000,
    }).then((r) => {
      prebillStore.setRows(r.reviews)
    })
  }, [])

  const hideReview = (id: number) => {
    const filtered = prebillStore.rows.filter((c) => c.id !== id) ?? []
    prebillStore.setRows(filtered)
  }

  const _archive = async (__record: Review) => {
    const record = toJS(__record)

    if (__record.archived) {
      await updateReviewMutation({ id: record.id, archived: false })
    } else {
      await unbillManyDisbursementsMutation({ reviewId: record.id })
      await updateReviewMutation({ id: record.id, archived: true })
      hideReview(record.id)
    }
  }

  const openForm = (record?: Review) => {
    const content = (
      <PreBillForm
        initialValues={record}
        setQueryData={(result) => {
          if (record) {
            // Edit
            const row = prebillStore.rows.find((r) => r.id === record.id)

            runInAction(() => {
              merge(row, result)
            })
          } else {
            // Create
            prebillStore.addRowAtBeginning(result)
          }
        }}
      />
    )
    modalStore.setContent("Invoice Form", <div style={{ padding: "24px" }}>{content}</div>)
  }

  const accountantColumns = [
    {
      title: "Complete",
      dataIndex: "complete",
      key: "complete",
      render: (_, record: Review) => (
        <Popconfirm
          title="Convert to a finalized bill?"
          onConfirm={async () => {
            const entries = await invoke(getAllEntries, {
              where: {
                flag: false,
                prebillingReviewId: null,
                matterId: record.matterId,
                date: {
                  lte: moment(record.endDate).toDate(),
                },
                deleted: false,
              },
            })

            if (entries.length > 0) {
              alert("Cannot finalize bill, there are unattached entries.")
              return
            }

            await finalize(record)
            hideReview(record.id)
          }}
        >
          <Button>
            {/** TODO: Prevent double clicing this.... disable the button */}
            Finalize Bill
          </Button>
        </Popconfirm>
      ),
    },
  ]

  const columns = [
    {
      title: <THeaderPadded>Invoice #</THeaderPadded>,
      dataIndex: "id",
      key: "id",
      render: (value, record) => (
        <THeaderPadded>
          {!isNil(record.pclawExport) ? <TTag>PCLaw</TTag> : null}
          {record.invoiceNo ?? record.id} {record.archived ? <TTag>Archive</TTag> : null}
        </THeaderPadded>
      ),
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Client",
      dataIndex: "clientId",
      key: "clientId",
      render: (value: number, record) => (
        <div>{matterStore.findClientFromMatter(record.matterId)?.name}</div>
      ),
      sorter: (a, b) =>
        matterStore.findClientFromMatter(a.matterId)?.id ??
        0 - (matterStore.findClientFromMatter(b.matterId)?.id ?? 0),
    },
    {
      title: "Matter",
      dataIndex: "matterId",
      key: "matterId",
      render: (value: number, record) => <MatterLink value={value} />,
      sorter: (a, b) => a.matterId - b.matterId,
    },
    {
      title: "Docket Range",
      dataIndex: "entryRange",
      key: "entryRange",
      render: (value: [Date, Date], record) => <DocketRangeCell range={value} />,
    },
    {
      title: "Review Due",
      dataIndex: "due",
      key: "due",
      render: (value: Array<number>, record) => <span>{moment(value).format("MMM D, YYYY")}</span>,
      sorter: (a, b) => moment(a.due).diff(moment(b.due), "hour"),
    },
    {
      title: "Reviewers",
      dataIndex: "reviewers",
      key: "reviewers",
      render: (value: Array<{ tier: number; value: number }>, record: Review, index) => (
        <OverlapAvatars
          lawyerIds={value ? value.sort((a, b) => a.tier - b.tier).map((v) => v.value) : []}
          lawyers={lawyers}
          reviewId={record.id}
        />
      ),
      ...LawyerFilterReview(lawyers),
      // defaultFilteredValue:
      //   // Accountants will see all by default...
      //   session.orgRole === MembershipRole.ACCOUNTANT ? [] : [session.userInOrgId?.toString()],
    },
    {
      title: "Review",
      dataIndex: "review",
      key: "review",
      render: (value, record: Review) => <ReviewButton review={record} />,
    },
    ...(EditingPermissions(session) ? accountantColumns : []),
    session.orgRole !== MembershipRole.USER
      ? RowActions(
          openForm,
          _archive,
          () => false,
          (record) => router.push("/organization/invoices/" + record.id.toString()),
          ActionType.REVIEW,
          (_, record: Review) => {
            return (
              <Button
                onClick={async () => {
                  modalStore.setContent(
                    "Export options",
                    <Box p={24}>
                      <ExportTemplateSection review={record}></ExportTemplateSection>
                    </Box>,
                    true
                  )
                }}
              >
                Export
              </Button>
            )
          }
        )
      : {},
  ]

  const action = <TButton style={{ marginBottom: 8 }} text={"Create Pre-Bill"} onClick={openForm} />

  return (
    <div>
      <TTable
        columns={columns as any}
        empty={{
          title: "No pre-bills",
          subtitle: "Create a pre-bill to start finalizing your dockets.",
          action,
        }}
        store={prebillStore}
      />
    </div>
  )
})
