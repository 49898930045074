import { FunctionComponent } from "react"
import { Badge, BadgeDimensions, colours } from "./Badge"

export const CustomBadge = (props: { title: string; Icon: FunctionComponent<any> }) => {
  const { Icon } = props
  return (
    <Badge colour={colours.info} title={props.title}>
      <Icon {...BadgeDimensions} />
    </Badge>
  )
}
