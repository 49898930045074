import { useDebounceFn } from "ahooks"
import { Input } from "antd"
import React, { useEffect, useRef, useState } from "react"
const { TextArea } = Input

export const TEditableCell = (
  props: {
    style?: any
    id?: string
    value: string
    multiline?: boolean
    onSave: (value: string) => void
    debounce?: boolean
    disabled: boolean
  } & {
    [key: string]: any
  }
) => {
  const { debounce, multiline, onSave } = props

  const [editing, setEditing] = useState(false)
  const inputRef = useRef<any>()

  useEffect(() => {
    if (editing) {
      inputRef.current!.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    if (!props.disabled) {
      setEditing((prev) => !prev)
    }
  }

  const save = async (stopEditing?: boolean) => {
    try {
      if (stopEditing) {
        toggleEdit()
      }
      const value = multiline
        ? inputRef.current?.resizableTextArea.textArea.value // TODO: Ensure this actually works.
        : inputRef.current?.input.value
      onSave(value)
    } catch (errInfo) {
      console.error("Save failed:", errInfo)
    }
  }

  const { run, cancel } = useDebounceFn(
    () => {
      save(false)
    },
    {
      wait: 300,
    }
  )

  const ElementType: any = props.multiline ? TextArea : Input

  return (
    <div>
      {!props.disabled && editing ? (
        <ElementType
          // Margin is important for centering in the table row.
          defaultValue={props.value}
          ref={inputRef}
          style={{ marginTop: 2, marginBottom: 2 }}
          onBlur={() => {
            save(true)
          }}
          onChange={() => {
            if (debounce) {
              run()
            }
          }}
          onPressEnter={() => {
            if (!props.multiline && editing) {
              cancel() // Stop debouncing.
              save(true)
            }
          }}
        />
      ) : (
        <div
          className="editable-cell-value-wrap"
          role="button"
          style={{ paddingRight: 32 }}
          tabIndex={0}
          onClick={toggleEdit}
          onKeyDown={() => {}}
        >
          {props.value}
        </div>
      )}
    </div>
  )
}
