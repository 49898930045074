import { Popover, Tooltip } from "antd"
import { Thread } from "app/core/components/comments/Thread"
import { threadStore } from "app/core/stores/store"
import { Entry } from "db"
import { observer } from "mobx-react-lite"
import { colours } from "../badges/Badge"
import { CommentBadge } from "../badges/CommentBadge"

const tooltip = (numberOfComments: number) => {
  if (numberOfComments > 0) {
    return "Add a comment"
  } else {
    return "View comments"
  }
}

const icon = (numberOfComments: number) => {
  if (numberOfComments > 0) {
    return <CommentBadge colour={colours.success} title={numberOfComments.toString()} />
  } else {
    return <CommentBadge colour={colours.info} title={numberOfComments.toString()} />
  }
}

export const CommentStatusCell = observer(
  (props: {
    record: Entry & {
      Comments?: Comment[] // May be optional in dockets upon post to postedStore.
    }
  }) => {
    const { record } = props

    // Use the `threadStore` if available for most up to date number of comments.
    const count = record.Comments?.length ?? 0

    return (
      <Popover
        content={<Thread />}
        title="Comments"
        trigger="click"
        onVisibleChange={(value) => {
          if (value) {
            threadStore.setContent(record.id, true)
          }
        }}
      >
        <div style={{ minWidth: 30, paddingRight: 5, paddingTop: 5, cursor: "pointer" }}>
          <Tooltip title={tooltip(count)}>{icon(count)}</Tooltip>
        </div>
      </Popover>
    )
  }
)
