// import * as jsRTF from 'jsrtf'
import { matterPageStore } from "app/pages/organization/matters/[matter]"
import { Disbursement, Entry, Matter } from "db"
import {
  AlignmentType,
  BorderStyle,
  Paragraph,
  Table,
  TableCell,
  TableRow,
  TextRun,
  WidthType,
} from "docx"
import { isNil, round } from "lodash"
import moment from "moment"
import { formatHours } from "../components/table/cells/utility/time"
import { lawyerStore, whoAmIStore } from "../stores/store"
import { getRateFromLawyer, rateFromEntry } from "./billing"

const tableMargin = {
  marginUnitType: WidthType.DXA,
  top: 150,
  bottom: 150,
  left: 50,
  right: 50,
}

const border = {
  style: BorderStyle.NONE,
  size: 1,
  color: "00FF00",
}

const tableBorders = {
  left: border,
  right: border,
  top: border,
  bottom: border,
}

const paraLineBreak = () => {
  return new Paragraph({
    children: [
      new TextRun({
        break: 3,
      }),
    ],
  })
}

const lineBreak = () => {
  return new TextRun({
    break: 3,
  })
}

const Cell = (
  text: string,
  header = false,
  percentage = 20,
  alignment: AlignmentType = AlignmentType.LEFT
) => {
  return new TableCell({
    width: {
      size: percentage,
      type: WidthType.PERCENTAGE,
    },
    borders: tableBorders,
    margins: tableMargin,
    children: [
      new Paragraph({
        alignment: alignment,
        children: [new TextRun({ text: header ? text.toUpperCase() : text, bold: header })],
      }),
    ],
  })
}

const table = (rows: TableRow[], headerRow?: TableRow) => {
  const headers = headerRow ? [headerRow] : []
  const table = new Table({
    columnWidths: [100, 100, 100, 100, 100],
    margins: tableMargin,
    borders: tableBorders,
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    rows: [...headers, ...rows],
  })
  return table
}

const docketTable = (entries: Entry[]) => {
  let firstCells: any[] = []

  const header = new TableRow({
    tableHeader: true,
    children: [
      ...firstCells,
      Cell("Date", true, 16),
      Cell("Description", true, 48),
      Cell("Hours", true, 10, AlignmentType.RIGHT),
      Cell("Amount", true, 13, AlignmentType.RIGHT),
      Cell("Lawyer", true, 13, AlignmentType.RIGHT),
    ],
  })

  const rows: TableRow[] = []

  // Sort dockets first by day, and then by lawyer...
  const sorted = entries.sort((a, b) => {
    const lawyerA = lawyerStore.findLawyerFromId(a.lawyerId)!
    const lawyerB = lawyerStore.findLawyerFromId(b.lawyerId)!
    return moment(a.date).isSame(moment(b.date), "day")
      ? (getRateFromLawyer(lawyerB, b.date) ?? 0) - (getRateFromLawyer(lawyerA, a.date) ?? 0) // This technically doesn't work, we need to average over all the entries in a prebill because rates can change.
      : moment(a.date).diff(moment(b.date), "hour")
  })
  sorted.forEach((er) => {
    let firstCells: any[] = []

    const hours = formatHours([er])
    const amount = round(hours * rateFromEntry(er), 2)

    const row = new TableRow({
      children: [
        ...firstCells,
        Cell(moment(er.date).format("MMM-D-YYYY"), false, 16),
        Cell(er.description, false, 48),
        Cell(hours.toFixed(1), false, 10, AlignmentType.RIGHT),
        Cell(amount.toFixed(2), false, 13, AlignmentType.RIGHT),
        Cell(
          lawyerStore.findLawyerFromId(er.lawyerId)!.shortName.toUpperCase() ?? "",
          false,
          13,
          AlignmentType.RIGHT
        ),
      ],
    })
    rows.push(row)
  })

  return table(rows, header)
}

export const totalsSummary = (
  matter: Matter,
  entries: Entry[],
  disbursements: Disbursement[],
  discountPercentage: number = 0.0,
  previousBalance: number = 0.0,
  writtenOff: number = 0.0
) => {
  const totalEntryAmount = entries.reduce(
    (prev, curr) => prev + round(formatHours([curr]) * rateFromEntry(curr), 2),
    0.0
  )
  const totalEntryHours = entries.reduce((prev, curr) => prev + round(formatHours([curr]), 1), 0.0)

  const totalDisbursementTax = disbursements.reduce((prev, curr) => prev + (curr.tax ?? 0.0), 0.0)

  const totalDisbursementAmount = disbursements.reduce(
    (prev, curr) => prev + curr.amount + (curr.tax ?? 0.0),
    0.0
  )

  const totalDisbursementAmountBeforeTaxWithoutTax = disbursements
    .filter((d) => isNil(d.tax) || d.tax <= 0)
    .reduce((prev, curr) => prev + curr.amount, 0.0)

  const totalDisbursementAmountBeforeTaxWithTax = disbursements
    .filter((d) => (d.tax ?? 0) > 0)
    .reduce((prev, curr) => prev + curr.amount, 0.0)

  const discountAmount =
    discountPercentage > 0.0 ? round((discountPercentage / 100.0) * totalEntryAmount, 2) : undefined

  const writtenOffAmount = round(writtenOff ?? 0, 2)

  const subtotal = totalEntryAmount - (discountAmount ?? 0) - (writtenOffAmount ?? 0)

  // Ensure the total is at least 0.0 if we are applying taxes.
  let taxes = 0.0
  if (subtotal > 0.0 && !matter.taxFree) {
    taxes = round(subtotal * round(whoAmIStore.me.organization.taxRate / 100.0, 2), 2)
  }

  const total = round(subtotal + taxes + totalDisbursementAmount, 2)

  return {
    discount: discountAmount,
    writtenOff,
    hours: totalEntryHours,
    fees: totalEntryAmount,
    net: subtotal,
    beforeTaxTotal:
      totalDisbursementAmountBeforeTaxWithoutTax +
      totalDisbursementAmountBeforeTaxWithTax +
      subtotal,
    totalDisbursementAmountBeforeTaxWithoutTax,
    totalDisbursementAmountBeforeTaxWithTax,
    disbursementsTax: totalDisbursementTax,
    disbursements: totalDisbursementAmount,
    disbursementsBeforeTax:
      totalDisbursementAmountBeforeTaxWithoutTax + totalDisbursementAmountBeforeTaxWithTax,
    taxes, // Fee taxes.
    totalTaxes: taxes + totalDisbursementTax, // Fee plus disbursement taxes.
    total,
    previousBalance,
  }
}

export const lawyerSummaries = (entries: Entry[]) => {
  // Lawyers found within the entries.
  const lawyers = new Set(entries.map((e) => e.lawyerId))

  const rows: {
    lawyer: number
    shortName: string
    name: string
    hours: number
    amount: number
    rate: number
    formattedRate: string
    formattedShortName: string
    title?: string
    // title: string
  }[] = []

  Array.from(lawyers)
    .map((l) => lawyerStore.findLawyerFromId(l)!)
    .sort((a, b) => {
      const rateComparison =
        (getRateFromLawyer(b, new Date()) ?? 0) - (getRateFromLawyer(a, new Date()) ?? 0)
      if (rateComparison === 0) {
        // This ensure Meghan is always ahead of Nathaniel.
        return a.shortName.localeCompare(b.shortName)
      } else {
        return rateComparison
      }
    })
    .forEach((lr) => {
      const lawyersEntries = entries.filter((e) => e.lawyerId === lr.id)
      const billableHours = lawyersEntries.reduce((prev, curr) => prev + formatHours([curr]), 0.0)
      const billableAmount = lawyersEntries.reduce(
        (prev, curr) => prev + round(formatHours([curr]) * (rateFromEntry(curr) ?? 0), 2),
        0.0
      )
      const billableRate = round(billableAmount / billableHours, 2)

      rows.push({
        lawyer: lr.id,
        // title: lr.title,
        shortName: lr.shortName,
        name: lr.invitedName,
        hours: billableHours,
        amount: billableAmount,
        rate: billableRate,
        formattedRate: matterPageStore.currencyLocaleFunction(billableRate),
        formattedShortName: lr.shortName.toUpperCase(),
        title: lr.title,
      })
    })

  return rows
}
