import Dexie, { Table } from "dexie"

export interface Reviewed {
  id?: number
  reviewId: string
  entryId: string
  description: string
}

class ReviewTrackingStoreDatabase extends Dexie {
  reviewed!: Table<Reviewed>

  public constructor() {
    super("ReviewTrackingStoreDatabase")
    this.version(3).stores({
      reviewed: "++id, [reviewId+entryId], description",
    })
    this.reviewed = this.table("reviewed")
  }
}

export const reviewTrackingStoreDatabase = new ReviewTrackingStoreDatabase()
