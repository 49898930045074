import { CalculatorIcon, UserCircleIcon } from "@heroicons/react/solid"
import { notification } from "antd"
import { settingsLawyerStore } from "app/core/stores/TableStore"
import { lawyerStore, modalStore } from "app/core/stores/store"
import { getRateFromLawyer } from "app/core/utils/billing"
import { EditingPermissions } from "app/permissions/Simple"
import updateUserInOrganization from "app/user-in-organizations/mutations/updateUserInOrganization"
import { getAntiCSRFToken, useMutation, useSession } from "blitz"
import { MembershipRole, UserInOrganization } from "db"
import _ from "lodash"
import { toJS } from "mobx"
import { useState } from "react"
import { TButton } from "../../button/TButton"
import { LoadingSpinner } from "../../spinner/LoadingSpinner"
import { TTable } from "../TTable"
import { THeaderPadded } from "../cells/headers/THeaders"
import { RowActions } from "../cells/settings/TSettingsActionCell"
import { CustomBadge } from "../dockets/badges/CustomBadge"
import { TTag } from "./TClientSettingsTable"
import { TLawyerForm } from "./forms/TLawyerForm"
import { TOmniSearch, omniOptions } from "./search/TOmniSearch"

export const sendInvitation = async (toUserId: number) => {
  const antiCSRFToken = getAntiCSRFToken()

  await fetch("/api/email/invite", {
    method: "POST",
    headers: { invitation: toUserId.toString(), "anti-csrf": antiCSRFToken },
  })
}

export const TLawyerSettingTable = (props: {}) => {
  const session = useSession()
  const [showArchive, setShowArchive] = useState<boolean>(false)

  const [update] = useMutation(updateUserInOrganization)

  const _archive = async (__record: UserInOrganization) => {
    const record = toJS(__record)
    const updateValues = { id: record.id, archived: !__record.archived }
    await update(updateValues)

    lawyerStore.upsertLawyerById(record.id, updateValues)
  }

  const openForm = (record) => {
    const permit = EditingPermissions(session)
    if (permit) {
      const content = (
        <TLawyerForm
          initialValues={toJS(record)}
          setQueryData={(row) => {
            lawyerStore.upsertLawyerById(row.id ?? "undefined", row)
          }}
        />
      )
      modalStore.setContent("Invitation Form", <div style={{ padding: "24px" }}>{content}</div>)
    } else {
      notification.open({
        message: "Must have account role OWNER, ADMIN, or ACCOUNTANT new users.",
      })
    }
  }

  const InvitationButton = (props: { id: number }) => {
    const [loading, setLoading] = useState(false)
    return (
      <TButton
        disabled={loading}
        style={{}}
        onClick={async () => {
          setLoading(true)
          sendInvitation(props.id)
          setTimeout(() => {
            setLoading(false)
          }, 1500)
        }}
      >
        {loading ? <LoadingSpinner center /> : "Resend Invitiation"}
      </TButton>
    )
  }

  const columns = [
    {
      title: <THeaderPadded>Full Name</THeaderPadded>,
      dataIndex: "invitedName",
      key: "invitedName",
      render: (value, record) => (
        <THeaderPadded>
          {value} {record.archived ? <TTag>Archive</TTag> : null}
        </THeaderPadded>
      ),
    },
    {
      title: "Short Name",
      dataIndex: "shortName",
      key: "shortName",
      render: (value, record: UserInOrganization) => {
        return <div>{value.toUpperCase()}</div>
      },
    },
    {
      title: "Display Email",
      dataIndex: "invitedEmail",
      key: "invitedEmail",
      render: (value, record: UserInOrganization) => {
        return <div>{value}</div>
      },
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (value, record: UserInOrganization) => {
        let Icon = UserCircleIcon
        switch (value) {
          case MembershipRole.ACCOUNTANT:
            Icon = CalculatorIcon
            break
          case MembershipRole.USER:
            Icon = UserCircleIcon
            break
        }
        return (
          <div>
            <CustomBadge Icon={Icon} title={value} />
          </div>
        )
      },
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      render: (value, record: UserInOrganization) => {
        return <div>{getRateFromLawyer(record, new Date())}</div>
      },
    },
    RowActions(
      openForm,
      _archive,
      () => false,
      undefined,
      undefined,
      (value, record: UserInOrganization) => {
        if (_.isNil(record.userId) && !record.archived) {
          // console.log(toJS(record))
          return <InvitationButton id={record.id} />
        } else {
          return null
        }
      }
    ),
  ]

  return (
    <div>
      <div style={{ display: "flex", gap: 10 }}>
        <TButton
          style={{ marginBottom: 16 }}
          text={"Invite User"}
          onClick={() => openForm(undefined)}
        />
        <TButton
          onClick={() => {
            setShowArchive((prev) => !prev)
          }}
        >
          {showArchive ? "Show Active" : "Show Archive"}
        </TButton>
      </div>
      <TOmniSearch
        options={omniOptions(columns, lawyerStore.lawyersArray)}
        store={settingsLawyerStore}
      />
      <TTable columns={columns} dataSource={lawyerStore.lawyersArray} store={settingsLawyerStore} />
    </div>
  )
}
