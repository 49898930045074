import { Button, Popconfirm } from "antd"
import { ReactNode, useEffect, useState } from "react"

export enum ActionType {
  ARCHIVABLE,
  REVIEW,
}

export const RowActions = (
  openEdit: (record: any) => any,
  archive: (record: any) => any,
  locked: (record: any) => boolean = () => false,
  review?: (record: any) => void,
  type?: ActionType,
  render?: (value: any, record: any) => ReactNode
) => {
  return {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (value, record) => (
      <TSettingsActionCell
        archive={archive}
        locked={locked}
        openEdit={openEdit}
        record={record}
        render={render}
        review={review}
        type={type}
      />
    ),
  }
}

const TSettingsActionCell = (props: {
  record: { id: number; archived: boolean }
  openEdit: (record) => void
  archive: (record: any) => void
  locked: (record: any) => boolean
  review?: (record: any) => void
  type?: ActionType
  render?: (value: any, record: any) => ReactNode
}) => {
  const { locked, record } = props
  const [archiveState, setArchiveState] = useState<boolean>(props.record.archived)
  const isLocked = locked && locked(record)

  useEffect(() => {
    setArchiveState(record.archived)
  }, [record.archived])

  return (
    <div style={{ display: "flex", gap: 20 }}>
      {props.render ? props.render(null, props.record) : null}

      <Button
        className={"small-table-button"}
        disabled={isLocked}
        style={{ height: 24 }}
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          props.openEdit(props.record)
        }}
      >
        Edit
      </Button>

      <Popconfirm
        title="Archive this entry? It will be hidden, but recoverable."
        onConfirm={() => {
          const initialArchiveState = props.record.archived
          props.archive(props.record)
          props.record.archived = !initialArchiveState
          setArchiveState(!initialArchiveState)
        }}
      >
        <Button className={"small-table-button"} disabled={isLocked}>
          {archiveState ? "Unarchive" : "Archive"}
        </Button>
      </Popconfirm>
    </div>
  )
}
