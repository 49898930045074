import { Entry, Matter } from "@prisma/client"
import { LoadingSpinner } from "app/core/components/spinner/LoadingSpinner"
import { HoverInfo } from "app/core/components/vectors/TInfoGraphic"
import {
  AugmentedLawyer,
  AugmentedStatistic,
  StatisticPeriod,
} from "app/core/stores/StatisticStore"
import { entryStore, postedStore } from "app/core/stores/TableStore"
import { lawyerStore, matterStore, statisticStore } from "app/core/stores/store"
import { TShadowBorder } from "app/core/styles/standard"
import { theme } from "app/core/styles/styles"
import getAllEntries from "app/entries/queries/getAllEntries"
import { useQuery, useSession } from "blitz"
import { reaction } from "mobx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { ReactNode, useCallback, useEffect, useState } from "react"
import { truncateText } from "../../pages/organization/workspace"
import { hours, timesArrayToSeconds } from "./table/cells/utility/time"

const TDocketFullStat = observer((props: {}) => {
  if (!matterStore.finishLoad) {
    return <LoadingSpinner center />
  }

  const pieData = Array.from(statisticStore.amounts.values())
    .map((m) => {
      // TODO: Flattening may be ineffecient. Run performance tests...

      const matter = Array.from(matterStore.clientsWithMatters.values())
        .flat(1)
        .find((v: Matter) => v.id === m.id)

      const label = `${matter?.file} (${matter?.description})`
      return {
        id: m.id.toString(),
        label: truncateText(label, 29),
        value: m.billableHours,
      }
    })
    .sort((a, b) => b.value - a.value)

  return (
    <div style={{ display: "flex", flexDirection: "column", ...TShadowBorder }}>
      <div
        style={{
          paddingLeft: 32,
          paddingTop: 32,
          paddingBottom: 6,
        }}
      >
        Billing Breakdown
      </div>
      <div
        style={{
          display: "flex",
          placeContent: "center",
          placeItems: "center",
          width: 350,
          height: 160,
        }}
      />
      <div
        style={{
          backgroundColor: theme.gray[350],
          width: "100%",
          borderBottomLeftRadius: 18,
          borderBottomRightRadius: 18,
        }}
      >
        {/* <div style={{ padding: 12, paddingLeft: 24 }}>
          <TButton onClick={() => {}} style={{}}>
            Full Report
          </TButton>
        </div> */}
      </div>
    </div>
  )
})

const Stat = (props: { top: string; bottom: string; tooltip: string }) => {
  return (
    <div>
      <div style={{ fontWeight: 400, color: theme.gray[800], display: "flex" }}>
        <div>{props.top}</div>
        <HoverInfo text={props.tooltip} />
      </div>
      <h2 style={{ fontWeight: 400, color: theme.gray[800] }}>{props.bottom}</h2>
    </div>
  )
}

type StatisticType = "hours" | "amount" | "rate" | "totalHours"

const TDocketStatistic = (props: { type: StatisticType; report: AugmentedStatistic }) => {
  const session = useSession()

  const [tabs, setTabs] = useState<string[]>()

  const values = useCallback(async () => {
    switch (props.type) {
      case "amount":
        return [
          "Billable Amount",
          props.report.statistics.amount.toFixed(2),
          "This is the billable amount multiplied by the rate. Client-specific rates take precedence over user rates.",
        ]
      case "hours":
        return [
          "Billable Hours",
          props.report.statistics.billableHours.toFixed(1),
          "This is the sum of all entries that have a 'billable' task, assigned matter, and are found within the selected time period.",
        ]
      case "totalHours":
        return [
          "Total Hours",
          props.report.statistics.totalHours.toFixed(1),
          "This is the sum of all entries that have any task, an assigned matter, and are found within the selected time period.",
        ]
      case "rate":
        let rate = props.report.statistics.amount / props.report.statistics.billableHours
        return [
          "Effective Rate",
          (rate > 0 ? rate : 0).toFixed(0),
          "This is the weighted average rate based on the matter and user. In order of preference is Matter-specific, Client-specific, and then User-specific rates.",
        ]
    }
  }, [
    props.report.statistics.amount,
    props.report.statistics.billableHours,
    props.report.statistics.totalHours,
    props.type,
    session.userInOrgId,
  ])

  useEffect(() => {
    const init = async () => {
      setTabs(await values())
    }
    init()
  }, [values])

  if (tabs) {
    return (
      <div
        style={{
          fontWeight: 400,
          color: theme.gray[800],
          marginTop: 0,
          padding: 32,
          maxWidth: 200,
          maxHeight: 120,
          display: "flex",
          alignContent: "center",
          ...TShadowBorder,
        }}
      >
        <Stat bottom={tabs[1]} tooltip={tabs[2]} top={tabs[0]} />
      </div>
    )
  } else {
    return null
  }
}

const getInterval = (period: StatisticPeriod) => {
  if (period === "daily") {
    return [moment().startOf("day"), moment().endOf("day")]
  } else if (period === "weekly") {
    return [moment().startOf("week"), moment().endOf("week")]
  } else if (period === "monthly") {
    return [moment().startOf("month"), moment().endOf("month")]
  }
}

// Filter based on period and lawyer

const filterDockets = (
  dockets: Entry[],
  period: StatisticPeriod,
  lawyerId: number,
  tasks: String[]
) => {
  const interval = getInterval(period)!
  return dockets.filter(
    (d) =>
      d &&
      d.date &&
      moment(d.date).isSameOrBefore(interval[1]) &&
      moment(d.date).isSameOrAfter(interval[0]) &&
      tasks.includes(d.task) &&
      d.lawyerId === lawyerId
  )
}

export const useStatistics = () => {
  const session = useSession()

  const lawyers = lawyerStore.lawyersArray

  const [report, setReport] = useState<AugmentedLawyer>()

  const createReport = () => {
    const entryStoreFiltered = filterDockets(
      entryStore.rows,
      statisticStore.period,
      session.userInOrgId!,
      ["bw", "nbw"]
    )

    const postedStoreFiltered = filterDockets(
      postedStore.rows,
      statisticStore.period,
      session.userInOrgId!,
      ["bw", "nbw"]
    )

    if (matterStore.finishLoad) {
      statisticStore.initializePreferences()
      statisticStore.generateReports(
        [...entryStoreFiltered, ...postedStoreFiltered],
        lawyers,
        getInterval(statisticStore.period)?.map((i) => i.toDate())!
      )
      const lawyerReports = statisticStore.reportsByLawyer()
      const lawyerReport = lawyerReports.find((l) => l.id === session.userInOrgId)
      setReport(lawyerReport)
    }
  }

  reaction(
    () => JSON.stringify(statisticStore.period),
    () => {
      createReport()
    }
  )

  reaction(
    () => JSON.stringify(postedStore.rows),
    () => {
      createReport()
    }
  )

  reaction(
    () => JSON.stringify(entryStore.rows),
    () => {
      createReport()
    }
  )

  return [report]
}

function Statistics() {
  const title = "Weekly statistics"

  const session = useSession()

  let children: ReactNode | ReactNode[] | null = null

  // Sum up all the hours in the entryStore.
  const draftHours = entryStore.rows.reduce((acc, curr) => {
    return acc + hours(timesArrayToSeconds(JSON.parse(curr.times)))
  }, 0)

  const [entries, { refetch }] = useQuery(
    getAllEntries,
    {
      where: {
        date: {
          lte: moment().endOf("week").toISOString(),
          gte: moment().startOf("week").toISOString(),
        },
        posted: { not: null },
        lawyerId: session.userInOrgId,
        task: "bw",
        deleted: false,
      },
    },
    { enabled: true }
  )

  // Sum up all the hours in the entryStore.
  const postedHours =
    entries?.reduce((acc, curr) => {
      if (curr.task !== "nbw") {
        return acc + hours(timesArrayToSeconds(JSON.parse(curr.times)))
      }

      return acc
    }, 0) ?? 0

  useEffect(() => {
    if (session.userInOrgId) {
      refetch()
    }
  }, [refetch, session.userInOrgId])

  return (
    <div style={{ margin: 50 }}>
      <div
        style={{
          margin: 50,
          fontWeight: 400,
          color: theme.gray[800],
          padding: 32,
          maxWidth: 200,
          minHeight: 160,
          maxHeight: 160,
          display: "flex",
          alignContent: "center",
          ...TShadowBorder,
        }}
      >
        <Stat
          bottom={draftHours.toFixed(1)}
          tooltip={"All draft hours, not limited by a time period or task"}
          top={"All draft billable and non-billable hours"}
        />
      </div>
      <div
        style={{
          margin: 50,
          fontWeight: 400,
          color: theme.gray[800],
          padding: 32,
          maxWidth: 200,
          minHeight: 160,
          maxHeight: 160,
          display: "flex",
          alignContent: "center",
          ...TShadowBorder,
        }}
      >
        <Stat
          bottom={postedHours.toFixed(1)}
          tooltip={"This week's POSTED and BILLABLE hours, bounded by Sunday to Saturday"}
          top={"Only the current week's billable, posted hours"}
        />
      </div>
      <div style={{ height: "50px" }}></div>
    </div>
  )
}

export default Statistics
