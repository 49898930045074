import { Tabs } from "antd"
import { isEmpty } from "lodash"
import { ReactNode } from "react"

const { TabPane } = Tabs

export interface Tab {
  count?: ReactNode
  content: ReactNode | ReactNode[]
  name: ReactNode | string
  forceRender?: boolean
}

export const TTabBar = (props: { tabs: Tab[]; passthrough?: any; defaultActiveKey?: string }) => {
  let defaultActiveKey = Object.keys(props.tabs)[0]

  if (!isEmpty(window.location.hash)) {
    defaultActiveKey = decodeURIComponent(window.location.hash.replace("#", ""))
  }

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      onChange={(activeKey) => {
        window.location.hash = activeKey
      }}
    >
      {props.tabs.map((t) => {
        return (
          <TabPane
            forceRender={t.forceRender ?? false}
            key={t.name?.toString()}
            tab={
              <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {t.name}
                {t.count && <>{t.count}</>}
              </div>
            }
          >
            {t.content}
          </TabPane>
        )
      })}
    </Tabs>
  )
}
