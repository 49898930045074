import { Select } from "antd"
import { TableStore } from "app/core/stores/TableStore"
import { matterStore } from "app/core/stores/store"
import { Entry } from "db"
import { reaction, runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useRef, useState } from "react"
import { useReviewLock } from "../../billing/hooks/locked"
import { updateWithVersionBump } from "../TDocketTable"
import { TSelectableCell } from "../base/TSelectableCell"
import { ClickToEdit } from "./ClickToEdit"
const { Option } = Select

export const TTaskCell = observer((props: { record: Entry; store: TableStore<Entry> }) => {
  const { record, store } = props
  const entryLocked = useReviewLock(record.billingReviewId)
  const [editing, setEditing] = useState(false)

  let row: Entry = props.store.rows.find((r) => r.id === props.record.id)!

  let task = props.record.task

  const ref = useRef<any>(null)
  useEffect(() => {
    if (editing) {
      ref.current!.focus()
    }
  }, [editing])

  let defaultTask: string | null | undefined = null

  // Make sure the matterstore has finished loading...
  if (matterStore.finishLoad) {
    const matter = matterStore.findMatterFromId(row.matterId!)
    // Initialize the row with the preference...
    defaultTask = matter?.defaultTask
    task = !props.record.task || props.record.task === "" ? defaultTask ?? "bw" : props.record.task
  }

  // TODO: For some reason this reaction is called A LOT...
  // Listen to matter changes.
  reaction(
    () => props.store.matterUpdateForEntry,
    () => {
      // Make sure we are reacting to the correct entry...
      if (matterStore.finishLoad && props.store.matterUpdateForEntry[0] === row.id) {
        const matter = matterStore.findMatterFromId(props.store.matterUpdateForEntry[1])
        const task = matter?.defaultTask ?? "bw"
        // Make sure the tasks are different before updating...
        if (task !== row.task) {
          save(task)
        }
      }
    }
  )

  const save = (value: string) => {
    runInAction(() => {
      row.task = value
    })
    updateWithVersionBump(row, 0, { id: row.id, task: value }, store)
  }

  // Make sure the matterstore has finished loading...
  if (!matterStore.finishLoad) {
    return null
  }

  const options = [
    {
      name: "Billable Work",
      value: "bw",
    },
    {
      name: "Non-Billable Work",
      value: "nbw",
    },
  ]

  const optionsValues = (
    <>
      {options.map((o, i) => {
        return (
          <Option key={i} value={o.value}>
            {o.name}
          </Option>
        )
      })}
    </>
  )

  const NotEditable = ({ tooltipFunction }) => {
    const name = options.find((o) => o.value.toUpperCase() === row.task.toUpperCase())?.name
    const tooltip = tooltipFunction?.(row)
    return (
      <ClickToEdit
        warning={!!tooltip}
        setEditing={() => setEditing(true)}
        tooltip={tooltip ?? name}
        value={row.task.toUpperCase()}
      />
    )
  }

  const Editable = (props: {}) => {
    return (
      <TSelectableCell
        disabled={entryLocked}
        options={optionsValues}
        ref={ref}
        value={task}
        onSave={(value) => save(value as string)}
      />
    )
  }

  if (editing) {
    return <Editable />
  }

  const tooltipFunction = (row: Entry) => {
    return row.task !== defaultTask ? "Task does not match matter" : undefined
  }

  return <NotEditable tooltipFunction={tooltipFunction} />
})
