export const useReviewLock = (reviewId: number | undefined | null): boolean  => {
  return reviewId != null

  // TODO: eventually check the lock field on the review...
  // For now, if it has a reviewId, it is locked (aka.. finalized)

  // const [review, setReview] = useState<Review | undefined>(undefined)

  // const load = useCallback(async () => {
  //   const review = await cacheReview(reviewId)
  //   setReview(review)
  // }, [reviewId])

  // useEffect(() => {
  //   load()
  // }, [load])

  // return review ? review.locked : false
}
