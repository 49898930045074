import { MembershipRole } from "db"

export const HighPermissions = (session): boolean => {
  return session.orgRole === MembershipRole.OWNER || session.orgRole === MembershipRole.ADMIN
}

export const EditingPermissions = (session): boolean => {
  return (
    session.orgRole === MembershipRole.OWNER ||
    session.orgRole === MembershipRole.ADMIN ||
    session.orgRole === MembershipRole.ACCOUNTANT
  )
}
