import { entryStore, TableStore } from "app/core/stores/TableStore"
import { theme } from "app/core/styles/styles"
import { Entry, UserInOrganization } from "db"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { updateWithVersionBump } from "../TDocketTable"
import { maxSeconds } from "./TBillableTimeCell"
import { getStoppedSeconds, getTimeInSeconds, isRunning } from "./utility/time"

export const stopTimer = (r: Entry) => {
  const times = JSON.parse(r.times)
  if (isRunning(times)) {
    // Set what the final time looks like.
    times[times.length - 1]!.stop = getTimeInSeconds()

    let maximumSeconds: number | undefined = maxSeconds()

    if (getStoppedSeconds(times) >= maximumSeconds) {
      r.times = JSON.stringify([{ start: 0, stop: maximumSeconds }])
    } else {
      r.times = JSON.stringify(times)
    }
  }
  return r
}

export const TStopWatch = observer(
  (props: { record: Entry; lawyer: UserInOrganization; store: TableStore<Entry> }) => {
    const { store } = props

    let row = entryStore.rows.find((r) => r.id === props.record.id)!

    let times = JSON.parse(row!.times) as Array<{ start?: number; stop?: number }>

    const [running, setRunning] = useState(isRunning(times))

    useEffect(() => {
      setRunning(isRunning(times))
    }, [row.times])

    // TODO: Get rid of all this json parsing...
    const toggleRunning = () => {
      let newTimes = JSON.parse(row.times)
      runInAction(() => {
        if (running) {
          newTimes = JSON.parse(stopTimer(row).times)
        } else {
          // If automatic stop setting is set - stop all other timers
          if (props.lawyer.settingAutomaticStop) {
            entryStore.rows
              .filter((f) => f.id !== row.id)
              .forEach((r: Entry) => {
                if (isRunning(JSON.parse(r.times))) {
                  const stopped = stopTimer(r)
                  updateWithVersionBump(r, r.version, { times: stopped.times! }, store)
                }
              })
          }
          newTimes.push({ start: getTimeInSeconds(), stop: undefined })
        }
        // Update the observable row...
        row.times = JSON.stringify(newTimes)
      })
      updateWithVersionBump(row, row.version, { times: JSON.stringify(newTimes) }, store)
      setRunning((prev) => !prev)
    }

    return (
      <div
        role={"button"}
        style={{
          paddingTop: 7.5,
          display: "flex",
          width: "22px",
          marginLeft: 8,
          color: running ? "#FF5656" : "#73CF8D",
          cursor: "pointer",
        }}
        tabIndex={0}
        onClick={toggleRunning}
        onKeyDown={() => {}}
      >
        {running ? <RunningAnimation /> : <Ready />}
      </div>
    )
  }
)

export const Ready = () => {
  return (
    <svg
      fill="none"
      height="92%"
      style={{ height: 20, width: 20 }}
      viewBox="0 0 16 16"
      width="92%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15A7 7 0 108 1a7 7 0 000 14v0z"
        opacity=".5"
        stroke={theme.gray[500]}
        strokeWidth="2"
      />
      <path d="M8 12a4 4 0 100-8 4 4 0 000 8z" fill={theme.gray[500]} />
    </svg>
  )
}

export const RunningAnimation = () => {
  return (
    <svg
      className="anim-rotate"
      fill="none"
      height="92%"
      style={{ height: 20, width: 20 }}
      viewBox="0 0 16 16"
      width="92%"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 15A7 7 0 108 1a7 7 0 000 14v0z"
        opacity=".5"
        stroke={theme.green[550]}
        strokeWidth="2"
      />
      <path d="M15 8a7 7 0 01-7 7" stroke={theme.green[550]} strokeWidth="2" />
      <path d="M8 12a4 4 0 100-8 4 4 0 000 8z" fill={theme.green[550]} />
    </svg>
  )
}
