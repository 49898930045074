import { Modal, Spin } from "antd"
import { modalStore } from "app/core/stores/store"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import React, { Suspense } from "react"

export const PageModal = observer(() => {
  const handleOk = () => {
    modalStore.setVisibility(false)
  }

  const handleCancel = () => {
    modalStore.setVisibility(false)
  }

  return (
    <>
      <Modal
        footer={null}
        title={<span style={{ fontWeight: 500 }}>{toJS(modalStore.title)}</span>}
        visible={modalStore.visible}
        onCancel={handleCancel}
        onOk={handleOk}
      >
        <Suspense fallback={<Spin />}>{toJS(modalStore.content)}</Suspense>
      </Modal>
    </>
  )
})
