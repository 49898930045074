import { matterStore } from "app/core/stores/store"
import { TableStore } from "app/core/stores/TableStore"
import { Entry } from "db"
import _ from "lodash"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { useReviewLock } from "../../billing/hooks/locked"
import { updateWithVersionBump } from "../TDocketTable"
import { TEditableCell } from "./TEditableCell"
import { formatHours, hoursToSecondsObject, isRunning } from "./utility/time"

export const maxSeconds = (row?) => {
  let maximumSeconds = 24 * 60 * 60 - 1
  if (row && row.matterId) {
    const matterLimit = matterStore.findMatterFromId(row.matterId)?.docketTimeSoftLimit
    if (matterLimit) {
      maximumSeconds = matterLimit * 60 * 60
    }
  }
  return maximumSeconds
}

export const maxHours = (row?) => {
  let maximumHours = 24
  if (row && row.matterId) {
    const matterLimit = matterStore.findMatterFromId(row.matterId)?.docketTimeSoftLimit
    if (matterLimit) {
      maximumHours = matterLimit
    }
  }
  return maximumHours
}

export const TBillableTimeCell = observer((props: { record: Entry; store: TableStore<Entry> }) => {
  const { record, store } = props
  const entryLocked = useReviewLock(record.billingReviewId)

  const row: Entry = props.store.rows.find((r) => r.id === props.record.id)!
  const times = JSON.stringify(row.times)
  const hours = formatHours([row])

  const [time, setTime] = useState(hours)

  const maximumHours = maxHours()

  useEffect(() => {
    setTime(formatHours([row]))
  }, [row, times])

  useEffect(() => {
    const interval = setInterval(() => {
      const running = isRunning(JSON.parse(row.times))
      if (running) {
        let hours = formatHours([row])
        if (hours > maximumHours) {
          hours = maximumHours
        }

        // Trigger a statistic update if the hours change

        if (hours !== time) {
          runInAction(() => {
            // This change has no real effect on the database. It is just used to update the statistic. Which is recomputed on any entryStore or postedStore change.

            row.updatedAt = new Date()
          })
        }
        setTime(hours)
      } else {
        clearInterval(interval)
      }
    }, 100)
    return () => clearInterval(interval)
  }, [row, time, times])

  const updateTime = async (value?: string) => {
    if (!_.isNil(value)) {
      let parsed = parseFloat(value)
      if (parsed > maximumHours) {
        parsed = maximumHours
      }
      const secondsObject = hoursToSecondsObject(parsed)
      const newVersion = row.version + 1
      runInAction(() => {
        row!.times = JSON.stringify(secondsObject)
        row.version = newVersion
      })
      updateWithVersionBump(row, newVersion, { id: row!.id!, times: row!.times! }, store)
    }
  }

  return (
    <TEditableCell
      disabled={entryLocked}
      value={time.toString()}
      onSave={(value: string) => {
        updateTime(value)
      }}
    />
  )
})
