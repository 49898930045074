import { Skeleton, Tooltip } from "antd"
import { lawyerStore } from "app/core/stores/store"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"

export const StaticLawyerCell = observer((props: { id: number }) => {
  const { id } = props

  useEffect(() => {
    lawyerStore.load()
  }, [])

  const lawyer = lawyerStore.findLawyerFromId(id)

  if (!lawyerStore.finishLoad) {
    return <Skeleton />
  }

  return (
    <div>
      <Tooltip title={lawyer?.invitedName}>{lawyer?.invitedName}</Tooltip>
    </div>
  )
})
