import { FilterValue } from "antd/lib/table/interface"
import createEntry from "app/entries/mutations/createEntry"
import updateEntry from "app/entries/mutations/updateEntry"
import { invoke } from "blitz"
import {
  AutoComplete,
  Client,
  Disbursement,
  Email,
  Entry,
  Matter,
  Review,
  Share,
  UserInOrganization,
} from "db"
import { cloneDeep, merge } from "lodash"
import { action, makeObservable, observable } from "mobx"
import moment from "moment"
import { syncStore } from "../sync/store"
import { generateRandomInt } from "../utils/hex"

export class TableStore<T extends { id: number; deleted?: boolean }> {
  rows: T[] = []
  filteredRows: T[] = []

  filteredInfo: Record<string, FilterValue | null> = {}

  checkedRows: T[] = []

  matterUpdateForEntry: [number, number]

  filterFn: (a, b) => boolean = (a, b) => true

  sortFn: (a, b) => number = (a, b) => 0

  constructor(updateFn?, deleteFn?, sortFn?) {
    this.deleteFn = deleteFn
    this.updateFn = updateFn
    this.sortFn = sortFn
    makeObservable(this, {
      filteredInfo: observable,
      setFilteredInfo: action,
      filteredRows: observable,
      checkedRows: observable,
      setFilteredRows: action,
      setCheckedRows: action,
      filterFn: observable,
      setFilterFn: action,
      appendRow: action,
      removeRow: action,
      rows: observable,
      setRows: action,
      addRowAtBeginning: action,
      upsertRowById: action,
      matterUpdateForEntry: observable,
    })
  }

  upsertRowById(id: number, obj: T) {
    const exists = this.rows.find((c) => c.id === id)
    if (exists) {
      merge(exists, obj)
    } else {
      this.rows.push(cloneDeep(obj))
    }
  }

  setFilteredInfo(filteredInfo: Record<string, FilterValue | null>) {
    this.filteredInfo = filteredInfo
  }

  updateFn:
    | ((row: T, values?: Partial<T>) => Entry & { _id?: string; _rev?: string }) // Returns the blitz response or the couchdb response...
    | null = null
  deleteFn: ((row: Entry & { _id: string }) => void) | null = null

  // Trigger when a matter is updated for an entry. TaskCell listens to this.
  setMatterUpdateForEntry = (entryId, matterId): void => {
    this.matterUpdateForEntry = [entryId, matterId]
  }

  setFilterFn = (filterFn): void => {
    this.filterFn = filterFn
  }

  setCheckedRows = (rows: T[]): void => {
    this.checkedRows = [...rows]
  }

  setFilteredRows = (filteredRows: T[]): void => {
    this.filteredRows = [...filteredRows]
  }

  addRowAtBeginning = (row: T) => {
    this.rows = [row, ...this.rows]
  }

  removeRow = (rowId: number) => {
    this.rows = this.rows.filter((row) => row.id !== rowId)
  }

  setRows = (rows: T[]): void => {
    this.rows = cloneDeep(rows)
      .filter((a) => !a.deleted) // Filter out deleted rows.
      .sort(this.sortFn)
  }

  appendRow = (row: any) => {
    this.rows.push(row)
  }
}

// CouchDB

export const entryStore = new TableStore<Entry>(
  async (entry: Entry & { _id?: string }, values: Partial<Entry>) => {
    if (entry._id) {
      const result: { rev: string; id: string; ok: boolean } = await syncStore.database?.update({
        ...(entry as Entry & { _id: string }),
      })! // Return the updated entry to the caller so that they can propagate the `_rev`.
      return { ...entry, _rev: result.rev, _id: result.id }
    } else {
      const id = generateRandomInt(10000000, 20000000) // Used as a placeholder for the postgres entry id.
      const result: { rev: string; id: string; ok: boolean } = await syncStore.database?.create({
        id,
        ...(entry as Omit<Entry, "id">),
      })! // Return the updated entry to the caller so that they can propagate the `_rev`.
      return { ...entry, id, _rev: result.rev, _id: result.id }
    }
  },
  async (entry: Entry & { _id: string }) => {
    await syncStore.database?.delete(entry)
  },
  (a, b) => {
    return moment(a.createdAt).diff(moment(b.createdAt)) // This should be rearrangable.
  }
)

// Postgres

const postgresUpdateFn = (entry: Entry, values: Partial<Entry>) => {
  if (entry.id) {
    // Whether it is an update or creation call. TODO: This should be moved into separate calls...
    return invoke(updateEntry, values) // Only update the passed values.
  } else {
    entry = {
      ...entry,
      date: moment(entry.date).toDate(), // Otherwise Zod throws errors...
      posted: moment().toDate(), // It is posted on creation...
      updatedAt: moment(entry.updatedAt).toDate(),
      createdAt: moment(entry.createdAt).toDate(),
      history: [], // TODO: History should be removed from the database.
    }
    return invoke(createEntry, entry)
  }
}

const postgresDeleteFn = async (entry: Entry) => {
  return invoke(updateEntry, { id: entry.id, deleted: true })
}

export const postedStore = new TableStore<Entry>(postgresUpdateFn, postgresDeleteFn, (a, b) => {
  return moment(b.date).diff(moment(a.date))
})

export const reportsDocketStore = new TableStore<Entry>(
  postgresUpdateFn,
  postgresDeleteFn,
  (a, b) => {} // This is already sorted in the DocketTable component.
)

// Emails

export const emailStore = new TableStore<Email>()

// Reports

export const reportsMatterStore = new TableStore<any>()
export const reportsLawyerStore = new TableStore<any>()
export const reportsClientStore = new TableStore<any>()

// Disbursements

export const disbursementsStore = new TableStore<Disbursement>()

// Reviews

export const billStore = new TableStore<Review>()

export const prebillStore = new TableStore<Review>()

// Settings

export const settingsLawyerStore = new TableStore<UserInOrganization>()
export const settingsMatterStore = new TableStore<Matter>()
export const settingsClientStore = new TableStore<Client>()
export const settingsAutoCompleteStore = new TableStore<AutoComplete>()

// Files

export const fileOutgoingStore = new TableStore<Share>()
export const fileIncomingStore = new TableStore<Share>()

// Files

export const archiveOutgoingStore = new TableStore<any>()
export const archiveIncomingStore = new TableStore<any>()

export const outgoingSharesTableStore = new TableStore<Share>()
