import { Box, Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { Breadcrumb, Button } from "antd"
import getAllBalanceAdjustments from "app/balance-adjustments/queries/getAllBalanceAdjustments"
import { Section } from "app/core/components/Section"
import { Input, InputNumber } from "app/core/components/formik/antd"
import { TNavigation } from "app/core/components/layout/Navigation"
import { PageModal } from "app/core/components/modal/PageModal"
import { LoadingSpinner } from "app/core/components/spinner/LoadingSpinner"
import { TTabBar, Tab } from "app/core/components/tab/TTabBar"
import { TTableNotObservable } from "app/core/components/table/TTable"
import { TReadOnlyDateCell } from "app/core/components/table/cells/file/TReadOnlyDateCell"
import { CustomBadge } from "app/core/components/table/dockets/badges/CustomBadge"
import { SuccessBadge } from "app/core/components/table/dockets/badges/SuccessBadge"
import { DocketRangeCell } from "app/core/components/table/reviews/DocketRangeCell"
import { cancelFinalization } from "app/core/components/table/reviews/bill.helpers"
import { matterStore, modalStore, whoAmIStore } from "app/core/stores/store"
import { theme } from "app/core/styles/styles"
import saveAs from "app/core/utils/save"
import getAllReviews from "app/reviews/queries/getAllReviews"
import { BlitzPage, Link, invoke, useParam, useRouter, useSession } from "blitz"
import {
  BalanceAdjustment,
  BalanceAdjustmentType,
  Matter,
  MembershipRole,
  Review,
  UserInOrganization,
} from "db"
import { zipSync } from "fflate"
import { capitalize, isNil, omitBy, orderBy, round } from "lodash"
import { makeAutoObservable } from "mobx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import { CSSProperties, Suspense, useEffect } from "react"
import { ExportTemplateSection } from ".."
const { TextArea } = Input

class MatterPageStore {
  matter: Matter

  bills: Review[] = []
  balanceAdjustments: BalanceAdjustment[] = []

  currencyLocaleFunction = (value?: number | string, currency = "USD") => {
    if (typeof value === "number") {
      value = round(value, 2)
    }
    return (
      value?.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        style: "currency",
        currency,
      }) ?? ""
    )
  }

  addBalanceAdjustment(adjustment: BalanceAdjustment) {
    this.balanceAdjustments.unshift(adjustment)
  }

  setBalanceAdjustments(balanceAdjustments: BalanceAdjustment[]) {
    this.balanceAdjustments = balanceAdjustments
  }

  setBills(bills: Review[]) {
    this.bills = bills
  }

  setMatter(matter: Matter) {
    this.matter = matter
  }

  get debits() {
    return (this.bills as any).reduce((acc, invoice) => acc + invoice.finalizedTotal, 0)
  }

  get adjustments() {
    return (this.balanceAdjustments as any).reduce((acc, adjusment) => acc + adjusment.amount, 0)
  }

  get balance() {
    return this.debits + this.adjustments
  }

  get balanceInLocale() {
    return this.currencyLocaleFunction(this.balance)
  }

  constructor() {
    makeAutoObservable(this)
  }
}

export const matterPageStore = new MatterPageStore()

export const Number = (props: { title; subtitle }) => {
  const { title, subtitle } = props
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
      <span style={{ color: theme.gray[600] }}>{title}</span>
      <span style={{ fontSize: "22px" }}>{subtitle}</span>
    </div>
  )
}

export const CurrencyInput = (props: { style: CSSProperties }) => {
  const { style } = props
  return (
    <InputNumber
      autoFocus
      max={10e9}
      min={0}
      name={"amount"}
      precision={2}
      prefix="CA$"
      style={style}
    />
  )
}

const MatterPage: BlitzPage = (props: {}) => {
  const matterId = useParam("matter", "number")

  if (!matterId) {
    return <LoadingSpinner center></LoadingSpinner>
  }

  return (
    <TNavigation>
      <PageModal />
      <MatterPageContent matterId={matterId}></MatterPageContent>
    </TNavigation>
  )
}

const MatterPageContent = (props: { matterId: number }) => {
  const { matterId } = props
  const router = useRouter()
  const matter = matterStore.findMatterFromId(matterId)
  const client = matterStore.findClientFromMatter(matterId)
  const session = useSession()

  if (matter && client) {
    matterPageStore.setMatter(matter)
  } else {
    return <LoadingSpinner />
  }

  if (session.orgRole === MembershipRole.USER) {
    return null
  }

  const tabs: Tab[] = [
    {
      name: "Overview",
      content: (
        <Suspense fallback={<LoadingSpinner center />}>
          <Section button={null} title="Bills">
            <InvoiceTable matterId={matterId} />
          </Section>
          <Section
            button={
              <Button
                onClick={() => {
                  router.push(`/organization/clients/${client.id}/payment`)
                  // modalStore.setContent(
                  //   "Create payment",

                  //   <BalanceModal isPayment={true} matterId={matterId} />,
                  //   true
                  // )
                }}
              >
                Create payment
              </Button>
            }
            title="Payments and adjustments"
          >
            <BalanceTable matterId={matterId} />
          </Section>
          {/* <Section
            button={null}
            // button={
            //   <Button
            //     onClick={() => {
            //       modalStore.setContent(
            //         "Balance adjustment",
            //         <BalanceModal isPayment={false} matterId={matterId} />,
            //         true
            //       )
            //     }}
            //   >
            //     Adjust balance
            //   </Button>
            // }
            title="Balance"
          >
            <BalanceAmount />
          </Section> */}
        </Suspense>
      ),
    },
    {
      name: "Events and logs",
      content: (
        <Suspense fallback={<LoadingSpinner center />}>
          <Section button={null} title="Invoices" />
        </Suspense>
      ),
    },
  ]

  return (
    <div style={{ display: "flex", gap: theme.spacing[6] }}>
      <div style={{ flex: 1 }}>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link href={`/organization`}>{whoAmIStore?.me.organization.name}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link href={`/organization/clients/${client!.id}`}>{client?.name}</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            <Link href={`/organization/matters/${matter!.id}`}>{matter?.file}</Link>
          </Breadcrumb.Item>
        </Breadcrumb>

        <h1
          style={{
            fontSize: theme.fontSize.xl,
            margin: 0,
            whiteSpace: "nowrap",
            fontWeight: 700,
          }}
        >
          {matter?.file}
        </h1>
        <h3
          style={{
            fontSize: theme.fontSize.md,
            margin: 0,
            whiteSpace: "nowrap",
            fontWeight: 500,
          }}
        >
          {matter?.description}
        </h3>
        <Section dropdown button={null} title="Details" />
        <Section dropdown button={null} title="Properties" />
      </div>
      <div style={{ flex: 2 }}>
        <TTabBar tabs={tabs} />
      </div>
    </div>
  )
}

export default MatterPage

const columns = [
  {
    title: "Total (tax, disburs. and discount incl.)",
    dataIndex: "finalizedTotal",
    key: "finalizedTotal",
    render: (value: number) => matterPageStore.currencyLocaleFunction(round(value, 2)),
  },
  {
    title: "Dockets Subtotal",
    dataIndex: "finalizedSubtotal",
    key: "finalizedSubtotal",
    render: (value: number) => matterPageStore.currencyLocaleFunction(round(value, 2)),
  },

  {
    title: "Disbursements",
    dataIndex: "finalizedDisbursements",
    key: "finalizedDisbursements",
    render: (value: number) => matterPageStore.currencyLocaleFunction(round(value ?? 0.0, 2)),
  },
  {
    title: "Discount",
    dataIndex: "writtenOff",
    key: "writtenOff",
    render: (value: number) => matterPageStore.currencyLocaleFunction(round(value ?? 0.0, 2)),
  },
  {
    title: "Hours",
    dataIndex: "finalizedHours",
    key: "finalizedHours",
  },
  {
    title: "Billed on",
    dataIndex: "arDate",
    key: "arDate",
    render: (value: Date) => <TReadOnlyDateCell date={value} />,
  },
  {
    title: "Docket Range",
    dataIndex: "entryRange",
    key: "entryRange",
    render: (value: [Date, Date]) => <DocketRangeCell range={value} />,
  },
  {
    title: "Bill ID",
    dataIndex: "invoiceNo",
    key: "invoiceNo",
  },
  {
    title: "",
    dataIndex: "action",
    key: "action",
    render: (_, record) => {
      const deleteConfirm = (e: any) => {
        e.stopPropagation()
        modals.openConfirmModal({
          title: "Cancel this bill",
          centered: true,
          children: <Text size="sm">{"Archive"}</Text>,
          labels: { confirm: "Cancel bill", cancel: "No don't cancel it" },
          onCancel: () => console.log("Cancel"),
          onConfirm: () => cancelFinalization(record),
        })
      }
      return (
        <>
          <Button
            onClick={() => {
              modalStore.setContent(
                "Export options",
                <Box>
                  <ExportTemplateSection review={record}></ExportTemplateSection>
                </Box>,
                true
              )
            }}
          >
            Export
          </Button>
          <Button onClick={deleteConfirm}>Archive</Button>
        </>
      )
    },
  },
]

export const zipFiles = (files: { name: string; blob: any }[]) => {
  const zip = {}
  files.forEach((f) => {
    zip[`${f.name}.docx`] = [f.blob, { level: 9 }]
  })
  const zipped = zipSync(zip, {
    level: 9,
  })
  saveAs(new Blob([zipped]), `Export.zip`)
}

export const saveInvoice = (name, blob) => {
  saveAs(new Blob([blob]), `${name}.docx`)
}

export const InvoiceTable = observer((props: { clientId?: number; matterId?: number }) => {
  const { matterId, clientId } = props

  const router = useRouter()

  useEffect(() => {
    const where = omitBy(
      {
        matterId,
        clientId,
        finalized: true,
      },
      isNil
    )

    where["pclawExport"] = null

    invoke(getAllReviews, {
      where,
    }).then((res) => {
      matterPageStore.setBills(res)
    })
  }, [clientId, matterId])

  if (!matterPageStore.bills || matterPageStore.bills.length === 0) {
    return <div style={{ color: theme.gray[500], marginTop: theme.spacing[2] }}>No bills</div>
  }

  return (
    <TTableNotObservable
      onRow={(record) => {
        return {
          style: { cursor: "pointer" },
          onClick: () => {
            router.push(`/organization/matters/${matterId}/bills/${record.id}`)
          },
        }
      }}
      columns={columns}
      dataSource={orderBy(matterPageStore.bills, (a) => moment(a.entryRange[0]), "desc")}
      empty={{
        title: "No invoices",
        subtitle: "This resource has no finalized bills",
      }}
      expands={undefined}
    />
  )
})

export const BalanceSection = (props: { value: string }) => {
  const { value } = props
  return (
    <h1>
      {value}
      <span style={{ color: theme.gray[500], fontSize: "14px" }}>&nbsp;CAD</span>
    </h1>
  )
}

const BalanceAmount = observer(() => {
  return <BalanceSection value={matterPageStore.balanceInLocale}></BalanceSection>
})

const balanceAdjustmentColumns = [
  {
    title: "Amount",
    dataIndex: "amount",
    key: "amount",
    render: (value: number, record) => matterPageStore.currencyLocaleFunction(value),
  },
  {
    title: "Type",
    dataIndex: "type",
    key: "type",
    render: (value: string, record: BalanceAdjustment) =>
      value == BalanceAdjustmentType.PAYMENT ? (
        <SuccessBadge title={`${capitalize(value)}`}></SuccessBadge>
      ) : (
        <CustomBadge title={"Adjustment"} Icon={() => <></>}></CustomBadge>
      ),
  },
  {
    title: "Bill",
    dataIndex: "reviewId",
    key: "reviewId",
    render: (value?: number) =>
      value ? <CustomBadge title={value?.toString()} Icon={() => <></>}></CustomBadge> : null,
  },
  {
    title: "Reason",
    dataIndex: "reason",
    key: "reason",
  },
  {
    title: "Created By",
    dataIndex: "userInOrganization",
    key: "userInOrganization",
    render: (value: UserInOrganization, record) => value.invitedName,
  },
  {
    title: "Created",
    dataIndex: "createdAt",
    key: "createdAt",
    render: (value: Date, record) => <TReadOnlyDateCell date={value} />,
  },
]

export const BalanceTable = observer((props: { matterId?: number }) => {
  const { matterId } = props

  useEffect(() => {
    const where = {
      matterId,
    }

    invoke(getAllBalanceAdjustments, {
      where,
      orderBy: { createdAt: "desc" },
    }).then((res) => {
      matterPageStore.setBalanceAdjustments(res)
    })
  }, [matterId])

  if (!matterPageStore.balanceAdjustments || matterPageStore.balanceAdjustments.length === 0) {
    return (
      <div style={{ color: theme.gray[500], marginTop: theme.spacing[2] }}>
        No balance payments or adjustments
      </div>
    )
  }

  return (
    <TTableNotObservable
      columns={balanceAdjustmentColumns}
      dataSource={matterPageStore.balanceAdjustments}
      empty={{
        title: "No payments",
        subtitle: `This resource has no payments or adjustments`,
      }}
      expands={undefined}
    />
  )
})
