import { Textarea } from "@mantine/core"
import { Button, Form, Input, Select } from "antd"
import createClient from "app/clients/mutations/createClient"
import updateClient from "app/clients/mutations/updateClient"
import { LawyerSearch } from "app/core/components/input/LawyerSearch"
import { whoAmIStore } from "app/core/stores/store"
import { useMutation } from "blitz"
import { Client } from "db"
import Countries from "./Countries.json"
import { TForm } from "./TForm"
import { ratesToMoment } from "./TLawyerForm"
import { TDynamicFormItems } from "./fields/TDynamicFormItems"

const { Option } = Select

const lawyerFormItemProps = {
  name: "userInOrganizationId",
  label: "Responsible Lawyer",
  rules: [
    {
      required: true,
      message: "Please input!",
    },
  ],
}

export const TClientForm = (props: { initialValues?: Client; setQueryData: any }) => {
  const [create] = useMutation(createClient)
  const [update] = useMutation(updateClient)

  const [form] = Form.useForm()

  let _converted = undefined
  if (props.initialValues) {
    _converted = ratesToMoment(props.initialValues)
    delete _converted?.["matters"]
  }

  return (
    <TForm
      includeUIOID
      create={create}
      form={form}
      index={"clients"}
      initialValues={_converted}
      setQueryData={props.setQueryData}
      update={update}
    >
      <>
        <Form.Item
          required
          label="Client Name"
          name="name"
          rules={[
            {
              required: true,
              message: "Please input!",
            },
          ]}
          tooltip="This is a required field"
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          required
          label="Contact Name"
          name="contactName"
          rules={[
            {
              required: true,
              message: "Please input!",
            },
          ]}
          tooltip="This is a required field"
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          required
          label="Contact Email"
          name="email"
          rules={[
            {
              type: "email",
              message: "Please input a valid e-mail",
            },
            {
              required: true,
              message: "Please input an e-mail",
            },
          ]}
          tooltip="This is a required field"
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item label="Contact Title" name="contactTitle" tooltip="This is a required field">
          <Input placeholder="" />
        </Form.Item>
        <Form.Item label="Contact Phone Number" name="phone">
          <Input style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item label="Address" name="address" tooltip="This is a required field">
          <Textarea defaultValue={props.initialValues?.address ?? ""} placeholder="" />
        </Form.Item>

        <LawyerSearch formProps={lawyerFormItemProps} initialId={whoAmIStore.me.id} />

        <Form.Item hasFeedback label="Country" name="country">
          <Select showSearch placeholder="Please select a country">
            {Countries.map((c: any, index) => {
              return (
                <Option key={index} value={c.name}>
                  {c.emoji} {c.name}
                </Option>
              )
            })}
          </Select>
        </Form.Item>

        <Form.Item label="Province / State" name="province" tooltip="This is a required field">
          <Input placeholder="" />
        </Form.Item>

        <Form.Item label="City" name="city" tooltip="This is a required field">
          <Input placeholder="" />
        </Form.Item>

        <Form.Item label="Street Address" name="street" tooltip="This is a required field">
          <Input placeholder="" />
        </Form.Item>
        <Form.Item label="Postal Code" name="postalCode" tooltip="This is a required field">
          <Input placeholder="" />
        </Form.Item>

        <TDynamicFormItems mode form={form} />

        <Form.Item>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
      </>
    </TForm>
  )
}
