import { Button } from "antd"
import { theme } from "app/core/styles/styles"
import { useRouter, useSession } from "blitz"
import { Review } from "db"
import { encode } from "js-base64"

export const preBillUrl = (id: number): string => {
  const billId = encode(id.toString(), true)
  return `/organization/reports/?prebill=${billId}`
}

export const ReviewButton = (props: { review: Review }) => {
  const { review } = props
  const router = useRouter()
  const session = useSession()

  return (
    <>
      <Button
        style={{
          color: "white",
          borderColor: theme.blue[600],
          backgroundColor: theme.blue[400], // TODO: These will not apply until we remove the !important modifiers.
        }}
        onClick={(e) => {
          // If modifier key is pressed, open in new tab...
          if (e.ctrlKey || e.metaKey) {
            window.open(preBillUrl(review.id), "_blank")
            return
          }

          router.push(preBillUrl(review.id))
        }}
      >
        Open
      </Button>
    </>
  )
}
