import { useSession } from "blitz"
import { useState } from "react"
import { LoadingSpinner } from "../../spinner/LoadingSpinner"

export const ExportColumn = (exportFunction) => {
  return {
    title: "",
    dataIndex: "download",
    key: "download",
    width: "2%",
    render: (value, record: any) => {
      return <ExportSpinningButton exportFunction={exportFunction} record={record} />
    },
  }
}

export const ExportSpinningButton = (props: { record: any; exportFunction: any }) => {
  const { record, exportFunction } = props
  const [loading, setLoading] = useState(false)

  const session = useSession()

  return loading ? <LoadingSpinner center /> : <div></div>
}
