import { UserIcon } from "@heroicons/react/solid"
import { Dropdown, Menu } from "antd"
import SubMenu from "antd/lib/menu/SubMenu"
import impersonateRole from "app/auth/mutations/impersonateRole"
import impersonateUser from "app/auth/mutations/impersonateUser"
import { lawyerStore } from "app/core/stores/store"
import { theme } from "app/core/styles/styles"
import { queryClient, useMutation, useRouter, useSession } from "blitz"
import { GlobalRole, MembershipRole } from "db"
import React from "react"

const ImpersonateRoleMenu = () => {
  const session = useSession()
  const [impersonateRoleMutation] = useMutation(impersonateRole)

  return (
    <>
      {Object.values(MembershipRole).map((u, i) => {
        return (
          <Menu.Item
            key={i}
            style={{ fontWeight: session.orgRole === u ? 600 : 400 }}
            onClick={async () => {
              await impersonateRoleMutation({ role: u })
              queryClient.clear()
            }}
          >
            {u}
          </Menu.Item>
        )
      })}
    </>
  )
}

const ImpersonateMenu = () => {
  const [impersonateUserMutation] = useMutation(impersonateUser)

  return (
    <SubMenu title="Impersonate Person">
      {lawyerStore.lawyersArray.map((u, i) => {
        return (
          <Menu.Item
            key={i}
            onClick={async () => {
              await impersonateUserMutation({ userId: u.userId! })
              queryClient.clear()
            }}
          >
            {u.invitedName ?? ""}
          </Menu.Item>
        )
      })}
    </SubMenu>
  )
}

export const TProfileButton = (props: { style: React.CSSProperties; hidden: boolean }) => {
  const session = useSession()
  const router = useRouter()

  return !props.hidden ? (
    <div
      role="button"
      style={{ ...props.style, display: "flex", gap: 20, cursor: "pointer" }}
      tabIndex={0}
      onKeyDown={() => {}}
    >
      <Dropdown
        forceRender
        overlay={
          <Menu>
            <Menu.ItemGroup title="Profile">
              <Menu.Item onClick={() => router.push(`/organization/users/${session.userInOrgId}`)}>
                Activity
              </Menu.Item>
              <Menu.Item onClick={() => router.push("/organization/profile")}>
                Profile Settings
              </Menu.Item>
              <Menu.Item
                onClick={() => {
                  router.push("/signout")
                }}
              >
                Signout
              </Menu.Item>
            </Menu.ItemGroup>

            {session.userRole === GlobalRole.SUPERADMIN ? (
              <>
                <Menu.ItemGroup title="Impersonate Roles">
                  <ImpersonateRoleMenu />
                </Menu.ItemGroup>
                <Menu.ItemGroup title="Impersonate People">
                  <ImpersonateMenu />
                </Menu.ItemGroup>
              </>
            ) : null}
          </Menu>
        }
        trigger={["hover"]}
      >
        <UserIcon color={theme.gray[550]} height={22} />
      </Dropdown>
    </div>
  ) : null
}
