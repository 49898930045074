import { Tooltip } from "antd"
import _ from "lodash"

export const ClickToEdit = (props: {
  tooltip?: string
  value?: string | number | null
  setEditing: () => void
  warning?: boolean
}) => {
  const { tooltip, value, setEditing, warning } = props

  const Wrapper = warning
    ? (props) => <div style={{ backgroundColor: "#FCC419", borderRadius: 5 }}>{props.children}</div>
    : (props) => <>{props.children}</>

  return (
    <Wrapper>
      <div
        className="editable-cell-value-wrap"
        role="textbox"
        style={{}}
        tabIndex={0}
        onClick={() => {
          setEditing()
        }}
        onKeyDown={() => {}}
      >
        <Tooltip popupVisible={!_.isNil(tooltip)} title={tooltip}>
          <span
            style={{
              display: "block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              height: 22,
              maxWidth: 100,
            }}
          >
            {value}
          </span>
        </Tooltip>
      </div>
    </Wrapper>
  )
}
