import { Tooltip } from "antd"
import {
  AugmentedClient,
  AugmentedMatter,
  AugmentedStatistic,
} from "app/core/stores/StatisticStore"
import { reportsClientStore } from "app/core/stores/TableStore"
import { reportStore } from "app/core/stores/store"
import { exportReviewClient } from "app/core/utils/docx"
import { cloneDeep } from "lodash"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { TEmbeddableTable } from "./TEmbeddableTable"
import { TExpandableTableProps } from "./TExpandableTable"
import { TMatterEmbeddableTable } from "./TMatterTable"
import { TTable } from "./TTable"
import { BillableAmountColumn, BillableHoursColumn } from "./columns/ReportColumns"
import { ExportColumn } from "./reports/PreBill"

export const MatterCommas = (props: { matters: AugmentedMatter[] }) => {
  return (
    <div>
      {props.matters.map((m, i) => {
        return (
          <Tooltip key={i} title={m.description}>
            <span>{i > 0 ? ", " : ""}</span>

            {m.file}
          </Tooltip>
        )
      })}
    </div>
  )
}

const columns = [
  {
    title: "Client",
    dataIndex: "name",
    key: "name",
    render: (value, record: AugmentedClient) => <div>{record.name}</div>,
    sorter: (a, b) => {
      return (a.name as string).toLowerCase().localeCompare(b.name.toLowerCase())
    },
  },
  BillableAmountColumn,
  BillableHoursColumn,
  {
    title: "Matters",
    dataIndex: "matters",
    key: "matters",
    render: (value, record: AugmentedClient) => {
      const matters: AugmentedMatter[] = Array.from(record.__data.values()).flat(1)
      return <MatterCommas matters={matters} />
    },
  },

  ExportColumn(exportReviewClient),
]

export const expandReportRow = (record) => {
  let _expands = (record as any).__data
  let expands: any[] = _expands ? Array.from(_expands.values()) : []
  return expands
}

export const TClientEmbeddableTable = (
  props: TExpandableTableProps & { dataSource: AugmentedStatistic[] }
) => {
  return (
    <TEmbeddableTable
      columns={columns}
      dataSource={props.dataSource}
      expands={props.expands}
      style={{}}
    />
  )
}

export const TClientTable = observer((props: TExpandableTableProps) => {
  const [__data, setData] = useState<AugmentedStatistic[]>([])
  const rerender = reportStore.reportsCount

  useEffect(() => {
    if (reportStore.reportsCount > 0) {
      // Unlike traditional reportsByX, this needs to be flattened by reportsByXMerge. Otherwise it will generate separate rows.
      setData(cloneDeep(toJS(Array.from(reportStore.reportsByClient().values()))))
    }
  }, [rerender])

  return (
    <div>
      <TTable
        columns={columns}
        dataSource={__data}
        empty={{
          title: "Nothing to report",
          subtitle: "0 dockets found based on this criteria.",
          action: null,
        }}
        expands={(record) => <TMatterEmbeddableTable dataSource={expandReportRow(record)} />}
        store={reportsClientStore}
      />
    </div>
  )
})
