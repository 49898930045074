import { Table, TablePaginationConfig, TableProps } from "antd"
import { TableStore } from "app/core/stores/TableStore"
import { Entry } from "db"
import { cloneDeep } from "lodash"
import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import { Empty, EmptyProps } from "../Empty"

export const TTableNotObservable = (
  props: TableProps<any> & {
    style?: React.CSSProperties
    dataSource: any
    columns: any
    empty?: EmptyProps
    expands?: any
    pagination?: boolean
  }
) => {
  const { empty } = props

  // Do not use ConfigProvider because it will override all Select, Search, etc. empty states.
  let locale = {
    emptyText: empty ? <Empty {...empty} /> : null,
  }

  return (
    <Table
      locale={locale}
      size="small"
      {...props}
      className={"dark-table"}
      columns={props.columns}
      dataSource={props.dataSource}
      expandedRowRender={typeof props.expands === "object" ? () => props.expands : props.expands}
      pagination={props.pagination ?? false}
      rowClassName={"dark-expanded-row"}
      rowKey={(record: Entry) => {
        return record.id.toString()
      }}
    />
  )
}

export const TTable = observer(
  (
    props: TableProps<any> & {
      store: TableStore<any>
      dataSource?: any
      columns: any
      empty?: EmptyProps
      expands?: any
      pagination?: TablePaginationConfig
    }
  ) => {
    const { empty, columns, pagination, dataSource, store } = props

    // Eventually remove this. TODO.
    useEffect(() => {
      if (dataSource) {
        props.store.setRows(dataSource)
      }
    }, [props.store, dataSource, JSON.stringify(dataSource)])

    let page: any = {}
    if (props.pagination) {
      page = pagination
    }

    // Do not use ConfigProvider because it will override all Select, Search, etc. empty states.
    let locale = {
      emptyText: empty ? <Empty {...empty} /> : null,
    }

    return (
      <Table
        locale={locale}
        pagination={{
          defaultPageSize: parseInt(localStorage.getItem("pageSizePref") ?? "30"),
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["25", "50", "100", "300", "500"],
          ...page,
        }}
        size="small"
        onChange={(pagination, filters, sorter) => {
          if (pagination && pagination.pageSize) {
            localStorage.setItem("pageSizePref", pagination.pageSize.toString())
          }
          if (filters) {
            store.setFilteredInfo(cloneDeep(filters))
          }
        }}
        {...props}
        bordered={false}
        columns={columns.map((column) => {
          return {
            ...column,
            filteredValue: store.filteredInfo[column.dataIndex] || null,
          }
        })}
        dataSource={props.store.rows ? props.store.rows.filter(props.store.filterFn) : []}
        expandedRowRender={typeof props.expands === "object" ? () => props.expands : props.expands}
        rowKey={(record) => {
          return record.id.toString()
        }}
      />
    )
  }
)
