import { saveAs } from "file-saver"
import * as jsonexport from "jsonexport"
import _ from "lodash"
import moment from "moment"
import { AugmentedLawyer } from "../stores/StatisticStore"

export const truncateDigits = (number: number, digits: number): number => {
  return Math.trunc(number * Math.pow(10, digits)) / Math.pow(10, digits)
}

export const sortByMomentDate = (a: string | Date, b: string | Date) => {
  return moment(b).diff(moment(a), "minute")
}

export const lawyerReport = (data: AugmentedLawyer[]): void => {
  const dates = new Set<string>()

  // Populate all of the dates...
  data.forEach((l) => {
    const dayKeys = Object.keys(l.days)
    dayKeys.forEach((k) => {
      dates.add(k)
    })
  })

  // This needs to be sorted to for some reason...
  const allDates = Array.from(dates.values()).sort((a, b) =>
    moment(a, "MMM-D-YYYY").diff(moment(b, "MMM-D-YYYY"), "minute")
  )

  const json = data.map((l, index) => {
    const sourceDays: any = _.cloneDeep(l.days)
    const newDays = {}

    allDates.forEach((k) => {
      if (sourceDays[k]) {
        newDays[k] = `${sourceDays[k].billableHours.toFixed(1)} (${sourceDays[k].totalHours.toFixed(
          1
        )})`
      } else {
        newDays[k] = ""
      }
    })
    return {
      Lawyer: l.shortName.toUpperCase(),
      ...newDays,
      Total: `${data[index].statistics.billableHours} (${data[index].statistics.totalHours})`,
    }
  })

  jsonexport(json, { forceTextDelimiter: true, eol: "\r\n" }, function (err, csv) {
    if (err) return console.error(err)
    saveAs(new Blob([csv]), "Evatir-Employee-Report.csv")
  })
}
