import { Matter } from "@prisma/client"
import { TableStore } from "app/core/stores/TableStore"
import { matterStore } from "app/core/stores/store"
import { observer } from "mobx-react-lite"
import { TTable } from "./TTable"
import { hours } from "./cells/utility/time"

const columns = [
  {
    title: "Matter",
    dataIndex: "file",
    key: "file",
    render: (value: string, record: Matter) => {
      return record.file
    },
    sorter: (a, b) => {
      return a.file.toLowerCase().localeCompare(b.file.toLowerCase())
    },
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "descripton",
    render: (value: string, record: Matter) => {
      return record.description
    },
    sorter: (a, b) => {
      return a.description.toLowerCase().localeCompare(b.description.toLowerCase())
    },
  },
  {
    title: "Client",
    dataIndex: "clientId", // The matterId.
    key: "clientId",
    render: (value: number, record: Matter) => {
      return matterStore.findClientFromId(value)?.name
    },
    sorter: (a, b) => {
      return (matterStore.findClientFromId(a.clientId)?.name ?? "").localeCompare(
        matterStore.findClientFromId(b.clientId)?.name ?? ""
      )
    },
  },
  {
    title: "Entries No.",
    dataIndex: "count",
    key: "count",
    render: (value: number, record: Matter) => {
      return value
    },
    sorter: (a, b) => {
      return (a.count ?? 0) - (b.count ?? 0)
    },
  },
  {
    title: "Finalized No.",
    dataIndex: "billedCount",
    key: "billedCount",
    render: (value: number) => {
      return value
    },
    sorter: (a, b) => {
      return (a.billedCount ?? 0) - (b.billedCount ?? 0)
    },
  },
  {
    title: "Synced No.",
    dataIndex: "syncedCount",
    key: "syncedCount",
    render: (value: number) => {
      return value
    },
    sorter: (a, b) => {
      return (a.syncedCount ?? 0) - (b.syncedCount ?? 0)
    },
  },

  {
    title: "Hours",
    dataIndex: "timeInSeconds",
    key: "timeInSeconds",
    render: (value: number, record: Matter) => {
      return hours(value)
    },
    sorter: (a, b) => {
      return (a.timeInSeconds ?? 0) - (b.timeInSeconds ?? 0)
    },
  },
]

export const MatterTable = observer((props: { store: TableStore<Matter> }) => {
  const { store } = props

  const rowSelection = {
    onChange: (_: React.Key[], selectedRows: Matter[]) => {
      store.setCheckedRows(selectedRows)
    },
    selectedRowKeys: store.checkedRows.map((m) => m.id.toString()),
  }

  return (
    <TTable
      columns={columns}
      empty={{
        title: "No matters",
        subtitle: "There are no matters to display for this criteria.",
        action: null,
      }}
      store={store}
      rowSelection={{
        type: "checkbox",
        ...rowSelection,
      }}
    />
  )
})
