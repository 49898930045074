import { MembershipRole } from "@prisma/client"
import { whoAmIStore } from "app/core/stores/store"
import { Routes } from "blitz"
import { ReactNode } from "react"
import { EmailIcon, HomeIcon, ReportsIcon, SettingsIcon, SharingIcon } from "./Navigation"

export const activeLink = (router, link: any) => {
  let active = router.route === `/organization/${link.name}` || router.route === link.href
  if (router.route.match(new RegExp(`/organization/${link.name}/[0-9]*`))) {
    active = true
  }

  if (link.sub) {
    link.sub.forEach((sub) => {
      if (activeLink(router, sub)) {
        active = true
      }
    })
  }

  return active
}

export const activeLinks = (router) => {
  const loc = (_links) =>
    _links.reduce((prev, curr, index) => {
      const active = activeLink(router, curr)
      let sublinks: string[] = []
      if (curr.sub) {
        sublinks = loc(curr.sub)
      }
      const _new = active
        ? ([curr, ...sublinks, ...(prev as any)] as any)
        : [...sublinks, ...(prev as any)]
      return _new
    }, []) as any
  return loc(links())
}

export const links = () => {
  const local: {
    key: string
    icon?: () => ReactNode
    permission?: boolean
    name: string
    properName?: string
    hidden?: boolean
    sub?: any
    href?: string
  }[] = [
    {
      key: "d",
      name: "dockets",
      href: Routes.DocketPage().pathname,
      icon: HomeIcon,
      sub: [
        // {
        //   key: "dw",
        //   name: "workspace",
        //   header: (
        //     <div style={{ display: "flex", gap: 20 }}>
        //       <StatisticsPeriodDropdown />
        //     </div>
        //   ),
        // },
        {
          key: "dre",
          name: "reports",
          icon: ReportsIcon,
          permission: whoAmIStore.me.role !== MembershipRole.USER,
        },
        {
          key: "lwr",
          name: "matters",
          icon: ReportsIcon,
          permission: whoAmIStore.me.role !== MembershipRole.USER,
        },

        { key: "dr", name: "invoices", properName: "Billing" },
      ],
    },
    {
      key: "em",
      name: "emails",
      icon: EmailIcon,
      permission: whoAmIStore.me.givenAccessToEmails ?? false,
    },
    { key: "fs", name: "sharing", icon: SharingIcon },
    { key: "cf", name: "conflict", icon: SharingIcon },
    {
      key: "s",
      name: "settings",
      icon: SettingsIcon,
      permission: whoAmIStore.me.role !== MembershipRole.USER,
    },
    { key: "p", name: "profile", hidden: true },
  ]
  return local
}
