import { InformationCircleIcon } from "@heroicons/react/solid"
import { Tooltip } from "antd"
import { reviewTrackingStoreDatabase } from "app/core/stores/ReviewTrackingStore"
import { matterStore } from "app/core/stores/store"
import { TableStore } from "app/core/stores/TableStore"
import { theme } from "app/core/styles/styles"
import { Entry } from "db"
import { isNumber } from "lodash"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { useReviewLock } from "../../billing/hooks/locked"
import { updateWithVersionBump } from "../TDocketTable"
import { TAutoCompleteCell } from "./TAutoCompleteCell"

export const TDescriptionCell = observer(
  (props: { record: Entry; store: TableStore<Entry>; reviewId?: number }) => {
    const { record, store, reviewId } = props

    const [maxLength, setMaxLength] = useState<number | undefined>(undefined)
    const [maxTime, setMaxTime] = useState<number | undefined>(undefined)

    const [edited, setEdited] = useState(false)

    let tooltip: string = ""
    if (maxLength) {
      tooltip += ` This matter has a description limit of ${maxLength} words.`
    }

    if (maxTime) {
      tooltip += ` This matter has a time limit of ${maxTime} hours.`
    }

    const entryLocked = useReviewLock(record.billingReviewId)
    let row: Entry = props.store.rows.find((r) => r.id === props.record.id)!

    useEffect(() => {
      ;(async () => {
        if (reviewId) {
          const edited = await reviewTrackingStoreDatabase.reviewed
            .where("[reviewId+entryId]")
            .equals([reviewId.toString(), record.id.toString()])
            .first()
          if (edited) {
            setEdited(true)
          }
        }
      })()
    }, [record.id, reviewId])

    // TODO: Move this down...
    useEffect(() => {
      const init = async () => {
        // Get matter to find the soft maxlength, if it exists.
        if (row.matterId) {
          const matter = matterStore.findMatterFromId(row.matterId)
          if (matter) {
            if (matter.docketLengthSoftLimit) {
              setMaxLength(matter.docketLengthSoftLimit)
            } else {
              setMaxLength(undefined)
            }

            if (matter.docketTimeSoftLimit) {
              setMaxTime(matter.docketTimeSoftLimit)
            } else {
              setMaxTime(undefined)
            }
          }
        }
      }
      init()
    }, [row.matterId])

    return (
      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
        <TAutoCompleteCell
          multiline
          disabled={entryLocked}
          full={isNumber(reviewId)}
          id={record.id}
          maxLength={maxLength}
          store={store}
          style={{
            border: edited ? `1px solid ${theme.blue[300]}` : undefined,
            borderRadius: "5px",
            width: "100%",
            height: "100%",
          }}
          // Margin is important for centering in the table row.
          value={row.description}
          onSave={(value: string) => {
            // We always want to add to the reviewed table, even if the value is the same.
            if (reviewId) {
              reviewTrackingStoreDatabase.reviewed.add({
                entryId: record.id.toString(),
                reviewId: reviewId.toString(),
                description: value,
              })
              setEdited(true)
            }

            // If they match, don't update.
            // TODO: some tests here to ensure persistence.
            if (value?.trim() === row.description?.trim()) {
              return
            }

            runInAction(() => {
              row.description = value
            })
            updateWithVersionBump(row, 0, { id: row.id, description: value }, store)
          }}
        />
        {tooltip !== "" ? (
          <Tooltip title={tooltip}>
            <InformationCircleIcon height={16} width={16} />
          </Tooltip>
        ) : null}
      </div>
    )
  }
)
