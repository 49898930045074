import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/solid"
import { Divider } from "antd"
import { ReactNode, useState } from "react"
import { theme } from "../styles/styles"

export const Section = (props: {
  title
  button
  dropdown?: Boolean
  children?: ReactNode | ReactNode[]
}) => {
  const { button, title, dropdown, children } = props

  const [open, setOpen] = useState(false)

  return (
    <div style={{ marginTop: theme.spacing[6] }}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            cursor: dropdown ? "pointer" : "auto",
            display: "flex",
            alignItems: "center",
            gap: theme.spacing[1],
          }}
          onClick={() => setOpen((p) => !p)}
        >
          {dropdown &&
            (open ? (
              <ChevronDownIcon color={theme.gray[500]} height="16px" />
            ) : (
              <ChevronRightIcon color={theme.gray[500]} height="16px" />
            ))}
          <h4
            style={{
              fontSize: theme.fontSize.md,
              margin: 0,
              whiteSpace: "nowrap",
              fontWeight: 600,
            }}
          >
            {title}
          </h4>
        </div>
        {button}
      </div>
      {!dropdown && (
        <Divider style={{ marginTop: theme.spacing[3], marginBottom: theme.spacing[3] }} />
      )}
      {children}
    </div>
  )
}
