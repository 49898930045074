import { Form, Select } from "antd"
import { lawyerStore } from "app/core/stores/store"

const { Option } = Select

export const LawyerSearch = (props: {
  initialId?: number
  formProps: any
  onSelect?
  defaultValue?
}) => {
  const { onSelect, defaultValue } = props
  return (
    <Form.Item {...props.formProps}>
      <Select
        defaultValue={defaultValue}
        onSelect={onSelect}
        showSearch
        dropdownStyle={{ minWidth: 500 }}
        optionFilterProp="search"
        placeholder="Please select the lawyer"
        style={{ minWidth: 100 }}
      >
        {lawyerStore.lawyersArray.map((c, index) => {
          return (
            <Option
              key={index}
              label={`${c.invitedName}`}
              search={`${c.invitedName} ${c.invitedEmail}`}
              value={c.id}
            >
              {c.invitedName}
            </Option>
          )
        })}
      </Select>
    </Form.Item>
  )
}
