import { TNewEntryButton } from "app/core/components/button/TNewEntryButton"
import { DisbursementsTable, TableType } from "app/core/components/disbursements/DisbursementsTable"
import { TNavigation } from "app/core/components/layout/Navigation"
import { PageModal } from "app/core/components/modal/PageModal"
import { Tab, TTabBar } from "app/core/components/tab/TTabBar"
import { newEntry, TDocketTable, TDocketTableType } from "app/core/components/table/TDocketTable"
import { whoAmIStore } from "app/core/stores/store"
import { entryStore, postedStore } from "app/core/stores/TableStore"
import { syncStore } from "app/core/sync/store"
import { BlitzPage } from "blitz"
import { memo, useEffect } from "react"

export const truncateText = (label: string, amount: number): string => {
  return label.length > amount ? label.slice(0, amount) + "..." : label
}

const Wrapper = memo((props: any) => {
  useEffect(() => {
    syncStore.start(whoAmIStore.me.userId)
  }, [])

  const tabs: Tab[] = [
    {
      name: "Drafts",
      content: (
        <TDocketTable expands={undefined} store={entryStore} type={TDocketTableType.WORKSPACE} />
      ),
    },
    {
      name: "Recently Posted",
      content: (
        <TDocketTable expands={undefined} store={postedStore} type={TDocketTableType.POSTED} />
      ),
    },
    {
      name: "Disbursements",
      content: <DisbursementsTable type={TableType.WORKSPACE} />,
    },
  ]

  return <TTabBar tabs={tabs}></TTabBar>
})

const Buttons = (props: {}) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
      <TNewEntryButton
        onAdd={() =>
          newEntry(
            whoAmIStore.me.id!,
            whoAmIStore.me.id!,
            whoAmIStore.me.organizationId!,
            entryStore,
            whoAmIStore.me.settingTodaysDate
          )()
        }
        onClick={() => {}}
      />
    </div>
  )
}

const DocketPage: BlitzPage = () => {
  return (
    <TNavigation buttons={<Buttons />}>
      <PageModal />
      <Wrapper />
    </TNavigation>
  )
}

DocketPage.authenticate = true
export default DocketPage
