import { theme } from "app/core/styles/styles"
import { CSSProperties, ReactNode } from "react"

export const BadgeDimensions = { height: 16, width: 16 }
export const LargeBadgeDimensions = { height: 20, width: 20 }

export type ColourType = [string, string]

const success: ColourType = [theme.green[200], theme.green[550]]
const info: ColourType = [theme.gray[450], theme.gray[700]]
const warning: ColourType = [theme.red[200], theme.red[550]]
const blue: ColourType = [theme.blue[100], theme.blue[400]]

export const colours = {
  success,
  info,
  warning,
  blue,
}

export const Badge = (props: {
  title: string
  children: ReactNode
  colour: ColourType
  style?: CSSProperties
}) => {
  return (
    <div
      style={{
        fontSize: 12,
        fontWeight: 500,
        lineHeight: "16px",
        borderRadius: 4,
        display: "inline-flex",
        padding: "1px 6px",
        flexDirection: "row",
        gap: 4,
        alignItems: "center",
        backgroundColor: props.colour[0],
        color: props.colour[1],
        ...props.style,
      }}
    >
      <div>{props.title}</div>
      {props.children}
    </div>
  )
}
