import getAllDisbursements from "app/disbursements/queries/getAllDisbursements"
import getAllEntries from "app/entries/queries/getAllEntries"
import getUserInOrganizations from "app/user-in-organizations/queries/getUserInOrganizations"
import { invoke } from "blitz"
import { Client, Disbursement, Entry, Review } from "db"
import { zipSync } from "fflate"
import { saveAs } from "file-saver"
import * as jsonexport from "jsonexport/dist"
import { round } from "lodash"
import moment from "moment"
import { findAllDisbursements } from "../components/table/reviews/PreBillTable"
import {
  AugmentedClient,
  AugmentedLawyer,
  AugmentedMatter,
  organizeDockets,
  reportsByX,
} from "../stores/StatisticStore"
import { matterStore } from "../stores/store"

export const disbursementsForMatterAndRange = async (
  matter: AugmentedMatter,
  range: { start: Date; end: Date },
  review?: Review
) => {
  let results: Disbursement[] = []
  if (review?.id && review?.finalized) {
    results = await invoke(getAllDisbursements, { where: { billingReviewId: review.id } })
  } else {
    results = await findAllDisbursements(matter.id, range.start, range.end)
  }
  return results
}

export const entriesForMatterRangeOrReview = async (
  matter: AugmentedMatter,
  allLawyers: false | { start: Date; end: Date },
  userInOrganizations,
  review?: Review
) => {
  let dockets: Entry[] = []

  if (review?.id && review?.finalized) {
    dockets = await invoke(getAllEntries, { where: { billingReviewId: review!.id } })
  } else if (review?.id && !review?.finalized) {
    dockets = await invoke(getAllEntries, { where: { prebillingReviewId: review!.id } })
  } else if (allLawyers) {
    dockets = await invoke(getAllEntries, {
      where: { matterId: matter.id, date: { gte: allLawyers.start, lte: allLawyers.end } },
    })
  } else {
    dockets = reportsByX([matter])
  }

  // Need to organize the dockets again.
  const { toReport, augmentedEntries } = organizeDockets(
    dockets,
    userInOrganizations,
    // TODO: This is incorrect. Needs to be fixed...
    allLawyers ? [allLawyers.start, allLawyers.end] : [new Date(), new Date()]
  )
  const lawyerReport = Array.from(toReport.values())

  return { lawyerReport, augmentedEntries }
}

const report = async (
  matters: AugmentedMatter[],
  range: { start: Date; end: Date },
  review?: Review,
  csv?: boolean,
  separateFiles?: boolean,
  pclaw?: boolean
) => {
  const files: { name: string; data: any }[] = []

  const { userInOrganizations } = await invoke(getUserInOrganizations, { take: 250 })

  // Contain all entries if export all from CSV...
  let all: Array<any> = []

  for (let m of matters) {
    const client: Client = matterStore.findClientFromMatter(m.id)!

    let { lawyerReport, augmentedEntries } = await entriesForMatterRangeOrReview(
      m,
      range,
      userInOrganizations,
      review
    )

    if (csv) {
      const json = await Promise.all(
        augmentedEntries
          .filter((f) => (pclaw ? !f.flag : true))
          .map(async (ae) => {
            const lawyer = lawyerReport.find((uio) => uio.id === ae.lawyerId)!
            return {
              lawyer: lawyer.shortName.toLowerCase(),
              matter: m.file,
              description: ae.description,
              date: moment(ae.date).format("YYYY-MM-DD"),
              amount: round(ae.statistics.totalHours * ae.statistics.rate, 2).toFixed(2),
              hours: ae.statistics.totalHours.toFixed(1),
              task: ae.task,
              rate: ae.statistics.rate.toFixed(0),
            }
          })
      )

      if (!pclaw) {
        // CSV export stopped working??
        await new Promise<void>((resolve) => {
          jsonexport(
            json,
            { includeHeaders: false, forceTextDelimiter: true, eol: "\r\n" },
            function (err, csv) {
              if (err) return console.error(err)
              files.push({
                name: `${client.name}/Evatir-Invoice-${m.file}.csv`,
                data: new TextEncoder().encode(csv),
              })
              resolve()
            }
          )
        })
      } else {
        all = [...all, ...json]
      }
    }
  }

  if (pclaw) {
    await new Promise<void>((resolve) => {
      jsonexport(
        // Add an extra row to be more compatible with PCLaw...
        // TODO: Replace double escape quotes with single?
        [...all, {}],
        { includeHeaders: false, forceTextDelimiter: true, endOfLine: "\r\n" },
        function (err, csv) {
          if (err) return console.error(err)
          files.push({ name: `Evatir-Export.csv`, data: new TextEncoder().encode(csv) })
          resolve()
        }
      )
    })
  }

  const toBeZipped: any = files.reduce((prev, curr) => {
    prev[curr.name] = [curr.data, { level: 9 }]
    return prev
  }, {})

  const zipped = zipSync(toBeZipped, {
    level: 9,
  })
  saveAs(new Blob([zipped]), "Evatir-Report.zip")
}

export async function exportReviewMatter(
  referenceReport: AugmentedMatter[],
  range: { start: Date; end: Date },
  review?: Review,
  csv?: true,
  separateFiles?: boolean,
  pclaw?: boolean
) {
  await report(referenceReport, range, review, csv, separateFiles, pclaw)
}

export async function exportReviewClient(
  referenceReport: AugmentedClient[],
  range: { start: Date; end: Date },
  review?: Review,
  csv?: true,
  separateFiles?: boolean,
  pclaw?: boolean
) {
  const matters: AugmentedMatter[] = reportsByX(referenceReport)
  await report(matters, range, review, csv, separateFiles, pclaw)
}

export async function exportReviewLawyer(
  referenceReport: AugmentedLawyer[],
  range: { start: Date; end: Date },
  review?: Review,
  csv?: true,
  separateFiles?: boolean,
  pclaw?: boolean
) {
  const clients: AugmentedClient[] = reportsByX(referenceReport)
  const matters: AugmentedMatter[] = reportsByX(clients)
  await report(matters, range, review, csv, separateFiles, pclaw)
}
