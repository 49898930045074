import { Tooltip } from "antd"
import { AugmentedMatter, AugmentedStatistic } from "app/core/stores/StatisticStore"
import { reportsMatterStore } from "app/core/stores/TableStore"
import { reportStore } from "app/core/stores/store"
import { exportReviewMatter } from "app/core/utils/docx"
import { cloneDeep } from "lodash"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { expandReportRow } from "./TClientTable"
import { TDocketEmbeddableTable } from "./TDocketTable"
import { TEmbeddableTable } from "./TEmbeddableTable"
import { TExpandableTableProps } from "./TExpandableTable"
import { TTable } from "./TTable"
import { BillableAmountColumn, BillableHoursColumn } from "./columns/ReportColumns"
import { ExportColumn } from "./reports/PreBill"

const columns = [
  {
    title: "Matter",
    dataIndex: "file",
    key: "file",
    render: (value: string, record: AugmentedMatter) => {
      return <Tooltip title={record.description}>{record.file}</Tooltip>
    },
    sorter: (a, b) => {
      return (a.file as string).toLowerCase().localeCompare(b.file.toLowerCase())
    },
  },
  BillableAmountColumn,
  BillableHoursColumn,
  ExportColumn(exportReviewMatter),
]

export const TMatterEmbeddableTable = (
  props: TExpandableTableProps & { dataSource: AugmentedStatistic[] }
) => {
  return (
    <TEmbeddableTable columns={columns} dataSource={props.dataSource} expands={props.expands} />
  )
}

export const TMatterTable = observer((props: TExpandableTableProps) => {
  const [__data, setData] = useState<AugmentedStatistic[]>([])
  const rerender = reportStore.reportsCount
  useEffect(() => {
    if (reportStore.reportsCount > 0) {
      // Unlike traditional reportsByX, this needs to be flattened by Matter. Otherwise it will generate separate rows.
      const data: Array<AugmentedStatistic> = cloneDeep(
        toJS(Array.from(reportStore.reportsByMatter().values()))
      )
      setData(data)
    }
  }, [rerender])

  return (
    <div>
      <TTable
        columns={columns.concat([
          {
            title: "Export",
            dataIndex: "export",
            sorter: (a, b) => {
              return 0
            },
            key: "export",
            render: (value: string, record: AugmentedMatter) => {
              return (
                <a
                  onClick={async () => {
                    if (props.range === undefined) {
                      return
                    }
                    await exportReviewMatter(
                      [record],
                      {
                        start: props.range[0].toDate(),
                        end: props.range[1].toDate(),
                      },
                      undefined,
                      true
                    )
                  }}
                >
                  Export
                </a>
              )
            },
          },
        ])}
        dataSource={__data}
        empty={{
          title: "Nothing to report",
          subtitle: "0 dockets found based on this criteria.",
          action: null,
        }}
        expands={(record) => <TDocketEmbeddableTable dataSource={expandReportRow(record)} />}
        store={reportsMatterStore}
      />
    </div>
  )
})
