import { Tag } from "antd"
import updateClient from "app/clients/mutations/updateClient"
import { settingsClientStore } from "app/core/stores/TableStore"
import { matterStore, modalStore } from "app/core/stores/store"
import { theme } from "app/core/styles/styles"
import { useMutation, useRouter } from "blitz"
import { Client, Matter } from "db"
import { toJS } from "mobx"
import { TButton } from "../../button/TButton"
import { TTable } from "../TTable"
import { THeaderPadded } from "../cells/headers/THeaders"
import { RowActions } from "../cells/settings/TSettingsActionCell"
import { StaticLawyerCell } from "../cells/static/StaticLawyerCell"
import { TClientForm } from "./forms/TClientForm"
import { TOmniSearch, omniOptions } from "./search/TOmniSearch"

export const TTag = (props: { children: any }) => {
  return (
    <Tag
      color="default"
      style={{ borderRadius: 10, backgroundColor: theme.background[150], marginLeft: 5 }}
    >
      {props.children}
    </Tag>
  )
}

export const TClientSettingsTable = (props: {}) => {
  const [update] = useMutation(updateClient)

  const router = useRouter()

  const openForm = (record?: Client) => {
    const content = (
      <TClientForm
        initialValues={record}
        setQueryData={(row) => {
          matterStore.upsertClientById(row.id ?? "undefined", row)
        }}
      />
    )
    modalStore.setContent("Client Form", <div style={{ padding: "24px" }}>{content}</div>)
  }

  const _archive = async (__record: Client) => {
    const record = toJS(__record)
    await update({ id: record.id, archived: true })
    matterStore.upsertClientById(record.id, { archived: true })
  }

  const columns = [
    {
      title: <THeaderPadded>Name</THeaderPadded>,
      dataIndex: "name",
      key: "name",
      render: (value, record) => (
        <THeaderPadded>
          {value} {record.archived ? <TTag>Archive</TTag> : null}
        </THeaderPadded>
      ),
    },
    {
      title: "Contact Name",
      dataIndex: "contactName",
      key: "contactName",
    },
    {
      title: "Contact Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
    {
      title: "Responsible Lawyer",
      dataIndex: "userInOrganizationId",
      key: "userInOrganizationId",
      render: (value, record: Matter) => {
        return <StaticLawyerCell id={record.userInOrganizationId} />
      },
    },
    RowActions(openForm, _archive),
  ]

  const action = (
    <TButton
      style={{ marginBottom: 16 }}
      text={"Create Client"}
      onClick={() => openForm(undefined)}
    />
  )
  return (
    <div>
      <div style={{ display: "flex", gap: 10 }}>{action}</div>
      <TOmniSearch
        options={omniOptions(columns, matterStore.clientArray)}
        store={settingsClientStore}
      />
      <TTable
        onRow={(record) => {
          return {
            style: { cursor: "pointer" },
            onClick: () => {
              router.push(`/organization/clients/${record.id}`)
            },
          }
        }}
        columns={columns}
        dataSource={matterStore.clientArray}
        empty={{
          title: "No clients",
          subtitle: "Create a client before you can start creating matters.",
          action,
        }}
        store={settingsClientStore}
      />
    </div>
  )
}
