import { Select } from "antd"
import { TableStore } from "app/core/stores/TableStore"
import { Entry, UserInOrganization } from "db"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import React, { useEffect, useRef, useState } from "react"
import { useReviewLock } from "../../billing/hooks/locked"
import { TSelectableCell } from "../base/TSelectableCell"
import { updateWithVersionBump } from "../TDocketTable"
import { ClickToEdit } from "./ClickToEdit"
const { Option } = Select

export const TLawyerCell = observer(
  (props: {
    lawyers: UserInOrganization[]
    record: Entry
    userInOrgId: number
    store: TableStore<Entry>
  }) => {
    const { record, store, lawyers } = props
    const entryLocked = useReviewLock(record.billingReviewId)
    const [editing, setEditing] = useState(false)

    const ref = useRef<any>(null)

    useEffect(() => {
      if (editing) {
        ref.current!.focus()
      }
    }, [editing])

    let row: Entry = props.store.rows.find((r) => r.id === props.record.id)!

    const save = (value: string) => {
      const newVersion = row.version + 1
      runInAction(() => {
        row.version = newVersion
        row!.lawyerId = parseInt(value)
      })
      updateWithVersionBump(row, newVersion, { id: row!.id!, lawyerId: parseInt(value) }, store)
      setEditing(false)
    }

    const NotEditable = (props: {}) => {
      const lawyer = lawyers.find((l) => l.id === row.lawyerId)
      return (
        <ClickToEdit
          setEditing={() => setEditing(true)}
          tooltip={`${lawyer?.invitedName}`}
          value={`${lawyer?.shortName.toUpperCase()}`}
        />
      )
    }

    const options = (
      <>
        {props.lawyers.map((u, i) => {
          return (
            <Option key={i} value={u.id.toString()}>
              {`${u.shortName.toUpperCase()} (${u.invitedName})`}
            </Option>
          )
        })}
      </>
    )

    const Editable = (props: {}) => {
      return (
        <TSelectableCell
          disabled={entryLocked}
          options={options}
          ref={ref}
          style={{ width: 120, minWidth: 120 }}
          value={row.lawyerId.toString()}
          onSave={(value: string) => save(value)}
        />
      )
    }

    return <>{editing ? <Editable /> : <NotEditable />}</>
  }
)
