import { Select } from "antd"
import React, { ReactNode, useState } from "react"

export const TSelectableCell = React.forwardRef(
  (
    props: {
      style?: React.CSSProperties
      value: string | number | null
      onSave: (value: string | number) => void
      onSearch?: (value: string) => void
      options: ReactNode
      other?: any
      disabled: boolean
    },
    ref
  ) => {
    const [focus, setFocus] = useState(false)

    const { disabled } = props

    return (
      <Select
        showSearch
        bordered={focus}
        className="selectable-cell-value-wrap"
        defaultValue={props.value}
        disabled={disabled}
        dropdownStyle={{ minWidth: 300 }}
        open={focus}
        optionFilterProp="children"
        placeholder="Search to Select"
        ref={ref}
        showArrow={false}
        style={{ minWidth: 100, ...props.style }}
        onBlur={() => setFocus(false)}
        onChange={(value: string) => props.onSave(value)}
        onFocus={() => setFocus(true)}
        onSearch={props.onSearch}
        {...props.other}
        //   filterOption={(input, option) =>
        //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        //   }
        //   filterSort={(optionA, optionB) =>
        //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
        //   }
      >
        {props.options}
      </Select>
    )
  }
)
