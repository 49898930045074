import { Select } from "antd"
import { TableStore } from "app/core/stores/TableStore"
import { ReactNode } from "react"

export const TOmniSearch = (props: {
  store: TableStore<any>
  options: {
    label: ReactNode
    options: {
      value: string
      label: ReactNode
    }[]
  }[]
}) => {
  return (
    <Select
      // tagRender={tagRender}
      mode="multiple"
      optionLabelProp="label"
      options={props.options}
      placeholder="Search"
      style={{ width: "100%", marginTop: 6, marginBottom: 24 }}
      onChange={(value, options: Array<any>) => {
        if (options.length === 0) {
          props.store.setFilterFn((o) => true)
        } else {
          const filterFns = options.map((opt) => {
            const _split = opt.value.split("____")
            const property: string = _split[0]
            const value: string = _split[1]
            return (b) => (b[property] as string).includes(value)
          })
          const merged = (r) => filterFns.reduceRight((p, c) => p || c(r), false)
          props.store.setFilterFn(merged)
        }
      }}
    />
  )
}

export const renderOmniTitle = (title: string) => <span>{title}</span>

export const renderOmniItem = (
  property: string,
  title: string
): { value: string; label: ReactNode } => ({
  value: property + "____" + title,
  label: (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {title}
    </div>
  ),
})

const propertiesToSets = (columns, elements) => {
  const map = new Map<string, Set<string>>()

  columns.forEach((c) => {
    map.set(c.key, new Set())
  })

  elements.forEach((c) => {
    if (c) {
      const keys = Array.from(map.keys())

      keys.forEach((k) => {
        const value = c[k]
        if (value && value !== "") {
          const set = map.get(k)!
          set?.add(value)
          map.set(k, set)
        }
      })
    }
  })

  return map
}

export const omniOptions = (columns, elements) => {
  const properties = Array.from(propertiesToSets(columns, elements).entries())

  return properties.map((p) => {
    return {
      label: renderOmniTitle(columns.find((c) => c.key === p[0]).title),
      options: Array.from(p[1].values()).map((v) => renderOmniItem(p[0], v)),
    }
  })
}

// export function tagRender(props) {
//   const { label, value, closable, onClose } = props

//   console.log("label", label)
//   console.log(value, "value")

//   const onPreventMouseDown = (event) => {
//     event.preventDefault()
//     event.stopPropagation()
//   }
//   return (
//     <Tag color={"green"} onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose}>
//       {label}
//     </Tag>
//   )
// }
