import {
  Anchor,
  Box,
  Checkbox,
  FileInput,
  Select,
  Space,
  Stack,
  TextInput,
  Title,
} from "@mantine/core"
import { openConfirmModal } from "@mantine/modals"
import { Button, Divider } from "antd"
import { DatePicker, Form, FormItem, Input } from "app/core/components/formik/antd"
import { lawyerStore, matterStore, modalStore } from "app/core/stores/store"
import getAllDisbursementTemplates from "app/disbursement-templates/queries/getAllDisbursementTemplates"
import createDisbursement, {
  CreateDisbursement,
} from "app/disbursements/mutations/createDisbursement"
import updateDisbursement from "app/disbursements/mutations/updateDisbursement"
import { CurrencyInput, Number, matterPageStore } from "app/pages/organization/matters/[matter]"
import updateReview from "app/reviews/mutations/updateReview"
import getAllReviews from "app/reviews/queries/getAllReviews"
import getTaxRates from "app/tax-rates/queries/getTaxRates"
import { invoke, useQuery, validateZodSchema } from "blitz"
import cuid from "cuid"
import { Disbursement } from "db"
import { Formik, FormikHelpers } from "formik"
import { isNil, omitBy, round, uniqBy } from "lodash"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { FormMatterSelect } from "../matters/MatterSearch"
import { NewReviewerSelect } from "../table/reviews/PreBillForm"

export interface Reviewer {
  id: number
  approved: boolean
}

const useTemplateField = (templateString: string) => {
  const [templateFields, setTemplateFields] = useState<
    { label: string; name: string; value: string }[]
  >([])

  const description = useCallback(
    () =>
      templateString?.replace(/{{(.*?)}}/g, (match) => {
        const templateField = templateFields.find((f) => f.name === match)
        if (templateField && !isNil(templateField.value) && templateField.value !== "") {
          return templateField.value
        }
        return match
      }),
    [templateFields, templateString]
  )

  const setTemplateField = useCallback((name: string, value: string) => {
    setTemplateFields((prev) => {
      return prev.map((f) => {
        if (f.name === name) {
          return { ...f, value }
        }
        return f
      })
    })
  }, [])

  useEffect(() => {
    let newTemplateFields = templateString?.match(/{{(.*?)}}/g)?.map((s) => ({
      label: s?.replace(/{{|}}/g, "")?.trim(),
      name: s,
      value: "",
    }))

    newTemplateFields = uniqBy(newTemplateFields, "name")

    // If there is an existing template field, use its value.
    if (templateFields?.length > 0) {
      newTemplateFields = newTemplateFields?.map((f) => {
        const existing = templateFields.find((t) => t.name === f?.name)
        return existing ? existing : f
      })
    }

    setTemplateFields(newTemplateFields)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateString])

  return { description: description(), templateFields, setTemplateField }
}

const DisbursementTemplateSelector = ({ initialDescription, initialTemplateId, onChange }) => {
  const [data, { isSuccess }] = useQuery(getAllDisbursementTemplates, {})
  const [selected, setSelected] = useState<string>(initialTemplateId)

  let templateString = data?.find((d) => d.id.toString() === selected)?.template ?? ""

  const { description, templateFields, setTemplateField } = useTemplateField(templateString)

  useEffect(() => {
    onChange(description)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [description])

  return (
    <Stack mb={16}>
      <Box>
        <Title order={5}>Description</Title>
        <Box>{description}</Box>
      </Box>

      {isSuccess && (
        <Select
          comboboxProps={{ withinPortal: false }}
          onChange={(s) => {
            setSelected(s ?? "")
            onChange(data?.find((d) => d.id?.toString() === s?.toString())?.template ?? "")
          }}
          defaultValue={initialTemplateId?.toString()}
          data={data?.map((d) => ({
            value: d.id.toString(),
            label: d.name,
          }))}
        ></Select>
      )}
      <Anchor target="_blank" size="xs" href="/organization/dtemplates">
        Create more disbursement templates
      </Anchor>

      {templateFields?.map(({ name, value, label }) => {
        return (
          <Box key={name}>
            <label>{label}</label>
            <TextInput
              value={value}
              onChange={(input) => {
                setTemplateField(name, input.currentTarget.value)
              }}
            ></TextInput>
          </Box>
        )
      })}
    </Stack>
  )
}

export const DisbursementForm = ({
  initialValues = {},
}: {
  initialValues?: Partial<Disbursement>
}) => {
  const lawyers = lawyerStore.lawyersArray

  const [matter, setMatter] = useState()
  const [amount, setAmount] = useState(initialValues?.amount ?? 0.0)
  const [tax, setTax] = useState(initialValues?.tax)
  const [taxRate, setTaxRate] = useState(round(100 * ((initialValues?.tax ?? 0.0) / amount), 2))

  const [results] = useQuery(getTaxRates, {})

  let formatted: any = undefined
  if (initialValues) {
    formatted = {
      ...initialValues,
      date: initialValues.date ? moment(initialValues.date) : undefined,
      dateServiceRendered: initialValues.dateServiceRendered
        ? moment(initialValues.dateServiceRendered)
        : undefined,
      dateInvoiced: initialValues.dateInvoiced ? moment(initialValues.dateInvoiced) : undefined,
      datePaid: initialValues.datePaid ? moment(initialValues.datePaid) : undefined,
    }
  }

  const initialReviewers = (initialValues?.reviewers as Reviewer[] | undefined)?.map(
    (r: Reviewer) => r.id
  )

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-evenly",
          alignItems: "center",
          padding: "24px",
          height: "100px",
        }}
      >
        <Number
          subtitle={matterPageStore.currencyLocaleFunction(amount ?? 0)}
          title={tax ? "Before tax" : "Untaxed"}
        />
        {(tax ?? 0) > 0 && (
          <>
            <Divider style={{ height: "55px" }} type="vertical" />
            <Number subtitle={matterPageStore.currencyLocaleFunction(tax ?? 0)} title={"Tax"} />
          </>
        )}
      </div>
      <Divider style={{ margin: 0, padding: 0 }} />

      <Formik
        initialValues={formatted}
        validate={async (values) => {
          if (values?.["amount"] > -1) {
            const _amount = values["amount"]
            setAmount(_amount)
          }

          const _tax = round(values["amount"] * (taxRate / 100), 2)
          setTax(_tax)

          const valid = await validateZodSchema(CreateDisbursement)({ ...values, tax, matter })
          return valid
        }}
        onSubmit={async (values, formikHelpers: FormikHelpers<{}>) => {
          const func = values.id ? updateDisbursement : createDisbursement
          return invoke(
            func,
            omitBy(
              {
                ...values,
                tax: tax ?? 0.0,
                matterId: matter,
                date: moment(values.date).toDate(),
              },
              isNil
            )
          )
            .then(async (res) => {
              {
                formikHelpers.resetForm()
                modalStore.setVisibility(false)

                // If we are creating...
                if (!values.id) {
                  // Check for existing pre-bills for this matter...
                  const existingReviews = await invoke(getAllReviews, {
                    where: {
                      matterId: matter,
                      finalized: false,
                      archived: false,
                    },
                  })
                  if (existingReviews.length > 0 && !res.estimate) {
                    // If there are existing pre-bills, prompt the user to add this disbursement to the pre-bill.
                    openConfirmModal({
                      title: `We found an existing pre-bill for ${
                        matterStore.findMatterFromId(res.matterId)?.file
                      }`,
                      children: `Would you like to add this disbursement to the existing pre-bill?`,
                      onConfirm: async () => {
                        // If the user confirms, add the disbursement to the pre-bill.
                        await invoke(updateReview, {
                          id: existingReviews[0].id,
                          addDisbursementPrebill: [res.id],
                        })
                      },
                      labels: {
                        confirm: "Add",
                        cancel: "Skip",
                      },
                    })
                  }
                }
              }
            })
            .catch((err) => {
              if (err) {
                console.log(err)
              }
            })
        }}
      >
        {(formik) => (
          <Form initialValues={formatted} layout="vertical">
            <div style={{ padding: "24px" }}>
              {initialValues.reason && initialValues.reason != "" ? (
                <FormItem label="Description" name="reason">
                  <Input type="text" name="reason" />
                </FormItem>
              ) : (
                <DisbursementTemplateSelector
                  onChange={(reason) => {
                    formik.setFieldValue("reason", reason)
                  }}
                  initialDescription={initialValues.reason}
                  initialTemplateId={initialValues.disbursementTemplateId}
                ></DisbursementTemplateSelector>
              )}

              <FormItem
                tooltip={"Is this amount an estimate?"}
                label="Is this an estimate?"
                name="estimate"
              >
                <Checkbox
                  defaultChecked={initialValues.estimate}
                  onChange={(e) => {
                    formik.setFieldValue("estimate", e.target.checked)
                  }}
                  name="estimate"
                />
              </FormItem>

              <FormItem
                tooltip={
                  "Has the vendor been paid? Check the box for faxes, photocopies, USBs, etc."
                }
                label="Has the vendor been paid?"
                name="paid"
              >
                <Checkbox
                  description="Only check this box once we have sent payment to the vendor AND you have attached a payment receipt at the bottom of the form. You may check this box without a payment receipt if the charge is from the firm itself, i.e. faxes, photocopies, USBs, etc."
                  defaultChecked={initialValues.paid}
                  onChange={(e) => {
                    formik.setFieldValue("paid", e.target.checked)
                  }}
                  name="paid"
                />
              </FormItem>

              <FormItem
                tooltip={"This amount should not include the tax, if any."}
                label="Before tax"
                name="amount"
              >
                <CurrencyInput style={{ minWidth: "250px" }} />
              </FormItem>

              <FormItem tooltip={"The tax amount."} label="Tax" name="tax">
                <Select
                  comboboxProps={{ withinPortal: false }}
                  value={taxRate.toString()}
                  onChange={(e) => {
                    if (!isNil(e)) {
                      setTaxRate(parseFloat(e))
                      setTax(round(amount * (parseFloat(e) / 100), 2))
                    }
                  }}
                  data={results?.taxRates.map((el) => ({
                    value: el.percentage.toString(),
                    label: el.name,
                  }))}
                ></Select>
              </FormItem>

              <FormMatterSelect onSelect={setMatter} initialValues={formatted} />

              <NewReviewerSelect
                onChange={(ids) => {
                  // Update the reviewers field with the new ids, while preserving the approved status of the old reviewers.
                  const newReviewers = ids.map((id) => {
                    let reviewer = formik.initialValues?.reviewers?.find((r) => r.id === id)
                    return reviewer ? reviewer : { id, approved: false }
                  })
                  formik.setFieldValue("reviewers", newReviewers)
                }}
                initialValues={initialReviewers}
                users={lawyers}
              />

              <FormItem label="Date For Internal Purposes" name="date">
                <DatePicker format={"MMM-D-YYYY"} name="date" />
              </FormItem>

              <FormItem label="Date Service Rendered" name="dateServiceRendered">
                <DatePicker format={"MMM-D-YYYY"} name="dateServiceRendered" />
              </FormItem>

              <FormItem label="Date Invoiced" name="dateInvoiced">
                <DatePicker format={"MMM-D-YYYY"} name="dateInvoiced" />
              </FormItem>

              <FormItem label="Date Paid" name="datePaid">
                <DatePicker format={"MMM-D-YYYY"} name="datePaid" />
              </FormItem>

              <FileInput
                accept="application/pdf"
                defaultValue={formik.initialValues?.url}
                multiple={false}
                label="Upload cheque request"
                placeholder="Select file"
                onChange={async (file) => {
                  const uid = cuid()
                  const res = await fetch(`/api/s3/presign?key=${uid}`, {
                    headers: { "content-type": "application/xml" },
                  })
                  const { url, fields } = await res.json()
                  const formData = new FormData()
                  Object.entries({ ...fields, file }).forEach(([key, value]) => {
                    formData.append(key, value as string | Blob)
                  })
                  await fetch(url, {
                    method: "POST",
                    body: formData,
                  })
                  formik.setFieldValue("url", uid)
                }}
              />

              <Space my={8}></Space>

              <FileInput
                accept="application/pdf"
                defaultValue={formik.initialValues?.receiptUrl}
                multiple={false}
                label="Upload payment receipt"
                placeholder="Select file"
                onChange={async (file) => {
                  const uid = cuid()
                  const res = await fetch(`/api/s3/presign?key=${uid}`, {
                    headers: { "content-type": "application/xml" },
                  })
                  const { url, fields } = await res.json()
                  const formData = new FormData()
                  Object.entries({ ...fields, file }).forEach(([key, value]) => {
                    formData.append(key, value as string | Blob)
                  })
                  await fetch(url, {
                    method: "POST",
                    body: formData,
                  })
                  formik.setFieldValue("receiptUrl", uid)
                }}
              />
            </div>

            <Divider style={{ margin: 0 }} />
            <div
              style={{
                display: "flex",
                gap: "10px",
                padding: "20px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                onClick={() => {
                  modalStore.setVisibility(false)
                }}
              >
                Cancel
              </Button>
              <Button htmlType="submit">Submit</Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
