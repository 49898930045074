import { AugmentedStatistic } from "app/core/stores/StatisticStore"
import { TExpandableTableProps } from "./TExpandableTable"
import { TTableNotObservable } from "./TTable"

export const TEmbeddableTable = (
  props: TExpandableTableProps & {
    style?: React.CSSProperties
    columns: any
    dataSource: AugmentedStatistic[]
  }
) => {
  return (
    <TTableNotObservable
      columns={props.columns}
      dataSource={props.dataSource}
      expands={props.expands}
      style={{
        paddingTop: 15,
        paddingBottom: 15,
        paddingRight: 15,
        ...props.style,
      }}
    />
  )
}
