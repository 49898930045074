import { Disbursement, Entry, Review } from "db"
import { isNil } from "lodash"
import moment from "moment"

export const dateWithin = (date: Date, entryRange: Date[]) => {
  return (
    moment(entryRange[0]).startOf("day").isBefore(moment(date)) &&
    moment(entryRange[1]).endOf("day").isAfter(moment(date))
  )
}

export const getPreBillForReviewOrDisbursement = async (
  entry: Entry | Disbursement,
  reviews: Review[]
) => {
  for (let r in reviews) {
    const review = reviews[r]
    // For disbursement:
    // We do not check the date range for disbursements.
    if (
      review.finalized === false &&
      !isNil((entry as unknown as Disbursement).reason) &&
      review.matterId === entry.matterId &&
      review.archived === false
    ) {
      return review
    }

    // For entry:
    // We check the date range for entries.
    if (
      review.finalized === false &&
      isNil((entry as unknown as Disbursement).reason) &&
      review.matterId === entry.matterId &&
      review.archived === false &&
      dateWithin(entry.date, review.entryRange)
    ) {
      return review
    }
  }

  return undefined
}
