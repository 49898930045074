import { TimePicker } from "antd"
import { TableStore } from "app/core/stores/TableStore"
import { Entry } from "db"
import _ from "lodash"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import moment from "moment"
import React, { memo, useEffect, useState } from "react"
import { useReviewLock } from "../../billing/hooks/locked"
import { updateWithVersionBump } from "../TDocketTable"
import { ClickToEdit } from "./ClickToEdit"
import { maxSeconds } from "./TBillableTimeCell"
import { entryToMoment, getStoppedSeconds, isRunning } from "./utility/time"

export const TimeCell = memo((props: { record: Entry; store: TableStore<Entry> }) => {
  const { record } = props

  // Make sure it is in editing mode if in the workspace. Indirect check.
  const isWorkspace = _.isNil(record.posted)
  const [editing, setEditing] = useState(isWorkspace)

  return (
    <>
      {editing ? (
        <EditableTimeCell {...props} />
      ) : (
        <NotEditable {...props} setEditing={setEditing} />
      )}
    </>
  )
})

const NotEditable = observer((props: { record: Entry; store: TableStore<Entry>; setEditing }) => {
  const { record, store, setEditing } = props
  const entryLocked = useReviewLock(record.billingReviewId)
  const row: Entry = store.rows.find((r) => r.id === record.id)!

  return (
    <ClickToEdit
      setEditing={() => {
        if (!entryLocked) {
          setEditing(true)
        }
      }}
      tooltip={undefined}
      value={entryToMoment(row).format("HH:mm:ss")}
    />
  )
})

const EditableTimeCell = observer((props: { record: Entry; store: TableStore<Entry> }) => {
  const { record, store } = props

  const row: Entry = store.rows.find((r) => r.id === record.id)!

  let maximumSeconds: number | undefined = maxSeconds()

  const _moment = entryToMoment(row, maximumSeconds)

  const [editing, setEditing] = useState(false)
  const [time, setTime] = useState(_moment)

  const times = JSON.stringify(row.times)

  useEffect(() => {
    setTime(entryToMoment(row, maximumSeconds))
  }, [row, times])

  useEffect(() => {
    const interval = setInterval(() => {
      const running = isRunning(JSON.parse(row.times))
      if (running) {
        setTime(entryToMoment(row!, maximumSeconds))
      } else {
        clearInterval(interval)
      }
    }, 100)
    return () => clearInterval(interval)
  }, [row, times])

  const updateTime = async (value: moment.Moment | null) => {
    if (!_.isNil(value)) {
      let newTimes = { start: 0, stop: value.diff(moment().startOf("day"), "seconds") }

      if (maximumSeconds) {
        if (getStoppedSeconds([newTimes]) > maximumSeconds) {
          newTimes = { start: 0, stop: maximumSeconds }
        }
      }

      const newVersion = row.version + 1
      runInAction(() => {
        row!.times = JSON.stringify([newTimes])
        row.version = newVersion
      })
      updateWithVersionBump(
        row,
        newVersion,
        { id: row!.id!, times: JSON.stringify([newTimes]) },
        store
      )
      setEditing(false)
    }
  }

  return (
    <TimePicker
      allowClear={false}
      bordered={editing}
      placeholder={"00:00:00"}
      showNow={false}
      size={"middle"}
      style={{ minWidth: 85, marginTop: 2, marginBottom: 2 }}
      suffixIcon={null}
      value={time}
      onBlur={() => setEditing(false)}
      onChange={updateTime}
      onFocus={() => setEditing(true)}
    />
  )
})
