import { AnnotationIcon } from "@heroicons/react/solid"
import React from "react"
import { Badge, BadgeDimensions } from "./Badge"

export const CommentBadge = (props: { colour; title: string }) => {
  return (
    <Badge colour={props.colour} title={props.title}>
      <AnnotationIcon {...BadgeDimensions} />
    </Badge>
  )
}
