import { Form, notification } from "antd"
import { modalStore } from "app/core/stores/store"
import { useSession } from "blitz"
import _, { isNil, omitBy } from "lodash"
import moment from "moment"
import { ReactNode } from "react"

export const TForm = (props: {
  newSetQueryData?: boolean
  form?: any
  initialValues?: any
  setQueryData: any
  children: ReactNode[] | ReactNode
  create: (values: any) => void
  update: (values: any) => void
  index: string
  includeUIOID: boolean
}) => {
  const { newSetQueryData } = props

  const session = useSession()

  let operation: any = props.create

  const isUpdateForm = !_.isNil(props.initialValues)

  if (isUpdateForm) {
    operation = props.update
  }

  const onFinish = (params: any) => {
    // console.log(params)

    // Make sure the range is inclusive.
    if (params.entryRange) {
      params.entryRange = [
        moment(params.entryRange[0]).startOf("day"),
        moment(params.entryRange[1]).endOf("day"),
      ]
    }

    // For disbursements...
    if (params.date) {
      params.date = moment(params.date).toDate()
    }

    // For disbursements...
    if (params.amount) {
      params.amount = parseFloat(params.amount)
    }

    if (params.invoiceNo) {
      params.invoiceNo = parseInt(params.invoiceNo)
    }

    const values: any = {
      ...params,
      organizationId: session.orgId,
      userInOrganizationId:
        // If the form has a lawyer field, then we pass the id, otherwise we pass the current session user's id.
        params.userInOrganizationId ?? (props.includeUIOID ? session.userInOrgId : undefined),
    }

    // this functionality is only for reviews...
    const reviewers: any[] = []

    const keys = Object.keys(values)
    for (let k of keys) {
      if (k.startsWith("review")) {
        const tier = parseInt(k.split("review")[1])
        for (let id of values[k]) {
          reviewers.push({ tier: tier, value: id })
        }
        delete values[k]
      }
    }

    if (reviewers.length > 0) {
      values["reviewers"] = reviewers
    }

    // Include initialValues at the beginning in case it's an update. Then clean it up, remove based on `isNil`. This is important because blitz will not allow null for certain properties.
    const inputObject = omitBy({ ...props.initialValues, ...values }, isNil)

    operation(inputObject)
      .then((result) => {
        if (newSetQueryData) {
          // Cleanup based on `isNil`. This is important because our code will not allow null for certain properties.
          props.setQueryData(omitBy(result, isNil))
        } else {
          // Deprecated.
          props.setQueryData((data) => {
            if (data) {
              let _elements: Array<any> = []
              if (!data[props.index]) {
                _elements = [result]
              } else if (isUpdateForm) {
                const _filtered = data![props.index].filter((f) => props.initialValues.id !== f.id)
                _elements = [result, ..._filtered]
              } else {
                _elements = [result, ...data![props.index]]
              }
              const _data = { ...data }
              _data[props.index] = _elements
              return _data
            }
          })
        }

        modalStore.setVisibility(false)
      })
      .catch((error) => {
        notification.error({
          message: "Error while creating",
          description: "Error...",
        })
      })
    console.log("Success:", values)
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo)
  }

  return (
    <Form
      form={props.form}
      initialValues={props.initialValues}
      layout="vertical"
      requiredMark={undefined}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      onValuesChange={() => {}}
    >
      {props.children}
    </Form>
  )
}
