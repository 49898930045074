import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons"
import { Button, DatePicker, Form, Input, Select, Space } from "antd"
import { lawyerStore } from "app/core/stores/store"
import { useEffect, useState } from "react"

const { Option } = Select

const areas = [
  { label: "Standard Rates", value: "STANDARD" },
  { label: "Custom Rates", value: "CUSTOM" },
]

export const TDynamicFormItems = (props: { mode: boolean; form: any }) => {
  const [activeType, setActiveType] = useState<string>("STANDARD")

  useEffect(() => {
    setActiveType(props.form.getFieldValue("rateMode"))
  }, [props.form])

  return (
    <>
      {props.mode ? (
        <Form.Item label="" name="rateMode" rules={[{ required: true, message: "Missing rates" }]}>
          <Select
            options={areas}
            onChange={(value) => {
              setActiveType(value as any)
            }}
          />
        </Form.Item>
      ) : null}

      {!props.mode || activeType === "CUSTOM" ? (
        <Form.List name="rates">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, i) => (
                <Space align="baseline" key={i}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                    }
                  >
                    {() =>
                      props.mode ? (
                        <Form.Item
                          {...field}
                          fieldKey={[field.key, "lawyer"]}
                          label="Lawyer"
                          name={[field.name, "lawyer"]}
                          rules={[{ required: true, message: "Missing lawyer" }]}
                        >
                          <Select showSearch optionFilterProp={"filter"} placeholder="Lawyer">
                            {lawyerStore.lawyersArray.map((c, index) => {
                              return (
                                <Option
                                  filter={`${c.invitedName} ${c.shortName}`}
                                  key={index}
                                  value={c.id}
                                >
                                  {c.invitedName} ({c.shortName})
                                </Option>
                              )
                            })}
                          </Select>
                        </Form.Item>
                      ) : null
                    }
                  </Form.Item>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) =>
                      prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                    }
                  >
                    {() => (
                      <Form.Item
                        tooltip="Inclusive start date. Modifying rate periods and / or amounts will AFFECT account balances for unbilled dockets."
                        {...field}
                        fieldKey={[field.key, "starting"]}
                        label="Start Date"
                        name={[field.name, "starting"]}
                        rules={[{ required: true, message: "Missing starting date" }]}
                      >
                        <DatePicker format={"MMM-D-YYYY"} />
                      </Form.Item>
                    )}
                  </Form.Item>
                  <Form.Item
                    {...field}
                    fieldKey={[field.key, "rate"]}
                    label="Rate"
                    name={[field.name, "rate"]}
                    rules={[{ required: true, message: "Missing rate" }]}
                  >
                    <Input type="number" />
                  </Form.Item>

                  <MinusCircleOutlined onClick={() => remove(field.name)} />
                </Space>
              ))}

              <Form.Item>
                <Button block icon={<PlusOutlined />} type="dashed" onClick={() => add()}>
                  Add another rate
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      ) : null}
    </>
  )
}
