import { Tooltip } from "antd"

export const TInfoGraphic = (props: { style?: React.CSSProperties }) => {
  return (
    <svg
      fill="none"
      stroke="currentColor"
      style={props.style}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  )
}

export const HoverInfo = (props: { text: string }) => {
  return (
    <Tooltip title={props.text}>
      <div style={{ width: 15, display: "flex", placeSelf: "center", marginLeft: 6 }}>
        <TInfoGraphic style={{ width: 15, height: 15 }} />
      </div>
    </Tooltip>
  )
}
