import { Badge, Button, Layout, Menu } from "antd"
import { modalStore } from "app/core/stores/store"
import { theme } from "app/core/styles/styles"
import { SYNC_STATUS } from "app/core/sync"
import { syncStore } from "app/core/sync/store"
import { useRouter } from "blitz"
import { observer } from "mobx-react-lite"
import { ReactNode, Suspense, useEffect, useState } from "react"
import Stats from "../Stats"
import { LoadingSpinner } from "../spinner/LoadingSpinner"
import { activeLinks, links } from "./Links"
import { PreLoader } from "./PreLoader"
import { SubNavigation } from "./SubNavigation"
import { TProfileButton } from "./TProfileButton"
const { Header, Content, Footer } = Layout

// const divButtonProps = {
//   role: "button",
//   tabIndex: 0,
//   onKeyDown: () => {}
// }

export const properCase = (str: string): string => {
  return str[0].toUpperCase() + str.slice(1)
}

// const StatisticsTypeDropdown = observer((props: {}) => {
//   const ActionMenu = (
//     <Menu triggerSubMenuAction="click">
//       <Menu.Item
//         style={{ borderRadius: 0 }}
//         onClick={() => statisticStore.setType("minimal")}
//         key="1"
//       >
//         Minimal Statistics
//       </Menu.Item>
//       <Menu.Item style={{ borderRadius: 0 }} onClick={() => statisticStore.setType("full")} key="2">
//         Full Statistics
//       </Menu.Item>
//     </Menu>
//   )

//   return (
//     <div>
//       <Group>
//         <Dropdown
//           onVisibleChange={() => {}}
//           overlayStyle={{}}
//           trigger={["click"]}
//           overlay={ActionMenu}
//         >
//           <Button style={{ ...TButtonStyle, marginBottom: 16, fontWeight: 400 }}>
//             {properCase(statisticStore.type) + " Statistics"} <DownOutlined />
//           </Button>
//         </Dropdown>
//       </Group>
//     </div>
//   )
// })

export const StatisticsPeriodDropdown = observer((props: {}) => {
  const router = useRouter()

  const handleClick = () => {
    modalStore.setContent(
      "Statistics",
      <div>
        <Stats></Stats>
      </div>,
      true
    )
  }

  // const ActionMenu = (
  //   <Menu triggerSubMenuAction={"hover"}>
  //     <Menu.Item
  //       key="1"
  //       style={{ borderRadius: 0 }}
  //       onClick={() => statisticStore.setPeriod("daily")}
  //     >
  //       Daily Statistics
  //     </Menu.Item>
  //     <Menu.Item
  //       key="2"
  //       style={{ borderRadius: 0 }}
  //       onClick={() => statisticStore.setPeriod("weekly")}
  //     >
  //       Weekly Statistics
  //     </Menu.Item>
  //     <Menu.Item
  //       key="3"
  //       style={{ borderRadius: 0 }}
  //       onClick={() => statisticStore.setPeriod("monthly")}
  //     >
  //       Monthly Statistics
  //     </Menu.Item>
  //   </Menu>
  // )

  return (
    <div>
      <Button onClick={handleClick} style={{ marginBottom: 16, fontWeight: 400 }}>
        Statistics
      </Button>
      {/* <Group>
        <Dropdown
          overlay={ActionMenu}
          overlayStyle={{}}
          trigger={["hover"]}
          onVisibleChange={() => {}}
        >
          <Button style={{ marginBottom: 16, fontWeight: 400 }}>
            {properCase(statisticStore.period) + " Statistics"} <DownOutlined />
          </Button>
        </Dropdown>
      </Group> */}
    </div>
  )
})

export const SyncStatus = observer(() => {
  const [status, setStatus] = useState<SYNC_STATUS>(
    syncStore.database?.status ?? SYNC_STATUS.PENDING
  )

  useEffect(() => {
    const status = syncStore.database?.status
    if (status) {
      setStatus(status)
    }
  }, [syncStore.database?.status])

  if (status === SYNC_STATUS.SYNCED) {
    return <Badge status="processing" text="Syncing" />
  } else if (status === SYNC_STATUS.PENDING) {
    return <Badge status="success" text="Synced" />
  } else if (status === SYNC_STATUS.ERROR) {
    return <Badge status="error" text="Error Syncing" />
  } else if (status === SYNC_STATUS.OFFLINE) {
    return <Badge status="warning" text="Waiting" />
  } else {
    return null
  }
})

export const TNavigation = (props: {
  buttons?: ReactNode
  children: ReactNode | ReactNode[]
  titleOverride?: string
}) => {
  return (
    <PreLoader>
      <Layout style={{ backgroundColor: theme.background[100] }}>
        <Suspense fallback={<LoadingSpinner center></LoadingSpinner>}>
          <NavigationContent {...props}></NavigationContent>
        </Suspense>
      </Layout>
    </PreLoader>
  )
}

const NavigationContent = (props: {
  buttons?: ReactNode
  children: ReactNode | ReactNode[]
  titleOverride?: string
}) => {
  const { buttons } = props

  const router = useRouter()
  const active = activeLinks(router)

  const deepestActiveLink = active.length > 0 ? active[active.length - 1] : undefined

  return (
    <>
      <Header
        style={{
          boxShadow: "0 1px #e3e8ee",
          backgroundColor: theme.background[125],
          paddingRight: 16,
          paddingLeft: 16,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 5,
            height: "100%",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#e3e8ee",
          }}
        >
          {/* <OrganizationButton hidden={collapsed} style={{}} /> */}
          <Menu
            disabledOverflow
            defaultOpenKeys={active.map((l) => l.key)}
            mode="horizontal"
            selectedKeys={active.map((l) => l.key)}
            style={{ backgroundColor: theme.background[125] }}
            theme="light"
          >
            {links()
              .filter((f) => !f["hidden"] && f.permission !== false)
              .map((l, index) => {
                return (
                  <Menu.Item
                    key={index}
                    onClick={() => {
                      router.push(l.href ?? `/organization/${l.name}`)
                    }}
                  >
                    <span
                      style={{
                        fontWeight: 500,
                        userSelect: "none",
                      }}
                    >
                      {l.properName ? l.properName : properCase(l.name)}
                    </span>
                  </Menu.Item>
                )
              })}
          </Menu>

          <div />

          <SyncStatus />

          {/* <TSearch></TSearch> */}
          <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
            {/* Significantly slows down the app... */}
            <TProfileButton hidden={false} style={{}} />
            {/* <QuestionButton style={{}} /> */}
          </div>
        </div>
      </Header>
      <SubNavigation />
      <div style={{ padding: 32 }}>
        <div style={{ display: "flex", alignItems: "center", gap: 20, marginBottom: 0 }}>
          <h1
            style={{
              fontSize: theme.fontSize.xl,
              margin: 0,
              whiteSpace: "nowrap",
              fontWeight: 700,
            }}
          >
            {active.length > 0
              ? props.titleOverride ??
                (deepestActiveLink.properName
                  ? deepestActiveLink.properName
                  : properCase(deepestActiveLink.name))
              : ""}
          </h1>
          <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}>
            {active.length > 0 && deepestActiveLink.header ? deepestActiveLink.header : null}
          </div>
          {buttons}
        </div>

        <Content style={{}}>{props.children}</Content>
        <Footer style={{ backgroundColor: "#fff" }} />
      </div>
    </>
  )
}

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}

export const ReportsIcon = () => {
  return (
    <svg style={{ width: 16, height: 16 }} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12,8h1.56v6.22l4.66,0L12,20.4,12,24h7.2a2.41,2.41,0,0,0,2.4-2.4V7.2L14.4,0H12"
        style={{ opacity: 0.8 }}
      />
      <path d="M12,8H10.44v6.22l-4.66,0L12,20.4,12,24H4.79A2.4,2.4,0,0,1,2.4,21.6V2.4A2.4,2.4,0,0,1,4.8,0H12" />
    </svg>
  )
}

export const HomeIcon = () => {
  return (
    <svg
      height="16"
      style={{ width: 16, height: 16 }}
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path
          d="M9.5 15.035V11.63a.6.6 0 00-.607-.596H7.107a.604.604 0 00-.607.596v3.405h-4a.998.998 0 01-1-.998V7.032c0-.381.218-.729.56-.896l5.96-4.9 5.896 4.889a.997.997 0 01.584.907v7.005a.998.998 0 01-.999.998zM8 8.835a1.3 1.3 0 100-2.6 1.3 1.3 0 000 2.6z"
          fill="#A3ACB9"
        />
        <path
          d="M8.002 1.004a.403.403 0 00-.307.085L.552 6.873a.4.4 0 00-.06.57l.75.925c.138.17.395.2.568.06l6.192-5.015 6.193 5.015c.173.14.43.11.57-.06l.748-.926a.4.4 0 00-.06-.57L8.31 1.09a.403.403 0 00-.308-.085z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export const SettingsIcon = () => {
  return (
    <svg
      height="16"
      style={{ width: 16, height: 16 }}
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.197 7.017c-.23 0-1.607 0-2.18-.695 0-.115-.115-.115-.115-.231-.115-.81.918-1.851 1.032-1.967.23-.231.23-.579.115-.694-.115-.116-.574-.579-.688-.81-.115-.232-.46-.116-.689.116-.23.23-1.147 1.157-1.95 1.04-.115 0-.115-.115-.23-.115-.689-.463-.689-1.851-.689-2.082 0-.348-.114-.579-.344-.579H7.311c-.229 0-.344.231-.344.579 0 .23 0 1.62-.688 2.198-.115 0-.115.116-.23.116-.803 0-1.721-.926-1.95-1.157-.23-.232-.574-.232-.69-.116-.114.231-.573.694-.802.81-.115.115-.115.463.114.694.23.231 1.148 1.157 1.033 1.967 0 .116-.115.116-.115.231-.459.695-1.836.695-2.065.695-.344 0-.574.115-.574.347v1.041c0 .231.23.463.574.463.23 0 1.606 0 2.18.694 0 .116.115.116.115.231.115.81-.918 1.852-1.033 1.967-.23.232-.23.579-.115.695.115.115.574.578.689.81.115.115.459.115.688-.116.23-.232 1.148-1.157 1.951-1.042.115 0 .115.116.23.116.688.579.688 1.851.688 2.198 0 .232.23.579.46.579h1.032c.23 0 .459-.231.459-.579 0-.23 0-1.62.689-2.198.114 0 .114-.116.23-.116.802-.115 1.835.926 1.95 1.042.23.231.574.231.688.115l.804-.81c.114-.115.114-.462-.115-.694-.23-.231-1.148-1.157-1.033-1.967 0-.115.115-.115.115-.231.574-.694 1.836-.694 2.18-.694.23 0 .574-.232.574-.463V7.364c-.23-.232-.459-.347-.803-.347zm-6.312 3.47c-1.377 0-2.524-1.156-2.524-2.545 0-1.388 1.147-2.545 2.524-2.545s2.525 1.157 2.525 2.545c0 1.389-1.148 2.546-2.525 2.546z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </svg>
  )
}

export const SharingIcon = () => {
  return (
    <svg
      style={{ width: 16, height: 16 }}
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path
          d="M5.538 3.4v5.64c0 .55-.444.996-1 .996-.552 0-1-.455-1-.995V3.414L1.712 5.241a.999.999 0 01-1.417.002.994.994 0 01.002-1.416L3.83.295A.999.999 0 014.546 0c.259-.002.518.097.717.295l3.53 3.532c.4.4.394 1.026.003 1.416a.999.999 0 01-1.416-.002l-1.84-1.84z"
          fill="#A3ACB9"
        />
        <path
          d="M12.123 12.722v-5.64a.995.995 0 00-1-.995c-.552 0-1 .455-1 .995v5.626l-1.826-1.826a.999.999 0 00-1.416-.002.994.994 0 00.002 1.416l3.531 3.531c.198.199.458.297.716.296a1 1 0 00.717-.296l3.531-3.531c.4-.4.393-1.026.002-1.416a.999.999 0 00-1.416.002l-1.84 1.84z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}

export const EmailIcon = () => {
  return (
    <svg
      height="16"
      style={{ width: 16, height: 16 }}
      viewBox="0 0 16 16"
      width="16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none">
        <path
          d="M8.002 16.004A8.002 8.002 0 118.002 0c.455 0 .9.047 1.334.12V5h1.666v1.668h4.882c.073.435.12.88.12 1.334a8.002 8.002 0 01-8.002 8.002zm1.992-6.01H6.348a.34.34 0 01-.34-.34v-3.64h-2.44a.338.338 0 00-.24.1l-2.23 2.23a6.905 6.905 0 006.57 6.563l2.227-2.228c.063-.063.1-.15.1-.24V9.992z"
          fill="#A3ACB9"
        />
        <path
          d="M15.886 6.686L12.68 9.894a.338.338 0 01-.24.1H9.994V6.352a.338.338 0 00-.338-.338H6.009V3.559a.34.34 0 01.1-.24l3.2-3.203a7.993 7.993 0 016.577 6.57z"
          fill="currentColor"
        />
      </g>
    </svg>
  )
}
