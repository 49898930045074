import {
  AugmentedClient,
  AugmentedLawyer,
  AugmentedMatter,
  AugmentedStatistic,
  reportsByX,
} from "app/core/stores/StatisticStore"
import { reportsLawyerStore } from "app/core/stores/TableStore"
import { reportStore } from "app/core/stores/store"
import { exportReviewLawyer } from "app/core/utils/docx"
import { cloneDeep } from "lodash"
import { toJS } from "mobx"
import { observer } from "mobx-react-lite"
import { useEffect, useState } from "react"
import { MatterCommas, TClientEmbeddableTable, expandReportRow } from "./TClientTable"
import { TExpandableTableProps } from "./TExpandableTable"
import { TMatterEmbeddableTable } from "./TMatterTable"
import { TTable } from "./TTable"
import { BillableAmountColumn, BillableHoursColumn } from "./columns/ReportColumns"
import { ExportColumn } from "./reports/PreBill"

const columns = [
  {
    title: "Lawyer",
    dataIndex: "lawyer",
    key: "lawyer",
    render: (value, record: AugmentedLawyer) => <div>{record.invitedName}</div>,
    sorter: (a, b) => {
      return (a.lawyer as string).toLowerCase().localeCompare(b.lawyer.toLowerCase())
    },
  },
  BillableAmountColumn,
  BillableHoursColumn,
  {
    title: "Clients",
    dataIndex: "clients",
    key: "clients",
    render: (value, record: AugmentedLawyer) => {
      const clients: AugmentedClient[] = Array.from(record.__data.values()).flat(1)
      return <div>{clients.reduce((p, c, i) => c.name + (i > 0 ? ", " : "") + p, "")}</div>
    },
  },
  {
    title: "Matters",
    dataIndex: "matters",
    key: "matters",
    render: (value, record: AugmentedLawyer) => {
      const matters: AugmentedMatter[] = reportsByX(Array.from(record.__data.values()))
      return <MatterCommas matters={matters} />
    },
  },
  ExportColumn(exportReviewLawyer),
]

export const TLawyerTable = observer((props: TExpandableTableProps) => {
  const [__data, setData] = useState<AugmentedStatistic[]>([])
  const rerender = reportStore.reportsCount
  useEffect(() => {
    if (reportStore.reportsCount > 0) {
      setData(cloneDeep(toJS(reportStore.reportsByLawyer())))
    }
  }, [rerender])

  return (
    <TTable
      columns={columns}
      dataSource={__data}
      empty={{
        title: "Nothing to report",
        subtitle: "0 dockets found based on this criteria.",
        action: null,
      }}
      expands={(record) => (
        <TClientEmbeddableTable
          dataSource={expandReportRow(record)}
          expands={(subRecord) => (
            <TMatterEmbeddableTable dataSource={expandReportRow(subRecord)} />
          )}
        />
      )}
      store={reportsLawyerStore}
    />
  )
})
