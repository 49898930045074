import { useDebounceFn } from "ahooks"
import { Mentions, notification, Select, Tooltip } from "antd"
import { useAutoCompleteStore } from "app/core/stores/global/UseAutoCompleteStore"
import { TableStore } from "app/core/stores/TableStore"
import { theme } from "app/core/styles/styles"
import { Entry } from "db"
import { isNil } from "lodash"
import { matchSorter } from "match-sorter"
import { observer } from "mobx-react-lite"
import React, { useEffect, useRef, useState } from "react"
const { Option } = Select

export function isStrEmpty(str) {
  return isNil(str) || str.length === 0
}

export const TAutoCompleteCell = observer(
  (
    props: {
      full: boolean
      maxLength?: number
      style?: React.CSSProperties
      id: number
      multiline: boolean
      disabled: boolean
      onSave: (value: string) => void
      store: TableStore<Entry>
    } & {
      [key: string]: any
    }
  ) => {
    const { id, disabled, maxLength, style, store, onSave } = props
    const row: Entry = store.rows.find((r) => r.id === id)!

    const [, autocompletes] = useAutoCompleteStore()
    const [search, setSearch] = useState("")
    const [editing, setEditing] = useState(false)

    const valueRef = useRef<string>(row?.description ?? "")
    const inputRef = useRef<any>(null)

    useEffect(() => {
      // When the id changes, we have to reset the refs. Happens when entries are added or removed.
      valueRef.current = row?.description ?? ""
    }, [id, row?.description])

    const { run, cancel } = useDebounceFn(
      (change: string) => {
        onSave(change)
      },
      {
        wait: 300,
      }
    )

    useEffect(() => {
      if (editing) {
        inputRef.current!.focus()
      }
    }, [editing])

    const toggleEdit = () => {
      if (!disabled) {
        setEditing((prev) => !prev)
      }
    }

    const save = async () => {
      try {
        cancel() // Stop debouncing.
        toggleEdit()
        props.onSave(valueRef.current)
        setEditing(false)
      } catch (errInfo) {
        console.log("Save failed:", errInfo)
      }
    }

    const maximumWords = maxLength ?? 5000

    return (
      <div style={style ?? {}}>
        {!disabled && editing ? (
          <div
            style={{
              position: props.full ? "static" : "absolute",
              right: 0,
              zIndex: 100,
              top: props.full ? 0 : 12,
              left: props.full ? 0 : 4,
              marginRight: props.full ? 0 : "10px",
              boxShadow: `2px 2px ${theme.gray[400]}`,
            }}
          >
            <Mentions
              spellCheck
              autoSize={{ minRows: 1 }}
              defaultValue={valueRef.current!}
              filterOption={() => true}
              maxLength={5000}
              placeholder="Enter a description... @ for autocomplete..."
              prefix={["@"]}
              ref={inputRef}
              style={{ ...props.style, resize: "auto" as any }}
              onBlur={() => {
                save()
              }}
              onChange={(inputtedText) => {
                function countWords(str: string) {
                  return str?.trim()?.split(/\s+/).length
                }

                if (
                  countWords(inputtedText) > maximumWords &&
                  countWords(inputtedText) > countWords(valueRef.current)
                ) {
                  notification.open({
                    message:
                      "You've reached the maximum word length from this matter's settings. It is set to " +
                      maximumWords +
                      " words.",
                  })
                  return
                }
                valueRef.current = inputtedText
                if (!inputtedText.includes("@")) {
                  // TODO: Make this more robust. Issues with adding mentions including the `@` character.
                  run(inputtedText) // Propagate the change to a debounced `save` function.
                }
              }}
              onPressEnter={() => (props.multiline ? {} : save)}
              onSearch={(text: string, prefix: string) => {
                setSearch(text)
              }}
              onSelect={(value) => {
                setTimeout(async function () {
                  if (inputRef.current) {
                    const selectionStart = inputRef.current.textarea.selectionStart
                    const start = selectionStart - (value.value ? value.value!.length : 0) - 2
                    if (start > -1) {
                      const text: string = inputRef.current.textarea.value
                      const newText = pickAt(text, start)
                      inputRef.current.textarea.value = newText
                      inputRef.current.textarea.selectionStart = selectionStart - 1
                      inputRef.current.textarea.selectionEnd = selectionStart - 1
                    }
                  }
                }, 35)
              }}
            >
              {matchSorter(autocompletes, search, {
                keys: ["abbreviation", "expanded"],
              }).map((value, index) => {
                return (
                  <Option key={value.abbreviation + "---" + index} value={`${value.expanded}`}>
                    <small>{`(${value.abbreviation})`}</small> {`${value.expanded}`}
                  </Option>
                )
              })}
            </Mentions>
          </div>
        ) : (
          <div
            className="editable-cell-value-wrap"
            role="textbox"
            style={{}}
            tabIndex={0}
            onClick={() => {
              toggleEdit()
            }}
            onKeyDown={() => {}}
          >
            <Tooltip
              overlayStyle={{ maxWidth: "550px" }}
              title={row?.description}
              visible={props.full ? false : undefined}
            >
              <span
                style={{
                  display: "block",
                  overflow: "hidden",
                  height: props.full ? "auto" : 22,
                }}
              >
                {row?.description}
              </span>
            </Tooltip>
          </div>
        )}
      </div>
    )
  }
)

const pickAt = function (of: string, index: number) {
  return of.substr(0, index) + of.substr(index + 1)
}
