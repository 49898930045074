import { Button, Checkbox, Form, Input, Select } from "antd"
import { theme } from "app/core/styles/styles"
import createUserInOrganization from "app/user-in-organizations/mutations/createUserInOrganization"
import updateUserInOrganization from "app/user-in-organizations/mutations/updateUserInOrganization"
import { useMutation } from "blitz"
import { MembershipRole, Prisma } from "db"
import { cloneDeep } from "lodash"
import moment from "moment"
import { useState } from "react"
import { sendInvitation } from "../TLawyerSettingsTable"
import { TForm } from "./TForm"
import { TDynamicFormItems } from "./fields/TDynamicFormItems"

const { Option } = Select

export const ratesToMoment = (initialValues) => {
  return {
    ...initialValues,
    rates: initialValues.rates
      ? (initialValues.rates as Prisma.JsonArray).map((r: any) => {
          return { ...r, starting: moment(r.starting) }
        }) ?? []
      : undefined,
  }
}

export const TLawyerForm = (props: { initialValues?: any; setQueryData: any }) => {
  const [create] = useMutation(createUserInOrganization)
  const [update] = useMutation(updateUserInOrganization)

  const [emailChecked, setEmailChecked] = useState<boolean>(
    props.initialValues?.givenAccessToEmails ?? false
  )

  const [form] = Form.useForm()

  let _converted = undefined
  if (props.initialValues) {
    _converted = ratesToMoment(props.initialValues)
  }

  return (
    <TForm
      newSetQueryData
      create={async (obj: any) => {
        const invited = await create({
          ...obj,
          invitedEmail: obj.invitedEmail.toLowerCase(),
          givenAccessToEmails: emailChecked,
        })
        sendInvitation(invited.id)
        return invited
      }}
      form={form}
      includeUIOID={false}
      index={"userInOrganizations"}
      initialValues={_converted}
      setQueryData={props.setQueryData}
      update={(obj: any) => {
        return update({
          ...cloneDeep(obj),
          invitedEmail: obj.invitedEmail.toLowerCase(),
          givenAccessToEmails: emailChecked,
        })
      }}
    >
      <>
        <Form.Item
          required
          label="Display Name"
          name="invitedName"
          rules={[
            {
              required: true,
              message: "Please input!",
            },
            {
              type: "string",
            },
          ]}
          tooltip="This is a required field"
        >
          <Input placeholder="" />
        </Form.Item>
        {/* TODO: Only allow alphabetical characters... */}
        <Form.Item
          label="Matter Prefix"
          name="matterPrefix"
          rules={[{ type: "string", max: 2 }]}
          tooltip="Only enter alphabetical characters"
        >
          <Input required={false} placeholder="" />
        </Form.Item>
        <Form.Item
          required
          label="Title"
          name="title"
          rules={[
            {
              required: true,
              message: "Please input!",
            },
            {
              type: "string",
            },
          ]}
          tooltip="This is a required field"
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          required
          label="Short Name"
          name="shortName"
          rules={[
            {
              required: true,
              message: "Please input!",
            },
            {
              type: "string",
            },
            // ({ getFieldValue }) => ({
            //   validator(_, value) {
            //     // const trimmed = value.trim().length
            //     const re = new RegExp("([a-z]*[A-Z]*)").compile()
            //     const matches = re.exec(value)
            //     if ((matches?.length ?? 0) > 0 && matches![0] === value) {
            //       return Promise.resolve()
            //     }
            //     return Promise.reject(new Error("Must be 2-4 characters."))
            //   },
            // }),
          ]}
          tooltip="This is a required field"
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          required
          label="Display Email"
          name="invitedEmail"
          rules={[
            {
              required: true,
              message: "Please input!",
            },
            {
              type: "email",
            },
          ]}
          tooltip="This is a required field"
        >
          <Input disabled={props.initialValues} placeholder="" type="email" />
        </Form.Item>
        {/* <Form.Item name="email" label="email">
        <Input value={props.initialValues["email"]} />
      </Form.Item> */}
        <Form.Item
          label="Rate"
          name="rates"
          rules={[
            {
              required: true,
              message: "Please input!",
            },
          ]}
        >
          <TDynamicFormItems form={form} mode={false} />
        </Form.Item>

        <Form.Item
          label="Role"
          name="role"
          rules={[
            {
              required: true,
              message: "Please input!",
            },
          ]}
        >
          <Select showSearch placeholder="Please select the user role">
            <Option value={MembershipRole.USER}>{MembershipRole.USER}</Option>
            <Option value={MembershipRole.ACCOUNTANT}>{MembershipRole.ACCOUNTANT}</Option>
            <Option value={MembershipRole.ADMIN}>{MembershipRole.ADMIN}</Option>
            <Option value={MembershipRole.OWNER}>{MembershipRole.OWNER}</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Can View Matter Emails"
          name="givenAccessToEmails"
          tooltip="Rights to view matter emails. If you change this setting, the user will have to logout and log back in."
        >
          <Checkbox
            defaultChecked={emailChecked}
            onChange={(e) => setEmailChecked(e.target.checked)}
          />
        </Form.Item>

        <Form.Item>
          <Button htmlType="submit" type="primary">
            Submit
          </Button>
        </Form.Item>
        <code style={{ color: theme.gray[300] }}>{props.initialValues?.id}</code>
      </>
    </TForm>
  )
}
