import { lawyerStore, matterStore } from "app/core/stores/store"
import { Client, Entry, Matter, Review, UserInOrganization } from "db"
import _ from "lodash"

const TaskFilters = () => {
  return [
    {
      value: "bw",
      text: "Billable Work", // Needs to be a string for it to play nice.
    },
    {
      value: "nbw",
      text: "Non-Billable Work", // Needs to be a string for it to play nice.
    },
  ]
}

export const TaskFilterEntry = () => {
  return {
    filters: TaskFilters(),
    onFilter: (value, record: Entry) => {
      // Needs to be mapped to a string to play nice.
      return record.task === value
    },
    filterSearch: true,
  }
}

const ClientTableFilters = (clients: Client[]) => {
  return clients.map((l) => {
    return {
      text: l.name,
      value: l.id.toString(), // Needs to be a string for it to play nice.
    }
  })
}

export const ClientFilterMatter = () => {
  return {
    filters: ClientTableFilters(matterStore.clientArray as Client[]),
    onFilter: (value, record: Matter) => {
      // Needs to be mapped to a string to play nice.
      return record.clientId.toString() === value.toString()
    },
    filterSearch: true,
  }
}

const LawyerTableFilters = (lawyers: UserInOrganization[]) => {
  return lawyers.map((l) => {
    return {
      text: l.invitedName,
      value: l.id.toString(), // Needs to be a string for it to play nice.
    }
  })
}

export const LawyerFilterMatter = () => {
  return {
    filters: LawyerTableFilters(lawyerStore.getLawyers()),
    onFilter: (value, record: Matter) => {
      // Needs to be mapped to a string to play nice.
      return record.userInOrganizationId.toString() === value.toString()
    },
    filterSearch: true,
  }
}

export const LawyerFilterReview = (lawyers: UserInOrganization[]) => {
  return {
    filters: LawyerTableFilters(lawyers),
    onFilter: (value, record: Review) => {
      // Needs to be mapped to a string to play nice.
      return record.reviewers
        ? !_.isNil((record.reviewers as Array<any>).find((a) => a.value.toString() === value))
        : false
    },
    filterSearch: true,
  }
}

export const LawyerFilterEntry = (lawyers: UserInOrganization[]) => {
  return {
    filters: LawyerTableFilters(lawyers),
    onFilter: (value: string | number, record: Entry) => {
      // Needs to be mapped to a string to play nice.
      return record.lawyerId.toString() === value.toString()
    },
    filterSearch: true,
  }
}

const MatterTableFilters = (matters: Matter[]) => {
  return matters.map((l) => {
    return {
      text: `${l.file} ${l.description}`,
      value: l.id.toString(), // Needs to be a string for it to play nice.
    }
  })
}

export const MatterFilterEntry = () => {
  return {
    filters: MatterTableFilters(matterStore.matterArray),
    onFilter: (value, record: Entry) => {
      // Needs to be mapped to a string to play nice.
      return record.matterId?.toString() === value
    },
    filterSearch: true,
  }
}
