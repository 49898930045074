import { Menu } from "antd"
import { Link, useRouter } from "blitz"
import { activeLink, activeLinks, links } from "./Links"
import { properCase } from "./Navigation"

export const SubNavigation = () => {
  const router = useRouter()

  const parent = links().find((link) => activeLink(router, link))

  if (parent && parent.sub) {
    const l = parent

    const active = activeLinks(router)

    return (
      <Menu
        disabledOverflow
        defaultOpenKeys={active.map((l) => l.key)}
        key={l.key}
        mode="horizontal"
        selectedKeys={active.map((l) => l.key)}
        style={{ paddingLeft: 16 }}
        theme="light"
      >
        {(l.sub.filter((f) => f.permission !== false) as Array<any>).map((s, i, a) => {
          return (
            <Menu.Item
              key={s.key}
              style={{
                fontWeight: 500,
              }}
            >
              <Link href={s.href ?? `/organization/${s.name}`}>
                {s.properName ? s.properName : properCase(s.name)}
              </Link>
            </Menu.Item>
          )
        })}
      </Menu>
    )
  } else {
    return null
  }
}
