import { CalendarIcon, CheckCircleIcon } from "@heroicons/react/solid"
import { Entry, MembershipRole, Review } from "@prisma/client"
import { Badge, Button, DatePicker } from "antd"
import { TButton } from "app/core/components/button/TButton"
import { DisbursementsTable, TableType } from "app/core/components/disbursements/DisbursementsTable"
import { TNavigation } from "app/core/components/layout/Navigation"
import { PageModal } from "app/core/components/modal/PageModal"
import { LoadingSpinner } from "app/core/components/spinner/LoadingSpinner"
import { TTabBar, Tab } from "app/core/components/tab/TTabBar"
import { TClientTable } from "app/core/components/table/TClientTable"
import { TDocketTable, TDocketTableType } from "app/core/components/table/TDocketTable"
import { TLawyerTable } from "app/core/components/table/TLawyerTable"
import { TMatterTable } from "app/core/components/table/TMatterTable"
import { disbursementsStore, reportsDocketStore } from "app/core/stores/TableStore"
import { lawyerStore, matterStore, reportStore, whoAmIStore } from "app/core/stores/store"
import { theme } from "app/core/styles/styles"
import { lawyerReport } from "app/core/utils/csv"
import { saveAs } from "app/core/utils/save"
import getAllEntries from "app/entries/queries/getAllEntries"
import { EditingPermissions } from "app/permissions/Simple"
import updateReview from "app/reviews/mutations/updateReview"
import getAllReviews from "app/reviews/queries/getAllReviews"
import getReview from "app/reviews/queries/getReview"
import { BlitzPage, invoke, useRouter, useSession } from "blitz"
import { decode } from "js-base64"
import { round, uniq } from "lodash"
import { runInAction } from "mobx"
import { observer } from "mobx-react-lite"
import moment, { Moment } from "moment"
import Papa from "papaparse"
import { useEffect, useState } from "react"
const { RangePicker } = DatePicker

const TabWrapper = (props: { range; children; ready; matterId?: number; tasks: Array<string> }) => {
  const { range, tasks, matterId } = props

  const lawyers = lawyerStore.lawyersArray

  const [entries, setEntries] = useState<Array<Entry>>([])

  useEffect(() => {
    let where = {
      date: { gte: props.range[0].toDate(), lte: props.range[1].toDate() },
      posted: { not: null },
      task: { in: props.tasks }, // TODO: Right now this doesn't do anything...
    }

    // Matter is optional... Only show when viewing a review...
    // TODO: Right now this doesn't do anything...
    if (matterId) {
      where["matterId"] = { equals: matterId }
    }

    invoke(getAllEntries, {
      where,
      take: 10000,
    }).then((res) => {
      setEntries(res)
    })
  }, [range, tasks, matterId])

  useEffect(() => {
    if (props.ready) {
      reportStore.generateReports(entries, lawyers, props.range)
    }
  }, [entries, lawyers, props.range, props.ready])

  return <div>{props.children}</div>
}

const ReportsPage: BlitzPage = () => {
  const session = useSession()
  const router = useRouter()

  // // Optional parameters...
  // // TODO: Right now these are not used...
  // let queryRange: any | undefined = router.query.range as string
  // if (queryRange) {
  //   queryRange = JSON.parse(decode(queryRange))
  //   queryRange = [moment(queryRange[0]).startOf("day"), moment(queryRange[1]).endOf("day")]
  // }
  // let queryMatter: any | undefined = router.query.matter as string
  // if (queryMatter) {
  //   queryMatter = parseInt(decode(queryMatter))
  // }
  // let queryTasks: any | undefined = router.query.tasks as string
  // if (queryTasks) {
  //   queryTasks = JSON.parse(decode(queryTasks))
  // }

  // This is currently used to show a bill's dockets
  let queryBillId: any | undefined = router.query.prebill as string

  const [prebill, setPrebill] = useState<Review>()

  const handleLawyerBreakdown = async () => {
    const reviews = await invoke(getAllReviews, {
      where: { archived: false, pclawExport: null, finalizedTotal: { gt: 0 } },
    })

    const columns: string[] = []

    const rows = reviews.map((review) => {
      const breakdown = review.finalizedBreakdown as Record<string, any>

      const amounts = {}
      const hours = {}
      const rates = {}

      Object.keys(breakdown).forEach((key) => {
        const prefix = lawyerStore.findLawyerFromId(parseInt(key))?.shortName ?? "X"

        amounts["fees_" + prefix] = breakdown[key].billableAmount

        const multiplier = review.feesBeforeTax! / review.finalizedSubtotal!
        amounts["discounted_fees_" + prefix] = round(multiplier * breakdown[key].billableAmount, 2)

        hours["hours_" + prefix] = breakdown[key].billableHours

        rates["rate_" + prefix] = round(
          amounts["discounted_fees_" + prefix] / hours["hours_" + prefix],
          2
        )

        columns.push("fees_" + prefix)
        columns.push("discounted_fees_" + prefix)
        columns.push("hours_" + prefix)
        columns.push("rate_" + prefix)
      })

      return {
        invoice_no: review.invoiceNo,
        ar_date: moment(review.arDate).format("YYYY-MM-DD"),
        start: moment(review.entryRange[0]).format("YYYY-MM-DD"),
        end: moment(review.endDate).format("YYYY-MM-DD"),
        matter: matterStore.findMatterFromId(review.matterId)?.file,
        matter_description: matterStore.findMatterFromId(review.matterId)?.description,
        client: matterStore.findClientFromMatter(review.matterId)?.name,
        total: review.finalizedTotal,
        tax: review.finalizedTax,
        written_off: review.writtenOff,
        discount: review.discount,
        disbursements_before_tax: review.disbursementsBeforeTax,
        fees_before_tax_and_discount: review.finalizedSubtotal,
        hours: review.finalizedHours,
        ...amounts,
        ...hours,
        ...rates,
      }
    })

    rows.sort((a, b) => {
      return moment(a.end).isBefore(moment(b.end)) ? -1 : 1
    })

    const papaColumns = uniq([
      ...Object.keys(rows[0]).filter((f) => {
        if (
          f.startsWith("fees_") ||
          f.startsWith("discounted_fees_") ||
          f.startsWith("hours_") ||
          f.startsWith("rate_")
        ) {
          return false
        } else {
          return true
        }
      }),
      ...columns.sort(),
    ])

    const breakdownCsv = new Blob(
      [
        Papa.unparse(rows, {
          columns: papaColumns,
        }),
      ],
      { type: "text/csv" }
    )
    saveAs(breakdownCsv, "lawyer_breakdown.csv")
  }

  useEffect(() => {
    const getPreBill = async () => {
      queryBillId = parseInt(decode(router.query.prebill as string))

      try {
        const preBill = await invoke(getReview, { id: queryBillId })
        const preBillRange = [
          moment(preBill.entryRange[0]).startOf("day"),
          moment(preBill.entryRange[1]).endOf("day"),
        ]
        setPrebill(preBill)
        setRange(preBillRange)
        setMatterId(preBill.matterId)
        setTasks(["bw"])
      } catch (error) {
        console.error("Invalid prebill. Doesn't exist or is deleted.")
        router.push("/organization/reports")
      }
    }
    if (router.query.prebill) {
      getPreBill()
    }
  }, [router.query.prebill])

  const today = [moment().startOf("day"), moment().endOf("day")]
  const [range, setRange] = useState<Moment[]>(today)
  const [matterId, setMatterId] = useState<number>()
  const [tasks, setTasks] = useState<string[]>(["bw", "nbw"])

  // A boolean to indicate whether all the tabs should be shown...
  const reviewOnly = queryBillId && queryBillId !== ""
  const reviewOnlyReady = matterId && range && tasks

  let tabs: Tab[] = []

  if (reviewOnly && !reviewOnlyReady) {
    return <LoadingSpinner center />
  }

  if (reviewOnly || EditingPermissions(session)) {
    tabs.push({
      name: "Dockets",
      count: <TabCount store={reportsDocketStore}></TabCount>,
      content: (
        <TDocketTable
          reviewId={prebill?.id}
          matterId={matterId}
          range={range}
          store={reportsDocketStore}
          tasks={tasks}
          type={reviewOnly ? TDocketTableType.PREBILL : TDocketTableType.REPORT}
        />
      ),
      forceRender: true,
    })
  }

  tabs.push({
    name: "Disbursements",
    forceRender: true,
    count: <TabCount store={disbursementsStore}></TabCount>,
    content: (
      <DisbursementsTable
        matterId={matterId}
        range={range}
        review={prebill}
        type={reviewOnly ? TableType.PREBILL : TableType.REPORT}
      />
    ),
  })

  if (!reviewOnly) {
    tabs.push(
      {
        name: "By Matter",
        content: <TMatterTable range={range} />,
      },
      {
        name: "By Lawyer",
        content: <TLawyerTable />,
      },
      {
        name: "By Client",
        content: <TClientTable />,
      }
    )
  }

  const BudgetInformation = () => {
    const budget = matterStore.findMatterFromId(matterId!)?.budget

    return (
      <div className="flex flex-col">
        {budget && <div className="flex flex-row">File budget: {budget}</div>}
        {/* {<div className="flex flex-row">Current amount: {currentPrebillBeforeTax}</div>} */}
      </div>
    )
  }

  const MarkReviewedButton = () => {
    const [reviewed, setReviewed] = useState(
      prebill?.reviewerIdsComplete.includes(whoAmIStore.me.id)
    )

    const handleReview = () => {
      setReviewed((p) => !p)

      let ids: number[] = []

      if (prebill?.reviewerIdsComplete?.includes(session.userInOrgId!)) {
        ids = prebill?.reviewerIdsComplete?.filter((f) => f !== session.userInOrgId!)
      } else {
        ids = [session.userInOrgId!, ...(prebill?.reviewerIdsComplete ?? [])]
      }
      // Update the local rows...
      runInAction(() => {
        prebill!.reviewerIdsComplete = ids
      })
      invoke(updateReview, {
        id: prebill!.id,
        reviewerIdsComplete: ids,
      })
    }

    if (reviewOnly) {
      return (
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <div style={{ display: "flex", gap: 10 }}>
            <Button
              style={{
                marginBottom: 16,
                fontWeight: 400,
                display: "flex",
                alignItems: "center",
                gap: "4px",
              }}
              onClick={handleReview}
            >
              <CheckCircleIcon
                color={reviewed ? theme.green[600] : theme.gray[700]}
                height="18px"
              />
              {reviewed ? "Reviewed" : "Mark reviewed"}
            </Button>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <TNavigation
      buttons={
        prebill && (
          <div>
            <MarkReviewedButton />
            <BudgetInformation />
          </div>
        )
      }
    >
      <PageModal />
      {!reviewOnly && session.orgRole !== MembershipRole.USER && (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            marginTop: 8,
            marginBottom: 4,
          }}
        >
          <Options range={range} setRange={setRange} />
          <TButton onClick={() => lawyerReport(reportStore.reportsByLawyer())}>
            Download Employee Daily Report (csv)
          </TButton>
          <TButton onClick={handleLawyerBreakdown}>Download Lawyer Breakdown (csv)</TButton>
        </div>
      )}
      <TabWrapper ready matterId={matterId} range={range} tasks={tasks}>
        <TTabBar defaultActiveKey={"0"} tabs={tabs} />
      </TabWrapper>
    </TNavigation>
  )
}

export default ReportsPage

const monthRange = (offset: number) => {
  let month = moment()
  if (offset > 0) {
    month = month.add(offset, "month")
  } else if (offset < 0) {
    month = month.subtract(Math.abs(offset), "month")
  }
  // Need to unwrap and then rewrap because moment is finicky...
  const start = moment(month.startOf("month").toDate())
  const end = moment(month.endOf("month").toDate())
  return [month.format("MMMM"), start, end]
}

export const Ranges: any = {
  DAY: () => [moment().startOf("day"), moment().endOf("day")],
  MONTH: () => monthRange(0),
  LAST_MONTH: () => monthRange(-1),
  TWO_MONTHS_PREVIOUS: () => monthRange(-2),
  THREE_MONTHS_PREVIOUS: () => monthRange(-3),
  THIS_WEEK: () => [moment().startOf("week"), moment().endOf("week")],
  THIS_QUARTER: () => [moment().startOf("quarter"), moment().endOf("quarter")],
  THIS_YEAR: () => [moment().startOf("year"), moment().endOf("year")],
  LAST_YEAR: () => [
    moment().startOf("year").subtract(1, "year"),
    moment().endOf("year").subtract(1, "year"),
  ],
}

export const defaultRanges = () => {
  let ranges: { [k: string]: any } = {}

  const thisMonth = Ranges.MONTH()
  const oneMonthAgo = Ranges.LAST_MONTH()
  const twoMonthAgo = Ranges.TWO_MONTHS_PREVIOUS()
  const threeMonthAgo = Ranges.THREE_MONTHS_PREVIOUS()

  ranges[`${thisMonth[0]} (Current)`] = [thisMonth[1], thisMonth[2]]
  ranges[`${oneMonthAgo[0]} (-1 Month)`] = [oneMonthAgo[1], oneMonthAgo[2]]
  ranges[`${twoMonthAgo[0]} (-2 Month)`] = [twoMonthAgo[1], twoMonthAgo[2]]
  ranges[`${threeMonthAgo[0]} (-3 Month)`] = [threeMonthAgo[1], threeMonthAgo[2]]

  ranges = {
    ...ranges,
    "Current Week": Ranges.THIS_WEEK(),
    "Current Quarter": Ranges.THIS_QUARTER(),
    "Current Year": Ranges.THIS_YEAR(),
    "Last Year": Ranges.LAST_YEAR(),
  }
  return ranges
}

export const TRangePicker = (props: { onChange: (value) => void; default?: any; value? }) => {
  const { value } = props

  return (
    <RangePicker
      style={{ minWidth: "250px" }}
      value={value}
      className={"button-type-range-picker"}
      clearIcon={<></>}
      defaultValue={props.default}
      ranges={defaultRanges()}
      suffixIcon={<CalendarIcon color={"rgb(60, 66, 87)"} height={16} width={16} />}
      onChange={(value) => {
        const newValue = [value?.[0]?.startOf("day"), value?.[1]?.endOf("day")]
        props.onChange(newValue)
      }}
    />
  )
}

const Options = (props: { range; setRange }) => {
  return (
    <div>
      <TRangePicker
        default={props.range}
        onChange={(value) => {
          if (value) {
            props.setRange(value as any)
          }
        }}
      />
    </div>
  )
}

const TabCount = observer((props: { store }) => {
  const { store } = props

  if (store.rows.length === 0) {
    return null
  }

  return (
    <Badge
      size="small"
      style={{
        backgroundColor: theme.blue[600],
      }}
      overflowCount={10000}
      count={store.rows.length}
    ></Badge>
  )
})
